import StarIcon from "@mui/icons-material/Star";
import { Box, Container, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styledComponent from "styled-components";

import { APP_CONSTANT } from "../constants/AppConstant";
import {
    AddProjectDrawer,
    ConsentFormModal,
    FilterProjects,
    ManageTeamModal,
    ProjectNotFound,
    ProjectSearch,
} from "../containers";
import { NoProjectsFound } from "../containers/Projects/NoProjectsFound";
import { ProjectFilterContext } from "../contexts/ProjectFilterContext";
import { UserContext } from "../contexts/UserContext";
import {
    getFilterDateRange,
    useCommonPost,
    useDeleteProject,
    useFetchCommentsForDashboard,
    useFetchProjectList,
} from "../hooks";
import { useDebounce } from "../hooks/commonHook";
import { Cards } from "../stories/components/Cards/Cards";
import { EcDialog } from "../stories/components/EcDialog/EcDialog";
import { Loader } from "../stories/components/Loader/Loader";
import { IFavoriteRequestType, ISnackBarMessage, IUserFile } from "../types";
import { exportCommentsToExcel, getLoadingImage, getLoadingMessage, snackbar } from "../utils";

const GridWrapper = styledComponent.div`
    display: block;
    h3 {
        position: relative;
        font-size: 1.125rem;
        z-index: 1;
        overflow: hidden;
        text-align: center;
        font-weight: 400;
        color: #212121;
        margin:0;
        margin-bottom:1rem;
        &:after,
        &:before {
            position: absolute;
            top: 51%;
            overflow: hidden;
            width: 50%;
            height: 1px;
            content: " ";
            background-color: #e0e0e0;
            margin-left: 1%;
        }
        &:before {
            margin-left: -51%;
            text-align: right;
        }
    }
    .unmark {
        display: inline-flex;
        margin: 0 0.48rem 1rem 0.48rem;
        width: 32%;
        &:nth-child(3n) {
            margin-right: 0;
        }
        div {
            width: 100%;
        }
    }

    @media screen and (max-width: 1200px) {
        .m-cards-outer {
            .unmark {
                margin-left: 0 0.45rem !important;
                margin-right: 0 0.45rem !important;
            }
        }
    }

    @media screen and (max-width: 1140px) {
        .m-cards-outer {
            .unmark {
                margin: 0 0.4rem 1rem 0.4rem !important;
            }
        }
    }

    @media screen and (max-width: 1023px) {
        .m-cards-outer {
            justify-content: center;
            .unmark {
                width: 360px !important;
            }
        }
    }

    @media screen and (max-width: 858px) {
        .m-cards-outer {
            .unmark {
                width: 47% !important;
                margin-inline: 0.5rem !important;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .m-cards-outer {
            .unmark {
                width: 400px !important;
            }
        }
    }

    @media screen and (max-width: 458px) {
        .m-cards-outer {
        justify-content: flex-start;
            .unmark {
                width: 90% !important;
            }
        }
    }

    @media screen and (max-width: 1210px) {
        &.sub-header .filter-w-search {
            flex-wrap: wrap;
        }
        &.sub-header .filter-w-search .seperator {
            display: none;
        }
        &.sub-header .filter-w-search .w-287 {
            width: 330px;
        }
    }

    @media screen and (max-width: 1023px) {
        &.sub-header {
            align-items: flex-start !important;
        }
    }

    @media screen and (max-width: 767px) {
        &.sub-header {
            flex-wrap: wrap;
            .m-640 {
                min-width: 100%;
            }
            .filter-w-search {
                min-width: 100%;
                flex-wrap: nowrap;
                margin-top: 15px;
                justify-content: flex-start;

                .searchwrap.w-287 {
                    display: none;
                }
            }
        }
    }

    @media (max-width: 380px) {
        .filter-w-search .muicustSelect .MuiSelect-select {
                font-size: 13px !important;
            }
        }
    }
`;

const CardOuter = styledComponent.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

const StickyHeader = styledComponent.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: static;
    top: 58px;
    background-color: #f9f9fa; /* Adjust the alpha value as needed */
    z-index: 1;
    width:100%;
    @media screen and (min-width: 2560px) {

        //   margin: 0px 0px;
        //   padding: 20px 0px;

      }

      /* Styles for laptop screens (1440px and above) */
      @media screen and (max-width: 2560px) {

        //   margin: 0px 230px ;
          padding: 20px 0px;

      }
      @media screen and (max-width: 1920px) {

        // margin: 0px 300px ;
        padding: 20px 0px;

    }

      /* Styles for laptop screens (1024px and above) */
      @media screen and (max-width: 1440px) {

        //   margin: 0px 145px ;
          padding: 20px 24px;

      }

      /* Styles for tablet screens (768px and above) */
      @media screen and (max-width: 1024px) {

        //   margin: 0px 10px;
          padding: 20px 10px;
          flex-direction:column;
          justify-content:flex-start;
          align-items: flex-start;
      }

      /* Styles for mobile screens (425px and above) */
      @media screen and (max-width: 768px) {
         margin: 0px 0px ;
         background:transparent!important;


      }

      /* Additional styles for smaller screens if necessary */
      @media screen and (max-width: 425px) {

        flex-direction: column;
        align-items: flex-start;
        padding: 0px 0px;


      }
`;

export const UserFiles = snackbar((props: ISnackBarMessage) => {
    const { user, setFileList } = UserContext();
    const { filter, range } = useContext(ProjectFilterContext);
    const history = useHistory();
    const location = useLocation();
    const [openShareModal, setOpenShareModal] = useState<JSX.Element>();
    const [files, setFiles] = useState<IUserFile[]>([]);
    const [loadingMessage, setLoadingMessage] = useState<string>("");
    const [loadingImage, setLoadingImage] = useState<string>("");
    const [openConsentModal, setopenConsentModal] = useState(false);
    const [drawerContent, setDrawerContent] = useState<JSX.Element>();
    const [confirmDialog, setConfirmDialog] = useState<JSX.Element>();
    const [searchText, setSearchText] = useState("" as string);

    const favouriteProjects = files?.filter((file: IUserFile) => file.isFavorite === 1) || [];
    const nonFavouriteProjects = files?.filter((file: IUserFile) => file.isFavorite === 0) || [];

    const { mutate: markAsFavourite, data: favouriteResponse } = useCommonPost({
        title: "Favourite Project",
        alertRequired: true,
    });

    const {
        mutate: getComments,
        data: getCommentsData,
        isLoading: fetchingComments,
    } = useFetchCommentsForDashboard(props);

    const { deleteProject } = useDeleteProject();

    const { mutate: mutateDeleteProject } = deleteProject;

    useEffect(() => {
        if (
            user.acceptTermsOfUseAndPrivacyPolicy !== undefined &&
            user.acceptTermsOfUseAndPrivacyPolicy === false
        ) {
            setopenConsentModal(true);
        } else {
            setopenConsentModal(false);
        }
    }, [user.acceptTermsOfUseAndPrivacyPolicy]);

    useEffect(() => {
        if (location.state === "success") {
            history.replace({ state: {} });
        }
    }, [location]);
    useEffect(() => {
        if (getCommentsData) {
            exportCommentsToExcel(
                getCommentsData.data.comments.comments.comments,
                "all",
                user,
                getCommentsData.data.comments.fileInfo
            );
        }
    }, [getCommentsData]);

    useEffect(() => {
        if (favouriteResponse) {
            projectListRefetch();
        }
    }, [favouriteResponse]);

    const handleRemoveProject = (projectId: number) => {
        setConfirmDialog(
            <EcDialog
                title="Remove Project"
                description="Are you sure you want to remove the project."
                open
                actionButtons={[
                    {
                        label: "Cancel",
                        onClick: () => {
                            setConfirmDialog(undefined);
                        },
                        variant: "outlined",
                    },
                    {
                        label: "Yes, I'm Sure",
                        onClick: () => {
                            mutateDeleteProject(projectId);
                            setConfirmDialog(undefined);
                        },
                        variant: "contained",
                    },
                ]}
                onClose={() => {
                    setConfirmDialog(undefined);
                }}
            />
        );
    };

    const handleMarkAsFavourite = (item: any, favId: any) => {
        const obj: IFavoriteRequestType = {} as IFavoriteRequestType;
        obj.projectId = parseInt(item, 10);
        obj.status = parseInt(favId, 10) ? 0 : 1;
        markAsFavourite({
            body: obj,
            url: APP_CONSTANT.webApi.userFavorite,
        });
    };

    const openAllComments = (item: any) => {
        history.push(`/project/${item.projectId}/details?view=comments&filter=all`);
    };

    const openResolvedComments = (item: any) => {
        history.push(`/project/${item.projectId}/details?view=comments&filter=resolved`);
    };
    const openUnresolvedComments = (item: any) => {
        history.push(`/project/${item.projectId}/details?view=comments&filter=unresolved`);
    };
    const handleOpenUnreadComments = (item: any) => {
        history.push(`/project/${item.projectId}/details?view=comments&filter=unread`);
    };
    const openOwnComments = (item: any) => {
        history.push(`/project/${item.projectId}/details?view=comments&filter=my`);
    };

    const handleDownloadExcel = (item: any) => {
        getComments({
            body: { projectId: item.projectId },
            url: APP_CONSTANT.webApi.comments,
        });
    };

    const handleAddNewProject = () => {
        // TODO: check if this functionality is in use, remove if not required
        setDrawerContent(<AddProjectDrawer onClose={() => setDrawerContent(undefined)} />);
    };

    const debounceFilterProjects = useDebounce(() => {
        if (projectList)
            setFiles(
                projectList.filter((file: IUserFile) =>
                    file.fileName.toString().toLowerCase().includes(searchText.trim().toLowerCase())
                )
            );
    }, 500);

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        debounceFilterProjects();
    };

    const handleViewPrototypeLink = (item: any) => {
        history.push(`project/${item.projectId}/details?view=prototypes`);
    };

    const { startDate, endDate } = getFilterDateRange({ filter, ...range });
    const {
        data: projectList,
        isLoading: projectListLoading,
        refetch: projectListRefetch,
    } = useFetchProjectList({
        startDate,
        endDate,
        enabled: !!startDate && !!endDate,
        key: !!startDate && !!endDate ? `${startDate}-to-${endDate}` : `all`,
    });
    const { data: userAllProjects } = useFetchProjectList({
        key: `all`,
    });

    useEffect(() => {
        setLoadingMessage(getLoadingMessage("project"));
        setLoadingImage(getLoadingImage("project"));
        if (projectList) {
            setFileList(projectList);
            setFiles(projectList);
        }
    }, [projectList]);

    return (
        <>
            <Container
                maxWidth="xl"
                sx={{
                    padding: {
                        xl: "0 1rem",
                        lg: "0",
                        md: "0",
                        sm: "0",
                        xs: "0",
                    },
                }}
            >
                {projectList && !fetchingComments && (
                    <StickyHeader>
                        <Typography variant="h5">Projects</Typography>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            gap="2rem"
                            sx={{
                                flexDirection: {
                                    xl: "row",
                                    lg: "row",
                                    md: "row",
                                    sm: "column",
                                    xs: "column",
                                },
                                alignItems: {
                                    xl: "center",
                                    lg: "center",
                                    md: "center",
                                    sm: "flex-start",
                                    xs: "flex-start",
                                },
                            }}
                        >
                            <FilterProjects data={userAllProjects} />
                            <ProjectSearch
                                placeholder="Search"
                                value={searchText}
                                searchChange={onSearch}
                            />
                        </Box>
                    </StickyHeader>
                )}
                {(projectListLoading || fetchingComments) && (
                    <Loader message={loadingMessage} image={loadingImage} />
                )}
                <GridWrapper className="">
                    {projectList &&
                        projectList?.length !== 0 &&
                        !projectListLoading &&
                        !fetchingComments && (
                            <>
                                <Box>
                                    {favouriteProjects.length > 0 && (
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            mt="1.5rem"
                                            sx={{
                                                alignItems: {
                                                    xs: "flex-start",
                                                    sm: "center",
                                                },
                                                flexDirection: {
                                                    xs: "column",
                                                    sm: "row",
                                                    md: "row",
                                                    lg: "row",
                                                    xl: "row",
                                                },
                                                paddingX: {
                                                    xs: "0rem",
                                                    sm: "0",
                                                },
                                                marginBottom: {
                                                    xs: "1.5rem",
                                                },
                                            }}
                                        >
                                            <Box display="flex" margin="0px -8px">
                                                <StarIcon
                                                    color="primary"
                                                    style={{
                                                        paddingBottom: "10px",
                                                        fontSize: "34px",
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        fontWeight: 400,
                                                        fontSize: "20px",
                                                    }}
                                                >
                                                    Favourite ({favouriteProjects.length})
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}

                                    {favouriteProjects.length > 0 && (
                                        <CardOuter className="m-cards-outer">
                                            {favouriteProjects.map((item) => (
                                                <Box
                                                    className="onlyFavMarked unmark"
                                                    key={item.projectId}
                                                >
                                                    <Cards
                                                        usedIn="projectList"
                                                        editorType={item.editorType}
                                                        item={item}
                                                        isFavorite={item.isFavorite}
                                                        onShareProject={() => {
                                                            setOpenShareModal(
                                                                <ManageTeamModal
                                                                    projectId={item.projectId}
                                                                    onClose={() =>
                                                                        setOpenShareModal(undefined)
                                                                    }
                                                                />
                                                            );
                                                        }}
                                                        handleMarkAsFavourite={() =>
                                                            handleMarkAsFavourite(
                                                                item.projectId,
                                                                item.isFavorite
                                                            )
                                                        }
                                                        onDeleteProject={() =>
                                                            handleRemoveProject(item.projectId)
                                                        }
                                                        filterType={item.fileName}
                                                        createdAt={item.createdAt}
                                                        openAllComments={() =>
                                                            openAllComments(item)
                                                        }
                                                        openResolvedComments={() =>
                                                            openResolvedComments(item)
                                                        }
                                                        openUnresolvedComments={() =>
                                                            openUnresolvedComments(item)
                                                        }
                                                        openUnreadComments={() =>
                                                            handleOpenUnreadComments(item)
                                                        }
                                                        // openNewComments={() =>
                                                        //     openNewComments(item)
                                                        // }
                                                        openOwnComments={() =>
                                                            openOwnComments(item)
                                                        }
                                                        allComments={item.allComments}
                                                        myComments={item.myComments}
                                                        newComments={item.newComments}
                                                        resolvedComments={item.resolvedComments}
                                                        unresolvedComments={item.unresolvedComments}
                                                        handleDownloadExcel={() =>
                                                            handleDownloadExcel(item)
                                                        }
                                                        fetchingComments={fetchingComments}
                                                        loadingFiles={projectListLoading}
                                                        prototypeCount={item.prototypeCount || 0}
                                                        handleViewPrototypeLink={() =>
                                                            handleViewPrototypeLink(item)
                                                        }
                                                    />
                                                </Box>
                                            ))}
                                        </CardOuter>
                                    )}
                                </Box>
                                <Box>
                                    {files.length > 0 ? (
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            mt="1.5rem"
                                            sx={{
                                                alignItems: {
                                                    xs: "flex-start",
                                                    sm: "center",
                                                },
                                                flexDirection: {
                                                    xs: "column",
                                                    sm: "row",
                                                    md: "row",
                                                    lg: "row",
                                                    xl: "row",
                                                },
                                                paddingX: {
                                                    xs: "0",
                                                    sm: "0",
                                                },
                                                marginBottom: {
                                                    xs: "1.5rem",
                                                },
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: 400,
                                                    fontSize: "20px",
                                                }}
                                            >
                                                All Projects ({nonFavouriteProjects.length})
                                            </Typography>
                                        </Box>
                                    ) : (
                                        ""
                                    )}
                                </Box>
                                <CardOuter className="m-cards-outer">
                                    {nonFavouriteProjects.map((item) => (
                                        <Box className="unmark" key={item.projectId}>
                                            <Cards
                                                usedIn="projectList"
                                                editorType={item.editorType}
                                                item={item}
                                                isFavorite={item.isFavorite}
                                                onShareProject={() => {
                                                    setOpenShareModal(
                                                        <ManageTeamModal
                                                            projectId={item.projectId}
                                                            onClose={() =>
                                                                setOpenShareModal(undefined)
                                                            }
                                                        />
                                                    );
                                                }}
                                                handleMarkAsFavourite={() =>
                                                    handleMarkAsFavourite(
                                                        item.projectId,
                                                        item.isFavorite
                                                    )
                                                }
                                                filterType={item.fileName}
                                                createdAt={item.createdAt}
                                                openAllComments={() => openAllComments(item)}
                                                openResolvedComments={() =>
                                                    openResolvedComments(item)
                                                }
                                                openUnresolvedComments={() =>
                                                    openUnresolvedComments(item)
                                                }
                                                openUnreadComments={() =>
                                                    handleOpenUnreadComments(item)
                                                }
                                                openOwnComments={() => openOwnComments(item)}
                                                onDeleteProject={() =>
                                                    handleRemoveProject(item.projectId)
                                                }
                                                allComments={item.allComments}
                                                myComments={item.myComments}
                                                newComments={item.newComments}
                                                resolvedComments={item.resolvedComments}
                                                unresolvedComments={item.unresolvedComments}
                                                handleDownloadExcel={() =>
                                                    handleDownloadExcel(item)
                                                }
                                                fetchingComments={fetchingComments}
                                                loadingFiles={projectListLoading}
                                                prototypeCount={item.prototypeCount || 0}
                                                handleViewPrototypeLink={() =>
                                                    handleViewPrototypeLink(item)
                                                }
                                            />
                                        </Box>
                                    ))}
                                </CardOuter>
                            </>
                        )}
                    {userAllProjects?.length === 0 ? (
                        <ProjectNotFound onAddNewProject={handleAddNewProject} />
                    ) : (
                        (projectList?.length === 0 || searchText.length > 0) && <NoProjectsFound />
                    )}
                </GridWrapper>

                {drawerContent && drawerContent}
                {confirmDialog && confirmDialog}
                {openShareModal && openShareModal}
                {openConsentModal && (
                    <ConsentFormModal
                        open={openConsentModal}
                        onClose={() => setopenConsentModal(!openConsentModal)}
                        showCloseIcon={false}
                    />
                )}
            </Container>
        </>
    );
});
