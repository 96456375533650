import { Box } from "@mui/material";
import { useContext, useEffect, useState } from "react";

import { ProjectDetailsContext, PrototypePageContext } from "../../contexts";
import { processfilteredPrototypes, useFetchComments, useFetchPrototypeList } from "../../hooks";
import { processPrototypeData } from "../../hooks/customHook";
import { Loader } from "../../stories/components/Loader/Loader";
import { ProcessedPageDataType } from "../../types";
import { getLoadingImage, getLoadingMessage, uniqueKey } from "../../utils";
import { PrototypeAccordion } from "../Prototype";

export const PrototypesList = () => {
    const { projectId, searchInput, selectedBranch } = useContext(ProjectDetailsContext);
    const [processedPrototypeData, setProcessedPrototypeData] = useState<ProcessedPageDataType[]>(
        []
    );
    const [filteredPrototypeData, setFilteredPrototypeData] = useState<ProcessedPageDataType[]>([]);
    const [pageExpandMap, setPageExpandMap] = useState<Map<number, boolean>>(new Map());

    const { data: commentsData, isFetching: isFetchingComments } = useFetchComments(
        projectId,
        selectedBranch?.key || ""
    );
    const { data: prototypes = [], isFetching: isFetchingPrototypes } = useFetchPrototypeList(
        selectedBranch?.key || ""
    );

    const { drawerContent, isVersionUpdated, setVersionUpdated } = useContext(PrototypePageContext);

    const handleExpandToggle = (status: boolean, index: number) => {
        const updatedData = Array.from(filteredPrototypeData);
        updatedData[index].expanded = status;

        const updatedMap = pageExpandMap;
        updatedMap.set(updatedData[index].pageId, status);
        setPageExpandMap(updatedMap);

        setFilteredPrototypeData(updatedData);
    };

    const getProcessedPrototypeData = () => {
        if (commentsData && prototypes.length > 0) {
            const processedData = processPrototypeData(commentsData, prototypes, pageExpandMap);
            setProcessedPrototypeData(processedData);
            setFilteredPrototypeData(processedData);
        }
    };

    useEffect(() => {
        if (commentsData && prototypes) {
            getProcessedPrototypeData();
        }
    }, [commentsData, prototypes]);

    useEffect(() => {
        if (isVersionUpdated) {
            getProcessedPrototypeData();
            setVersionUpdated(false);
        }
    }, [isVersionUpdated]);

    useEffect(() => {
        const filteredPrototypes = processfilteredPrototypes(searchInput, processedPrototypeData);
        setFilteredPrototypeData(filteredPrototypes);
    }, [searchInput, processedPrototypeData]);

    if (isFetchingComments || isFetchingPrototypes) {
        return (
            <Loader
                message={getLoadingMessage("prototypes")}
                image={getLoadingImage("prototypes")}
            />
        );
    }

    return (
        <Box sx={{ overflowX: "auto", margin: "1rem 1rem" }}>
            {filteredPrototypeData.map((page, index) => (
                <PrototypeAccordion
                    key={uniqueKey(`page-${page.pageId}`)}
                    data={page}
                    onExpandToggle={(status) => handleExpandToggle(status, index)}
                />
            ))}
            {drawerContent && drawerContent}
        </Box>
    );
};
