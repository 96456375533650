export const FILTERED_EMOJIS = [
    {
        key: "100",
        name: "Hundred Points",
        emoji: "💯",
    },
    {
        key: "1234",
        name: "Input Numbers",
        emoji: "🔢",
    },
    {
        key: "grinning",
        name: "Grinning Face",
        emoji: "😀",
    },
    {
        key: "smiley",
        name: "Grinning Face with Big Eyes",
        emoji: "😃",
    },
    {
        key: "smile",
        name: "Grinning Face with Smiling Eyes",
        emoji: "😄",
    },
    {
        key: "grin",
        name: "Beaming Face with Smiling Eyes",
        emoji: "😁",
    },
    {
        key: "laughing",
        name: "Grinning Squinting Face",
        emoji: "😆",
    },
    {
        key: "sweat_smile",
        name: "Grinning Face with Sweat",
        emoji: "😅",
    },
    {
        key: "rolling_on_the_floor_laughing",
        name: "Rolling on the Floor Laughing",
        emoji: "🤣",
    },
    {
        key: "joy",
        name: "Face with Tears of Joy",
        emoji: "😂",
    },
    {
        key: "slightly_smiling_face",
        name: "Slightly Smiling Face",
        emoji: "🙂",
    },
    {
        key: "upside_down_face",
        name: "Upside-Down Face",
        emoji: "🙃",
    },
    {
        key: "melting_face",
        name: "Melting Face",
        emoji: "🫠",
    },
    {
        key: "wink",
        name: "Winking Face",
        emoji: "😉",
    },
    {
        key: "blush",
        name: "Smiling Face with Smiling Eyes",
        emoji: "😊",
    },
    {
        key: "innocent",
        name: "Smiling Face with Halo",
        emoji: "😇",
    },
    {
        key: "smiling_face_with_3_hearts",
        name: "Smiling Face with Hearts",
        emoji: "🥰",
    },
    {
        key: "heart_eyes",
        name: "Smiling Face with Heart-Eyes",
        emoji: "😍",
    },
    {
        key: "star-struck",
        name: "Star-Struck",
        emoji: "🤩",
    },
    {
        key: "kissing_heart",
        name: "Face Blowing a Kiss",
        emoji: "😘",
    },
    {
        key: "kissing",
        name: "Kissing Face",
        emoji: "😗",
    },
    {
        key: "relaxed",
        name: "Smiling Face",
        emoji: "☺️",
    },
    {
        key: "kissing_closed_eyes",
        name: "Kissing Face with Closed Eyes",
        emoji: "😚",
    },
    {
        key: "kissing_smiling_eyes",
        name: "Kissing Face with Smiling Eyes",
        emoji: "😙",
    },
    {
        key: "smiling_face_with_tear",
        name: "Smiling Face with Tear",
        emoji: "🥲",
    },
    {
        key: "yum",
        name: "Face Savoring Food",
        emoji: "😋",
    },
    {
        key: "stuck_out_tongue",
        name: "Face with Tongue",
        emoji: "😛",
    },
    {
        key: "stuck_out_tongue_winking_eye",
        name: "Winking Face with Tongue",
        emoji: "😜",
    },
    {
        key: "zany_face",
        name: "Zany Face",
        emoji: "🤪",
    },
    {
        key: "stuck_out_tongue_closed_eyes",
        name: "Squinting Face with Tongue",
        emoji: "😝",
    },
    {
        key: "money_mouth_face",
        name: "Money-Mouth Face",
        emoji: "🤑",
    },
    {
        key: "hugging_face",
        name: "Hugging Face",
        emoji: "🤗",
    },
    {
        key: "face_with_hand_over_mouth",
        name: "Face with Hand over Mouth",
        emoji: "🤭",
    },
    {
        key: "face_with_open_eyes_and_hand_over_mouth",
        name: "Face with Open Eyes and Hand over Mouth",
        emoji: "🫢",
    },
    {
        key: "face_with_peeking_eye",
        name: "Face with Peeking Eye",
        emoji: "🫣",
    },
    {
        key: "shushing_face",
        name: "Shushing Face",
        emoji: "🤫",
    },
    {
        key: "thinking_face",
        name: "Thinking Face",
        emoji: "🤔",
    },
    {
        key: "saluting_face",
        name: "Saluting Face",
        emoji: "🫡",
    },
    {
        key: "zipper_mouth_face",
        name: "Zipper-Mouth Face",
        emoji: "🤐",
    },
    {
        key: "face_with_raised_eyebrow",
        name: "Face with Raised Eyebrow",
        emoji: "🤨",
    },
    {
        key: "neutral_face",
        name: "Neutral Face",
        emoji: "😐",
    },
    {
        key: "expressionless",
        name: "Expressionless Face",
        emoji: "😑",
    },
    {
        key: "no_mouth",
        name: "Face Without Mouth",
        emoji: "😶",
    },
    {
        key: "dotted_line_face",
        name: "Dotted Line Face",
        emoji: "🫥",
    },
    {
        key: "face_in_clouds",
        name: "Face in Clouds",
        emoji: "😶‍🌫️",
    },
    {
        key: "smirk",
        name: "Smirking Face",
        emoji: "😏",
    },
    {
        key: "unamused",
        name: "Unamused Face",
        emoji: "😒",
    },
    {
        key: "face_with_rolling_eyes",
        name: "Face with Rolling Eyes",
        emoji: "🙄",
    },
    {
        key: "grimacing",
        name: "Grimacing Face",
        emoji: "😬",
    },
    {
        key: "face_exhaling",
        name: "Face Exhaling",
        emoji: "😮‍💨",
    },
    {
        key: "lying_face",
        name: "Lying Face",
        emoji: "🤥",
    },
    {
        key: "relieved",
        name: "Relieved Face",
        emoji: "😌",
    },
    {
        key: "pensive",
        name: "Pensive Face",
        emoji: "😔",
    },
    {
        key: "sleepy",
        name: "Sleepy Face",
        emoji: "😪",
    },
    {
        key: "drooling_face",
        name: "Drooling Face",
        emoji: "🤤",
    },
    {
        key: "sleeping",
        name: "Sleeping Face",
        emoji: "😴",
    },
    {
        key: "mask",
        name: "Face with Medical Mask",
        emoji: "😷",
    },
    {
        key: "face_with_thermometer",
        name: "Face with Thermometer",
        emoji: "🤒",
    },
    {
        key: "face_with_head_bandage",
        name: "Face with Head-Bandage",
        emoji: "🤕",
    },
    {
        key: "nauseated_face",
        name: "Nauseated Face",
        emoji: "🤢",
    },
    {
        key: "face_vomiting",
        name: "Face Vomiting",
        emoji: "🤮",
    },
    {
        key: "sneezing_face",
        name: "Sneezing Face",
        emoji: "🤧",
    },
    {
        key: "hot_face",
        name: "Hot Face",
        emoji: "🥵",
    },
    {
        key: "cold_face",
        name: "Cold Face",
        emoji: "🥶",
    },
    {
        key: "woozy_face",
        name: "Woozy Face",
        emoji: "🥴",
    },
    {
        key: "dizzy_face",
        name: "Dizzy Face",
        emoji: "😵",
    },
    {
        key: "face_with_spiral_eyes",
        name: "Face with Spiral Eyes",
        emoji: "😵‍💫",
    },
    {
        key: "exploding_head",
        name: "Exploding Head",
        emoji: "🤯",
    },
    {
        key: "face_with_cowboy_hat",
        name: "Cowboy Hat Face",
        emoji: "🤠",
    },
    {
        key: "partying_face",
        name: "Partying Face",
        emoji: "🥳",
    },
    {
        key: "disguised_face",
        name: "Disguised Face",
        emoji: "🥸",
    },
    {
        key: "sunglasses",
        name: "Smiling Face with Sunglasses",
        emoji: "😎",
    },
    {
        key: "nerd_face",
        name: "Nerd Face",
        emoji: "🤓",
    },
    {
        key: "face_with_monocle",
        name: "Face with Monocle",
        emoji: "🧐",
    },
    {
        key: "confused",
        name: "Confused Face",
        emoji: "😕",
    },
    {
        key: "face_with_diagonal_mouth",
        name: "Face with Diagonal Mouth",
        emoji: "🫤",
    },
    {
        key: "worried",
        name: "Worried Face",
        emoji: "😟",
    },
    {
        key: "slightly_frowning_face",
        name: "Slightly Frowning Face",
        emoji: "🙁",
    },
    {
        key: "white_frowning_face",
        name: "Frowning Face",
        emoji: "☹️",
    },
    {
        key: "open_mouth",
        name: "Face with Open Mouth",
        emoji: "😮",
    },
    {
        key: "hushed",
        name: "Hushed Face",
        emoji: "😯",
    },
    {
        key: "astonished",
        name: "Astonished Face",
        emoji: "😲",
    },
    {
        key: "flushed",
        name: "Flushed Face",
        emoji: "😳",
    },
    {
        key: "pleading_face",
        name: "Pleading Face",
        emoji: "🥺",
    },
    {
        key: "face_holding_back_tears",
        name: "Face Holding Back Tears",
        emoji: "🥹",
    },
    {
        key: "frowning",
        name: "Frowning Face with Open Mouth",
        emoji: "😦",
    },
    {
        key: "anguished",
        name: "Anguished Face",
        emoji: "😧",
    },
    {
        key: "fearful",
        name: "Fearful Face",
        emoji: "😨",
    },
    {
        key: "cold_sweat",
        name: "Anxious Face with Sweat",
        emoji: "😰",
    },
    {
        key: "disappointed_relieved",
        name: "Sad but Relieved Face",
        emoji: "😥",
    },
    {
        key: "cry",
        name: "Crying Face",
        emoji: "😢",
    },
    {
        key: "sob",
        name: "Loudly Crying Face",
        emoji: "😭",
    },
    {
        key: "scream",
        name: "Face Screaming in Fear",
        emoji: "😱",
    },
    {
        key: "confounded",
        name: "Confounded Face",
        emoji: "😖",
    },
    {
        key: "persevere",
        name: "Persevering Face",
        emoji: "😣",
    },
    {
        key: "disappointed",
        name: "Disappointed Face",
        emoji: "😞",
    },
    {
        key: "sweat",
        name: "Face with Cold Sweat",
        emoji: "😓",
    },
    {
        key: "weary",
        name: "Weary Face",
        emoji: "😩",
    },
    {
        key: "tired_face",
        name: "Tired Face",
        emoji: "😫",
    },
    {
        key: "yawning_face",
        name: "Yawning Face",
        emoji: "🥱",
    },
    {
        key: "triumph",
        name: "Face with Look of Triumph",
        emoji: "😤",
    },
    {
        key: "rage",
        name: "Pouting Face",
        emoji: "😡",
    },
    {
        key: "angry",
        name: "Angry Face",
        emoji: "😠",
    },
    {
        key: "face_with_symbols_on_mouth",
        name: "Face with Symbols on Mouth",
        emoji: "🤬",
    },
    {
        key: "smiling_imp",
        name: "Smiling Face with Horns",
        emoji: "😈",
    },
    {
        key: "imp",
        name: "Imp",
        emoji: "👿",
    },
    {
        key: "skull",
        name: "Skull",
        emoji: "💀",
    },
    {
        key: "skull_and_crossbones",
        name: "Skull and Crossbones",
        emoji: "☠️",
    },
    {
        key: "hankey",
        name: "Pile of Poo",
        emoji: "💩",
    },
    {
        key: "clown_face",
        name: "Clown Face",
        emoji: "🤡",
    },
    {
        key: "japanese_ogre",
        name: "Ogre",
        emoji: "👹",
    },
    {
        key: "japanese_goblin",
        name: "Goblin",
        emoji: "👺",
    },
    {
        key: "ghost",
        name: "Ghost",
        emoji: "👻",
    },
    {
        key: "alien",
        name: "Alien",
        emoji: "👽",
    },
    {
        key: "space_invader",
        name: "Alien Monster",
        emoji: "👾",
    },
    {
        key: "robot_face",
        name: "Robot",
        emoji: "🤖",
    },
    {
        key: "smiley_cat",
        name: "Grinning Cat",
        emoji: "😺",
    },
    {
        key: "smile_cat",
        name: "Grinning Cat with Smiling Eyes",
        emoji: "😸",
    },
    {
        key: "joy_cat",
        name: "Cat with Tears of Joy",
        emoji: "😹",
    },
    {
        key: "heart_eyes_cat",
        name: "Smiling Cat with Heart-Eyes",
        emoji: "😻",
    },
    {
        key: "smirk_cat",
        name: "Cat with Wry Smile",
        emoji: "😼",
    },
    {
        key: "kissing_cat",
        name: "Kissing Cat",
        emoji: "😽",
    },
    {
        key: "scream_cat",
        name: "Weary Cat",
        emoji: "🙀",
    },
    {
        key: "crying_cat_face",
        name: "Crying Cat",
        emoji: "😿",
    },
    {
        key: "pouting_cat",
        name: "Pouting Cat",
        emoji: "😾",
    },
    {
        key: "see_no_evil",
        name: "See-No-Evil Monkey",
        emoji: "🙈",
    },
    {
        key: "hear_no_evil",
        name: "Hear-No-Evil Monkey",
        emoji: "🙉",
    },
    {
        key: "speak_no_evil",
        name: "Speak-No-Evil Monkey",
        emoji: "🙊",
    },
    {
        key: "love_letter",
        name: "Love Letter",
        emoji: "💌",
    },
    {
        key: "cupid",
        name: "Heart with Arrow",
        emoji: "💘",
    },
    {
        key: "gift_heart",
        name: "Heart with Ribbon",
        emoji: "💝",
    },
    {
        key: "sparkling_heart",
        name: "Sparkling Heart",
        emoji: "💖",
    },
    {
        key: "heartpulse",
        name: "Growing Heart",
        emoji: "💗",
    },
    {
        key: "heartbeat",
        name: "Beating Heart",
        emoji: "💓",
    },
    {
        key: "revolving_hearts",
        name: "Revolving Hearts",
        emoji: "💞",
    },
    {
        key: "two_hearts",
        name: "Two Hearts",
        emoji: "💕",
    },
    {
        key: "heart_decoration",
        name: "Heart Decoration",
        emoji: "💟",
    },
    {
        key: "heavy_heart_exclamation_mark_ornament",
        name: "Heart Exclamation",
        emoji: "❣️",
    },
    {
        key: "broken_heart",
        name: "Broken Heart",
        emoji: "💔",
    },
    {
        key: "heart_on_fire",
        name: "Heart on Fire",
        emoji: "❤️‍🔥",
    },
    {
        key: "mending_heart",
        name: "Mending Heart",
        emoji: "❤️‍🩹",
    },
    {
        key: "heart",
        name: "Red Heart",
        emoji: "❤️",
    },
    {
        key: "orange_heart",
        name: "Orange Heart",
        emoji: "🧡",
    },
    {
        key: "yellow_heart",
        name: "Yellow Heart",
        emoji: "💛",
    },
    {
        key: "green_heart",
        name: "Green Heart",
        emoji: "💚",
    },
    {
        key: "blue_heart",
        name: "Blue Heart",
        emoji: "💙",
    },
    {
        key: "purple_heart",
        name: "Purple Heart",
        emoji: "💜",
    },
    {
        key: "brown_heart",
        name: "Brown Heart",
        emoji: "🤎",
    },
    {
        key: "black_heart",
        name: "Black Heart",
        emoji: "🖤",
    },
    {
        key: "white_heart",
        name: "White Heart",
        emoji: "🤍",
    },
    {
        key: "kiss",
        name: "Kiss Mark",
        emoji: "💋",
    },
    {
        key: "anger",
        name: "Anger Symbol",
        emoji: "💢",
    },
    {
        key: "boom",
        name: "Collision",
        emoji: "💥",
    },
    {
        key: "dizzy",
        name: "Dizzy",
        emoji: "💫",
    },
    {
        key: "sweat_drops",
        name: "Sweat Droplets",
        emoji: "💦",
    },
    {
        key: "dash",
        name: "Dash Symbol",
        emoji: "💨",
    },
    {
        key: "hole",
        name: "Hole",
        emoji: "🕳️",
    },
    {
        key: "speech_balloon",
        name: "Speech Balloon",
        emoji: "💬",
    },
    {
        key: "eye-in-speech-bubble",
        name: "Eye in Speech Bubble",
        emoji: "👁️‍🗨️",
    },
    {
        key: "left_speech_bubble",
        name: "Left Speech Bubble",
        emoji: "🗨️",
    },
    {
        key: "right_anger_bubble",
        name: "Right Anger Bubble",
        emoji: "🗯️",
    },
    {
        key: "thought_balloon",
        name: "Thought Balloon",
        emoji: "💭",
    },
    {
        key: "zzz",
        name: "Zzz",
        emoji: "💤",
    },
    {
        key: "wave",
        name: "Waving Hand",
        emoji: "👋",
    },
    {
        key: "raised_back_of_hand",
        name: "Raised Back of Hand",
        emoji: "🤚",
    },
    {
        key: "raised_hand_with_fingers_splayed",
        name: "Hand with Fingers Splayed",
        emoji: "🖐️",
    },
    {
        key: "hand",
        name: "Raised Hand",
        emoji: "✋",
    },
    {
        key: "spock-hand",
        name: "Vulcan Salute",
        emoji: "🖖",
    },
    {
        key: "rightwards_hand",
        name: "Rightwards Hand",
        emoji: "🫱",
    },
    {
        key: "leftwards_hand",
        name: "Leftwards Hand",
        emoji: "🫲",
    },
    {
        key: "palm_down_hand",
        name: "Palm Down Hand",
        emoji: "🫳",
    },
    {
        key: "palm_up_hand",
        name: "Palm Up Hand",
        emoji: "🫴",
    },
    {
        key: "ok_hand",
        name: "Ok Hand",
        emoji: "👌",
    },
    {
        key: "pinched_fingers",
        name: "Pinched Fingers",
        emoji: "🤌",
    },
    {
        key: "pinching_hand",
        name: "Pinching Hand",
        emoji: "🤏",
    },
    {
        key: "v",
        name: "Victory Hand",
        emoji: "✌️",
    },
    {
        key: "crossed_fingers",
        name: "Crossed Fingers",
        emoji: "🤞",
    },
    {
        key: "hand_with_index_finger_and_thumb_crossed",
        name: "Hand with Index Finger and Thumb Crossed",
        emoji: "🫰",
    },
    {
        key: "i_love_you_hand_sign",
        name: "Love-You Gesture",
        emoji: "🤟",
    },
    {
        key: "the_horns",
        name: "Sign of the Horns",
        emoji: "🤘",
    },
    {
        key: "call_me_hand",
        name: "Call Me Hand",
        emoji: "🤙",
    },
    {
        key: "point_left",
        name: "Backhand Index Pointing Left",
        emoji: "👈",
    },
    {
        key: "point_right",
        name: "Backhand Index Pointing Right",
        emoji: "👉",
    },
    {
        key: "point_up_2",
        name: "Backhand Index Pointing Up",
        emoji: "👆",
    },
    {
        key: "middle_finger",
        name: "Middle Finger",
        emoji: "🖕",
    },
    {
        key: "point_down",
        name: "Backhand Index Pointing Down",
        emoji: "👇",
    },
    {
        key: "point_up",
        name: "Index Pointing Up",
        emoji: "☝️",
    },
    {
        key: "index_pointing_at_the_viewer",
        name: "Index Pointing at the Viewer",
        emoji: "🫵",
    },
    {
        key: "+1",
        name: "Thumbs Up",
        emoji: "👍",
    },
    {
        key: "-1",
        name: "Thumbs Down",
        emoji: "👎",
    },
    {
        key: "fist",
        name: "Raised Fist",
        emoji: "✊",
    },
    {
        key: "facepunch",
        name: "Oncoming Fist",
        emoji: "👊",
    },
    {
        key: "left-facing_fist",
        name: "Left-Facing Fist",
        emoji: "🤛",
    },
    {
        key: "right-facing_fist",
        name: "Right-Facing Fist",
        emoji: "🤜",
    },
    {
        key: "clap",
        name: "Clapping Hands",
        emoji: "👏",
    },
    {
        key: "raised_hands",
        name: "Raising Hands",
        emoji: "🙌",
    },
    {
        key: "heart_hands",
        name: "Heart Hands",
        emoji: "🫶",
    },
    {
        key: "open_hands",
        name: "Open Hands",
        emoji: "👐",
    },
    {
        key: "palms_up_together",
        name: "Palms Up Together",
        emoji: "🤲",
    },
    {
        key: "handshake",
        name: "Handshake",
        emoji: "🤝",
    },
    {
        key: "pray",
        name: "Folded Hands",
        emoji: "🙏",
    },
    {
        key: "writing_hand",
        name: "Writing Hand",
        emoji: "✍️",
    },
    {
        key: "nail_care",
        name: "Nail Polish",
        emoji: "💅",
    },
    {
        key: "selfie",
        name: "Selfie",
        emoji: "🤳",
    },
    {
        key: "muscle",
        name: "Flexed Biceps",
        emoji: "💪",
    },
    {
        key: "mechanical_arm",
        name: "Mechanical Arm",
        emoji: "🦾",
    },
    {
        key: "mechanical_leg",
        name: "Mechanical Leg",
        emoji: "🦿",
    },
    {
        key: "leg",
        name: "Leg",
        emoji: "🦵",
    },
    {
        key: "foot",
        name: "Foot",
        emoji: "🦶",
    },
    {
        key: "ear",
        name: "Ear",
        emoji: "👂",
    },
    {
        key: "ear_with_hearing_aid",
        name: "Ear with Hearing Aid",
        emoji: "🦻",
    },
    {
        key: "nose",
        name: "Nose",
        emoji: "👃",
    },
    {
        key: "brain",
        name: "Brain",
        emoji: "🧠",
    },
    {
        key: "anatomical_heart",
        name: "Anatomical Heart",
        emoji: "🫀",
    },
    {
        key: "lungs",
        name: "Lungs",
        emoji: "🫁",
    },
    {
        key: "tooth",
        name: "Tooth",
        emoji: "🦷",
    },
    {
        key: "bone",
        name: "Bone",
        emoji: "🦴",
    },
    {
        key: "eyes",
        name: "Eyes",
        emoji: "👀",
    },
    {
        key: "eye",
        name: "Eye",
        emoji: "👁️",
    },
    {
        key: "tongue",
        name: "Tongue",
        emoji: "👅",
    },
    {
        key: "lips",
        name: "Mouth",
        emoji: "👄",
    },
    {
        key: "biting_lip",
        name: "Biting Lip",
        emoji: "🫦",
    },
    {
        key: "baby",
        name: "Baby",
        emoji: "👶",
    },
    {
        key: "child",
        name: "Child",
        emoji: "🧒",
    },
    {
        key: "boy",
        name: "Boy",
        emoji: "👦",
    },
    {
        key: "girl",
        name: "Girl",
        emoji: "👧",
    },
    {
        key: "adult",
        name: "Adult",
        emoji: "🧑",
    },
    {
        key: "person_with_blond_hair",
        name: "Person Blond Hair",
        emoji: "👱",
    },
    {
        key: "man",
        name: "Man",
        emoji: "👨",
    },
    {
        key: "bearded_person",
        name: "Person Beard",
        emoji: "🧔",
    },
    {
        key: "man_with_beard",
        name: "Man: Beard",
        emoji: "🧔‍♂️",
    },
    {
        key: "woman_with_beard",
        name: "Woman: Beard",
        emoji: "🧔‍♀️",
    },
    {
        key: "red_haired_man",
        name: "Man: Red Hair",
        emoji: "👨‍🦰",
    },
    {
        key: "curly_haired_man",
        name: "Man: Curly Hair",
        emoji: "👨‍🦱",
    },
    {
        key: "white_haired_man",
        name: "Man: White Hair",
        emoji: "👨‍🦳",
    },
    {
        key: "bald_man",
        name: "Man: Bald",
        emoji: "👨‍🦲",
    },
    {
        key: "woman",
        name: "Woman",
        emoji: "👩",
    },
    {
        key: "red_haired_woman",
        name: "Woman: Red Hair",
        emoji: "👩‍🦰",
    },
    {
        key: "red_haired_person",
        name: "Person: Red Hair",
        emoji: "🧑‍🦰",
    },
    {
        key: "curly_haired_woman",
        name: "Woman: Curly Hair",
        emoji: "👩‍🦱",
    },
    {
        key: "curly_haired_person",
        name: "Person: Curly Hair",
        emoji: "🧑‍🦱",
    },
    {
        key: "white_haired_woman",
        name: "Woman: White Hair",
        emoji: "👩‍🦳",
    },
    {
        key: "white_haired_person",
        name: "Person: White Hair",
        emoji: "🧑‍🦳",
    },
    {
        key: "bald_woman",
        name: "Woman: Bald",
        emoji: "👩‍🦲",
    },
    {
        key: "bald_person",
        name: "Person: Bald",
        emoji: "🧑‍🦲",
    },
    {
        key: "blond-haired-woman",
        name: "Woman: Blond Hair",
        emoji: "👱‍♀️",
    },
    {
        key: "blond-haired-man",
        name: "Man: Blond Hair",
        emoji: "👱‍♂️",
    },
    {
        key: "older_adult",
        name: "Older Adult",
        emoji: "🧓",
    },
    {
        key: "older_man",
        name: "Old Man",
        emoji: "👴",
    },
    {
        key: "older_woman",
        name: "Old Woman",
        emoji: "👵",
    },
    {
        key: "person_frowning",
        name: "Person Frowning",
        emoji: "🙍",
    },
    {
        key: "man-frowning",
        name: "Man Frowning",
        emoji: "🙍‍♂️",
    },
    {
        key: "woman-frowning",
        name: "Woman Frowning",
        emoji: "🙍‍♀️",
    },
    {
        key: "person_with_pouting_face",
        name: "Person Pouting",
        emoji: "🙎",
    },
    {
        key: "man-pouting",
        name: "Man Pouting",
        emoji: "🙎‍♂️",
    },
    {
        key: "woman-pouting",
        name: "Woman Pouting",
        emoji: "🙎‍♀️",
    },
    {
        key: "no_good",
        name: "Person Gesturing No",
        emoji: "🙅",
    },
    {
        key: "man-gesturing-no",
        name: "Man Gesturing No",
        emoji: "🙅‍♂️",
    },
    {
        key: "woman-gesturing-no",
        name: "Woman Gesturing No",
        emoji: "🙅‍♀️",
    },
    {
        key: "ok_woman",
        name: "Person Gesturing Ok",
        emoji: "🙆",
    },
    {
        key: "man-gesturing-ok",
        name: "Man Gesturing Ok",
        emoji: "🙆‍♂️",
    },
    {
        key: "woman-gesturing-ok",
        name: "Woman Gesturing Ok",
        emoji: "🙆‍♀️",
    },
    {
        key: "information_desk_person",
        name: "Person Tipping Hand",
        emoji: "💁",
    },
    {
        key: "man-tipping-hand",
        name: "Man Tipping Hand",
        emoji: "💁‍♂️",
    },
    {
        key: "woman-tipping-hand",
        name: "Woman Tipping Hand",
        emoji: "💁‍♀️",
    },
    {
        key: "raising_hand",
        name: "Person Raising Hand",
        emoji: "🙋",
    },
    {
        key: "man-raising-hand",
        name: "Man Raising Hand",
        emoji: "🙋‍♂️",
    },
    {
        key: "woman-raising-hand",
        name: "Woman Raising Hand",
        emoji: "🙋‍♀️",
    },
    {
        key: "deaf_person",
        name: "Deaf Person",
        emoji: "🧏",
    },
    {
        key: "deaf_man",
        name: "Deaf Man",
        emoji: "🧏‍♂️",
    },
    {
        key: "deaf_woman",
        name: "Deaf Woman",
        emoji: "🧏‍♀️",
    },
    {
        key: "bow",
        name: "Person Bowing",
        emoji: "🙇",
    },
    {
        key: "man-bowing",
        name: "Man Bowing",
        emoji: "🙇‍♂️",
    },
    {
        key: "woman-bowing",
        name: "Woman Bowing",
        emoji: "🙇‍♀️",
    },
    {
        key: "face_palm",
        name: "Face Palm",
        emoji: "🤦",
    },
    {
        key: "man-facepalming",
        name: "Man Facepalming",
        emoji: "🤦‍♂️",
    },
    {
        key: "woman-facepalming",
        name: "Woman Facepalming",
        emoji: "🤦‍♀️",
    },
    {
        key: "shrug",
        name: "Shrug",
        emoji: "🤷",
    },
    {
        key: "man-shrugging",
        name: "Man Shrugging",
        emoji: "🤷‍♂️",
    },
    {
        key: "woman-shrugging",
        name: "Woman Shrugging",
        emoji: "🤷‍♀️",
    },
    {
        key: "health_worker",
        name: "Health Worker",
        emoji: "🧑‍⚕️",
    },
    {
        key: "male-doctor",
        name: "Man Health Worker",
        emoji: "👨‍⚕️",
    },
    {
        key: "female-doctor",
        name: "Woman Health Worker",
        emoji: "👩‍⚕️",
    },
    {
        key: "student",
        name: "Student",
        emoji: "🧑‍🎓",
    },
    {
        key: "male-student",
        name: "Man Student",
        emoji: "👨‍🎓",
    },
    {
        key: "female-student",
        name: "Woman Student",
        emoji: "👩‍🎓",
    },
    {
        key: "teacher",
        name: "Teacher",
        emoji: "🧑‍🏫",
    },
    {
        key: "male-teacher",
        name: "Man Teacher",
        emoji: "👨‍🏫",
    },
    {
        key: "female-teacher",
        name: "Woman Teacher",
        emoji: "👩‍🏫",
    },
    {
        key: "judge",
        name: "Judge",
        emoji: "🧑‍⚖️",
    },
    {
        key: "male-judge",
        name: "Man Judge",
        emoji: "👨‍⚖️",
    },
    {
        key: "female-judge",
        name: "Woman Judge",
        emoji: "👩‍⚖️",
    },
    {
        key: "farmer",
        name: "Farmer",
        emoji: "🧑‍🌾",
    },
    {
        key: "male-farmer",
        name: "Man Farmer",
        emoji: "👨‍🌾",
    },
    {
        key: "female-farmer",
        name: "Woman Farmer",
        emoji: "👩‍🌾",
    },
    {
        key: "cook",
        name: "Cook",
        emoji: "🧑‍🍳",
    },
    {
        key: "male-cook",
        name: "Man Cook",
        emoji: "👨‍🍳",
    },
    {
        key: "female-cook",
        name: "Woman Cook",
        emoji: "👩‍🍳",
    },
    {
        key: "mechanic",
        name: "Mechanic",
        emoji: "🧑‍🔧",
    },
    {
        key: "male-mechanic",
        name: "Man Mechanic",
        emoji: "👨‍🔧",
    },
    {
        key: "female-mechanic",
        name: "Woman Mechanic",
        emoji: "👩‍🔧",
    },
    {
        key: "factory_worker",
        name: "Factory Worker",
        emoji: "🧑‍🏭",
    },
    {
        key: "male-factory-worker",
        name: "Man Factory Worker",
        emoji: "👨‍🏭",
    },
    {
        key: "female-factory-worker",
        name: "Woman Factory Worker",
        emoji: "👩‍🏭",
    },
    {
        key: "office_worker",
        name: "Office Worker",
        emoji: "🧑‍💼",
    },
    {
        key: "male-office-worker",
        name: "Man Office Worker",
        emoji: "👨‍💼",
    },
    {
        key: "female-office-worker",
        name: "Woman Office Worker",
        emoji: "👩‍💼",
    },
    {
        key: "scientist",
        name: "Scientist",
        emoji: "🧑‍🔬",
    },
    {
        key: "male-scientist",
        name: "Man Scientist",
        emoji: "👨‍🔬",
    },
    {
        key: "female-scientist",
        name: "Woman Scientist",
        emoji: "👩‍🔬",
    },
    {
        key: "technologist",
        name: "Technologist",
        emoji: "🧑‍💻",
    },
    {
        key: "male-technologist",
        name: "Man Technologist",
        emoji: "👨‍💻",
    },
    {
        key: "female-technologist",
        name: "Woman Technologist",
        emoji: "👩‍💻",
    },
    {
        key: "singer",
        name: "Singer",
        emoji: "🧑‍🎤",
    },
    {
        key: "male-singer",
        name: "Man Singer",
        emoji: "👨‍🎤",
    },
    {
        key: "female-singer",
        name: "Woman Singer",
        emoji: "👩‍🎤",
    },
    {
        key: "artist",
        name: "Artist",
        emoji: "🧑‍🎨",
    },
    {
        key: "male-artist",
        name: "Man Artist",
        emoji: "👨‍🎨",
    },
    {
        key: "female-artist",
        name: "Woman Artist",
        emoji: "👩‍🎨",
    },
    {
        key: "pilot",
        name: "Pilot",
        emoji: "🧑‍✈️",
    },
    {
        key: "male-pilot",
        name: "Man Pilot",
        emoji: "👨‍✈️",
    },
    {
        key: "female-pilot",
        name: "Woman Pilot",
        emoji: "👩‍✈️",
    },
    {
        key: "astronaut",
        name: "Astronaut",
        emoji: "🧑‍🚀",
    },
    {
        key: "male-astronaut",
        name: "Man Astronaut",
        emoji: "👨‍🚀",
    },
    {
        key: "female-astronaut",
        name: "Woman Astronaut",
        emoji: "👩‍🚀",
    },
    {
        key: "firefighter",
        name: "Firefighter",
        emoji: "🧑‍🚒",
    },
    {
        key: "male-firefighter",
        name: "Man Firefighter",
        emoji: "👨‍🚒",
    },
    {
        key: "female-firefighter",
        name: "Woman Firefighter",
        emoji: "👩‍🚒",
    },
    {
        key: "cop",
        name: "Police Officer",
        emoji: "👮",
    },
    {
        key: "male-police-officer",
        name: "Man Police Officer",
        emoji: "👮‍♂️",
    },
    {
        key: "female-police-officer",
        name: "Woman Police Officer",
        emoji: "👮‍♀️",
    },
    {
        key: "sleuth_or_spy",
        name: "Detective",
        emoji: "🕵️",
    },
    {
        key: "male-detective",
        name: "Man Detective",
        emoji: "🕵️‍♂️",
    },
    {
        key: "female-detective",
        name: "Woman Detective",
        emoji: "🕵️‍♀️",
    },
    {
        key: "guardsman",
        name: "Guard",
        emoji: "💂",
    },
    {
        key: "male-guard",
        name: "Man Guard",
        emoji: "💂‍♂️",
    },
    {
        key: "female-guard",
        name: "Woman Guard",
        emoji: "💂‍♀️",
    },
    {
        key: "ninja",
        name: "Ninja",
        emoji: "🥷",
    },
    {
        key: "construction_worker",
        name: "Construction Worker",
        emoji: "👷",
    },
    {
        key: "male-construction-worker",
        name: "Man Construction Worker",
        emoji: "👷‍♂️",
    },
    {
        key: "female-construction-worker",
        name: "Woman Construction Worker",
        emoji: "👷‍♀️",
    },
    {
        key: "person_with_crown",
        name: "Person with Crown",
        emoji: "🫅",
    },
    {
        key: "prince",
        name: "Prince",
        emoji: "🤴",
    },
    {
        key: "princess",
        name: "Princess",
        emoji: "👸",
    },
    {
        key: "man_with_turban",
        name: "Man with Turban",
        emoji: "👳",
    },
    {
        key: "man-wearing-turban",
        name: "Man Wearing Turban",
        emoji: "👳‍♂️",
    },
    {
        key: "woman-wearing-turban",
        name: "Woman Wearing Turban",
        emoji: "👳‍♀️",
    },
    {
        key: "man_with_gua_pi_mao",
        name: "Man with Gua Pi Mao",
        emoji: "👲",
    },
    {
        key: "person_with_headscarf",
        name: "Woman with Headscarf",
        emoji: "🧕",
    },
    {
        key: "person_in_tuxedo",
        name: "Man in Tuxedo",
        emoji: "🤵",
    },
    {
        key: "man_in_tuxedo",
        name: "Man in Tuxedo",
        emoji: "🤵‍♂️",
    },
    {
        key: "woman_in_tuxedo",
        name: "Woman in Tuxedo",
        emoji: "🤵‍♀️",
    },
    {
        key: "bride_with_veil",
        name: "Bride with Veil",
        emoji: "👰",
    },
    {
        key: "man_with_veil",
        name: "Man with Veil",
        emoji: "👰‍♂️",
    },
    {
        key: "woman_with_veil",
        name: "Woman with Veil",
        emoji: "👰‍♀️",
    },
    {
        key: "pregnant_woman",
        name: "Pregnant Woman",
        emoji: "🤰",
    },
    {
        key: "pregnant_man",
        name: "Pregnant Man",
        emoji: "🫃",
    },
    {
        key: "pregnant_person",
        name: "Pregnant Person",
        emoji: "🫄",
    },
    {
        key: "breast-feeding",
        name: "Breast-Feeding",
        emoji: "🤱",
    },
    {
        key: "woman_feeding_baby",
        name: "Woman Feeding Baby",
        emoji: "👩‍🍼",
    },
    {
        key: "man_feeding_baby",
        name: "Man Feeding Baby",
        emoji: "👨‍🍼",
    },
    {
        key: "person_feeding_baby",
        name: "Person Feeding Baby",
        emoji: "🧑‍🍼",
    },
    {
        key: "angel",
        name: "Baby Angel",
        emoji: "👼",
    },
    {
        key: "santa",
        name: "Santa Claus",
        emoji: "🎅",
    },
    {
        key: "mrs_claus",
        name: "Mrs. Claus",
        emoji: "🤶",
    },
    {
        key: "mx_claus",
        name: "Mx Claus",
        emoji: "🧑‍🎄",
    },
    {
        key: "superhero",
        name: "Superhero",
        emoji: "🦸",
    },
    {
        key: "male_superhero",
        name: "Man Superhero",
        emoji: "🦸‍♂️",
    },
    {
        key: "female_superhero",
        name: "Woman Superhero",
        emoji: "🦸‍♀️",
    },
    {
        key: "supervillain",
        name: "Supervillain",
        emoji: "🦹",
    },
    {
        key: "male_supervillain",
        name: "Man Supervillain",
        emoji: "🦹‍♂️",
    },
    {
        key: "female_supervillain",
        name: "Woman Supervillain",
        emoji: "🦹‍♀️",
    },
    {
        key: "mage",
        name: "Mage",
        emoji: "🧙",
    },
    {
        key: "male_mage",
        name: "Man Mage",
        emoji: "🧙‍♂️",
    },
    {
        key: "female_mage",
        name: "Woman Mage",
        emoji: "🧙‍♀️",
    },
    {
        key: "fairy",
        name: "Fairy",
        emoji: "🧚",
    },
    {
        key: "male_fairy",
        name: "Man Fairy",
        emoji: "🧚‍♂️",
    },
    {
        key: "female_fairy",
        name: "Woman Fairy",
        emoji: "🧚‍♀️",
    },
    {
        key: "vampire",
        name: "Vampire",
        emoji: "🧛",
    },
    {
        key: "male_vampire",
        name: "Man Vampire",
        emoji: "🧛‍♂️",
    },
    {
        key: "female_vampire",
        name: "Woman Vampire",
        emoji: "🧛‍♀️",
    },
    {
        key: "merperson",
        name: "Merperson",
        emoji: "🧜",
    },
    {
        key: "merman",
        name: "Merman",
        emoji: "🧜‍♂️",
    },
    {
        key: "mermaid",
        name: "Mermaid",
        emoji: "🧜‍♀️",
    },
    {
        key: "elf",
        name: "Elf",
        emoji: "🧝",
    },
    {
        key: "male_elf",
        name: "Man Elf",
        emoji: "🧝‍♂️",
    },
    {
        key: "female_elf",
        name: "Woman Elf",
        emoji: "🧝‍♀️",
    },
    {
        key: "genie",
        name: "Genie",
        emoji: "🧞",
    },
    {
        key: "male_genie",
        name: "Man Genie",
        emoji: "🧞‍♂️",
    },
    {
        key: "female_genie",
        name: "Woman Genie",
        emoji: "🧞‍♀️",
    },
    {
        key: "zombie",
        name: "Zombie",
        emoji: "🧟",
    },
    {
        key: "male_zombie",
        name: "Man Zombie",
        emoji: "🧟‍♂️",
    },
    {
        key: "female_zombie",
        name: "Woman Zombie",
        emoji: "🧟‍♀️",
    },
    {
        key: "troll",
        name: "Troll",
        emoji: "🧌",
    },
    {
        key: "massage",
        name: "Face Massage",
        emoji: "💆",
    },
    {
        key: "man-getting-massage",
        name: "Man Getting Massage",
        emoji: "💆‍♂️",
    },
    {
        key: "woman-getting-massage",
        name: "Woman Getting Massage",
        emoji: "💆‍♀️",
    },
    {
        key: "haircut",
        name: "Haircut",
        emoji: "💇",
    },
    {
        key: "man-getting-haircut",
        name: "Man Getting Haircut",
        emoji: "💇‍♂️",
    },
    {
        key: "woman-getting-haircut",
        name: "Woman Getting Haircut",
        emoji: "💇‍♀️",
    },
    {
        key: "walking",
        name: "Pedestrian",
        emoji: "🚶",
    },
    {
        key: "man-walking",
        name: "Man Walking",
        emoji: "🚶‍♂️",
    },
    {
        key: "woman-walking",
        name: "Woman Walking",
        emoji: "🚶‍♀️",
    },
    {
        key: "standing_person",
        name: "Standing Person",
        emoji: "🧍",
    },
    {
        key: "man_standing",
        name: "Man Standing",
        emoji: "🧍‍♂️",
    },
    {
        key: "woman_standing",
        name: "Woman Standing",
        emoji: "🧍‍♀️",
    },
    {
        key: "kneeling_person",
        name: "Kneeling Person",
        emoji: "🧎",
    },
    {
        key: "man_kneeling",
        name: "Man Kneeling",
        emoji: "🧎‍♂️",
    },
    {
        key: "woman_kneeling",
        name: "Woman Kneeling",
        emoji: "🧎‍♀️",
    },
    {
        key: "person_with_probing_cane",
        name: "Person with White Cane",
        emoji: "🧑‍🦯",
    },
    {
        key: "man_with_probing_cane",
        name: "Man with White Cane",
        emoji: "👨‍🦯",
    },
    {
        key: "woman_with_probing_cane",
        name: "Woman with White Cane",
        emoji: "👩‍🦯",
    },
    {
        key: "person_in_motorized_wheelchair",
        name: "Person in Motorized Wheelchair",
        emoji: "🧑‍🦼",
    },
    {
        key: "man_in_motorized_wheelchair",
        name: "Man in Motorized Wheelchair",
        emoji: "👨‍🦼",
    },
    {
        key: "woman_in_motorized_wheelchair",
        name: "Woman in Motorized Wheelchair",
        emoji: "👩‍🦼",
    },
    {
        key: "person_in_manual_wheelchair",
        name: "Person in Manual Wheelchair",
        emoji: "🧑‍🦽",
    },
    {
        key: "man_in_manual_wheelchair",
        name: "Man in Manual Wheelchair",
        emoji: "👨‍🦽",
    },
    {
        key: "woman_in_manual_wheelchair",
        name: "Woman in Manual Wheelchair",
        emoji: "👩‍🦽",
    },
    {
        key: "runner",
        name: "Runner",
        emoji: "🏃",
    },
    {
        key: "man-running",
        name: "Man Running",
        emoji: "🏃‍♂️",
    },
    {
        key: "woman-running",
        name: "Woman Running",
        emoji: "🏃‍♀️",
    },
    {
        key: "dancer",
        name: "Dancer",
        emoji: "💃",
    },
    {
        key: "man_dancing",
        name: "Man Dancing",
        emoji: "🕺",
    },
    {
        key: "man_in_business_suit_levitating",
        name: "Person in Suit Levitating",
        emoji: "🕴️",
    },
    {
        key: "dancers",
        name: "Woman with Bunny Ears",
        emoji: "👯",
    },
    {
        key: "men-with-bunny-ears-partying",
        name: "Men with Bunny Ears",
        emoji: "👯‍♂️",
    },
    {
        key: "women-with-bunny-ears-partying",
        name: "Women with Bunny Ears",
        emoji: "👯‍♀️",
    },
    {
        key: "person_in_steamy_room",
        name: "Person in Steamy Room",
        emoji: "🧖",
    },
    {
        key: "man_in_steamy_room",
        name: "Man in Steamy Room",
        emoji: "🧖‍♂️",
    },
    {
        key: "woman_in_steamy_room",
        name: "Woman in Steamy Room",
        emoji: "🧖‍♀️",
    },
    {
        key: "person_climbing",
        name: "Person Climbing",
        emoji: "🧗",
    },
    {
        key: "man_climbing",
        name: "Man Climbing",
        emoji: "🧗‍♂️",
    },
    {
        key: "woman_climbing",
        name: "Woman Climbing",
        emoji: "🧗‍♀️",
    },
    {
        key: "fencer",
        name: "Fencer",
        emoji: "🤺",
    },
    {
        key: "horse_racing",
        name: "Horse Racing",
        emoji: "🏇",
    },
    {
        key: "skier",
        name: "Skier",
        emoji: "⛷️",
    },
    {
        key: "snowboarder",
        name: "Snowboarder",
        emoji: "🏂",
    },
    {
        key: "golfer",
        name: "Person Golfing",
        emoji: "🏌️",
    },
    {
        key: "man-golfing",
        name: "Man Golfing",
        emoji: "🏌️‍♂️",
    },
    {
        key: "woman-golfing",
        name: "Woman Golfing",
        emoji: "🏌️‍♀️",
    },
    {
        key: "surfer",
        name: "Surfer",
        emoji: "🏄",
    },
    {
        key: "man-surfing",
        name: "Man Surfing",
        emoji: "🏄‍♂️",
    },
    {
        key: "woman-surfing",
        name: "Woman Surfing",
        emoji: "🏄‍♀️",
    },
    {
        key: "rowboat",
        name: "Rowboat",
        emoji: "🚣",
    },
    {
        key: "man-rowing-boat",
        name: "Man Rowing Boat",
        emoji: "🚣‍♂️",
    },
    {
        key: "woman-rowing-boat",
        name: "Woman Rowing Boat",
        emoji: "🚣‍♀️",
    },
    {
        key: "swimmer",
        name: "Swimmer",
        emoji: "🏊",
    },
    {
        key: "man-swimming",
        name: "Man Swimming",
        emoji: "🏊‍♂️",
    },
    {
        key: "woman-swimming",
        name: "Woman Swimming",
        emoji: "🏊‍♀️",
    },
    {
        key: "person_with_ball",
        name: "Person Bouncing Ball",
        emoji: "⛹️",
    },
    {
        key: "man-bouncing-ball",
        name: "Man Bouncing Ball",
        emoji: "⛹️‍♂️",
    },
    {
        key: "woman-bouncing-ball",
        name: "Woman Bouncing Ball",
        emoji: "⛹️‍♀️",
    },
    {
        key: "weight_lifter",
        name: "Person Lifting Weights",
        emoji: "🏋️",
    },
    {
        key: "man-lifting-weights",
        name: "Man Lifting Weights",
        emoji: "🏋️‍♂️",
    },
    {
        key: "woman-lifting-weights",
        name: "Woman Lifting Weights",
        emoji: "🏋️‍♀️",
    },
    {
        key: "bicyclist",
        name: "Bicyclist",
        emoji: "🚴",
    },
    {
        key: "man-biking",
        name: "Man Biking",
        emoji: "🚴‍♂️",
    },
    {
        key: "woman-biking",
        name: "Woman Biking",
        emoji: "🚴‍♀️",
    },
    {
        key: "mountain_bicyclist",
        name: "Mountain Bicyclist",
        emoji: "🚵",
    },
    {
        key: "man-mountain-biking",
        name: "Man Mountain Biking",
        emoji: "🚵‍♂️",
    },
    {
        key: "woman-mountain-biking",
        name: "Woman Mountain Biking",
        emoji: "🚵‍♀️",
    },
    {
        key: "person_doing_cartwheel",
        name: "Person Cartwheeling",
        emoji: "🤸",
    },
    {
        key: "man-cartwheeling",
        name: "Man Cartwheeling",
        emoji: "🤸‍♂️",
    },
    {
        key: "woman-cartwheeling",
        name: "Woman Cartwheeling",
        emoji: "🤸‍♀️",
    },
    {
        key: "wrestlers",
        name: "Wrestlers",
        emoji: "🤼",
    },
    {
        key: "man-wrestling",
        name: "Men Wrestling",
        emoji: "🤼‍♂️",
    },
    {
        key: "woman-wrestling",
        name: "Women Wrestling",
        emoji: "🤼‍♀️",
    },
    {
        key: "water_polo",
        name: "Water Polo",
        emoji: "🤽",
    },
    {
        key: "man-playing-water-polo",
        name: "Man Playing Water Polo",
        emoji: "🤽‍♂️",
    },
    {
        key: "woman-playing-water-polo",
        name: "Woman Playing Water Polo",
        emoji: "🤽‍♀️",
    },
    {
        key: "handball",
        name: "Handball",
        emoji: "🤾",
    },
    {
        key: "man-playing-handball",
        name: "Man Playing Handball",
        emoji: "🤾‍♂️",
    },
    {
        key: "woman-playing-handball",
        name: "Woman Playing Handball",
        emoji: "🤾‍♀️",
    },
    {
        key: "juggling",
        name: "Juggling",
        emoji: "🤹",
    },
    {
        key: "man-juggling",
        name: "Man Juggling",
        emoji: "🤹‍♂️",
    },
    {
        key: "woman-juggling",
        name: "Woman Juggling",
        emoji: "🤹‍♀️",
    },
    {
        key: "person_in_lotus_position",
        name: "Person in Lotus Position",
        emoji: "🧘",
    },
    {
        key: "man_in_lotus_position",
        name: "Man in Lotus Position",
        emoji: "🧘‍♂️",
    },
    {
        key: "woman_in_lotus_position",
        name: "Woman in Lotus Position",
        emoji: "🧘‍♀️",
    },
    {
        key: "bath",
        name: "Bath",
        emoji: "🛀",
    },
    {
        key: "sleeping_accommodation",
        name: "Person in Bed",
        emoji: "🛌",
    },
    {
        key: "people_holding_hands",
        name: "People Holding Hands",
        emoji: "🧑‍🤝‍🧑",
    },
    {
        key: "two_women_holding_hands",
        name: "Women Holding Hands",
        emoji: "👭",
    },
    {
        key: "man_and_woman_holding_hands",
        name: "Man and Woman Holding Hands",
        emoji: "👫",
    },
    {
        key: "two_men_holding_hands",
        name: "Men Holding Hands",
        emoji: "👬",
    },
    {
        key: "couplekiss",
        name: "Kiss",
        emoji: "💏",
    },
    {
        key: "woman-kiss-man",
        name: "Kiss: Woman, Man",
        emoji: "👩‍❤️‍💋‍👨",
    },
    {
        key: "man-kiss-man",
        name: "Kiss: Man, Man",
        emoji: "👨‍❤️‍💋‍👨",
    },
    {
        key: "woman-kiss-woman",
        name: "Kiss: Woman, Woman",
        emoji: "👩‍❤️‍💋‍👩",
    },
    {
        key: "couple_with_heart",
        name: "Couple with Heart",
        emoji: "💑",
    },
    {
        key: "woman-heart-man",
        name: "Couple with Heart: Woman, Man",
        emoji: "👩‍❤️‍👨",
    },
    {
        key: "man-heart-man",
        name: "Couple with Heart: Man, Man",
        emoji: "👨‍❤️‍👨",
    },
    {
        key: "woman-heart-woman",
        name: "Couple with Heart: Woman, Woman",
        emoji: "👩‍❤️‍👩",
    },
    {
        key: "family",
        name: "Family",
        emoji: "👪",
    },
    {
        key: "man-woman-boy",
        name: "Family: Man, Woman, Boy",
        emoji: "👨‍👩‍👦",
    },
    {
        key: "man-woman-girl",
        name: "Family: Man, Woman, Girl",
        emoji: "👨‍👩‍👧",
    },
    {
        key: "man-woman-girl-boy",
        name: "Family: Man, Woman, Girl, Boy",
        emoji: "👨‍👩‍👧‍👦",
    },
    {
        key: "man-woman-boy-boy",
        name: "Family: Man, Woman, Boy, Boy",
        emoji: "👨‍👩‍👦‍👦",
    },
    {
        key: "man-woman-girl-girl",
        name: "Family: Man, Woman, Girl, Girl",
        emoji: "👨‍👩‍👧‍👧",
    },
    {
        key: "man-man-boy",
        name: "Family: Man, Man, Boy",
        emoji: "👨‍👨‍👦",
    },
    {
        key: "man-man-girl",
        name: "Family: Man, Man, Girl",
        emoji: "👨‍👨‍👧",
    },
    {
        key: "man-man-girl-boy",
        name: "Family: Man, Man, Girl, Boy",
        emoji: "👨‍👨‍👧‍👦",
    },
    {
        key: "man-man-boy-boy",
        name: "Family: Man, Man, Boy, Boy",
        emoji: "👨‍👨‍👦‍👦",
    },
    {
        key: "man-man-girl-girl",
        name: "Family: Man, Man, Girl, Girl",
        emoji: "👨‍👨‍👧‍👧",
    },
    {
        key: "woman-woman-boy",
        name: "Family: Woman, Woman, Boy",
        emoji: "👩‍👩‍👦",
    },
    {
        key: "woman-woman-girl",
        name: "Family: Woman, Woman, Girl",
        emoji: "👩‍👩‍👧",
    },
    {
        key: "woman-woman-girl-boy",
        name: "Family: Woman, Woman, Girl, Boy",
        emoji: "👩‍👩‍👧‍👦",
    },
    {
        key: "woman-woman-boy-boy",
        name: "Family: Woman, Woman, Boy, Boy",
        emoji: "👩‍👩‍👦‍👦",
    },
    {
        key: "woman-woman-girl-girl",
        name: "Family: Woman, Woman, Girl, Girl",
        emoji: "👩‍👩‍👧‍👧",
    },
    {
        key: "man-boy",
        name: "Family: Man, Boy",
        emoji: "👨‍👦",
    },
    {
        key: "man-boy-boy",
        name: "Family: Man, Boy, Boy",
        emoji: "👨‍👦‍👦",
    },
    {
        key: "man-girl",
        name: "Family: Man, Girl",
        emoji: "👨‍👧",
    },
    {
        key: "man-girl-boy",
        name: "Family: Man, Girl, Boy",
        emoji: "👨‍👧‍👦",
    },
    {
        key: "man-girl-girl",
        name: "Family: Man, Girl, Girl",
        emoji: "👨‍👧‍👧",
    },
    {
        key: "woman-boy",
        name: "Family: Woman, Boy",
        emoji: "👩‍👦",
    },
    {
        key: "woman-boy-boy",
        name: "Family: Woman, Boy, Boy",
        emoji: "👩‍👦‍👦",
    },
    {
        key: "woman-girl",
        name: "Family: Woman, Girl",
        emoji: "👩‍👧",
    },
    {
        key: "woman-girl-boy",
        name: "Family: Woman, Girl, Boy",
        emoji: "👩‍👧‍👦",
    },
    {
        key: "woman-girl-girl",
        name: "Family: Woman, Girl, Girl",
        emoji: "👩‍👧‍👧",
    },
    {
        key: "speaking_head_in_silhouette",
        name: "Speaking Head",
        emoji: "🗣️",
    },
    {
        key: "bust_in_silhouette",
        name: "Bust in Silhouette",
        emoji: "👤",
    },
    {
        key: "busts_in_silhouette",
        name: "Busts in Silhouette",
        emoji: "👥",
    },
    {
        key: "people_hugging",
        name: "People Hugging",
        emoji: "🫂",
    },
    {
        key: "footprints",
        name: "Footprints",
        emoji: "👣",
    },
    {
        key: "monkey_face",
        name: "Monkey Face",
        emoji: "🐵",
    },
    {
        key: "monkey",
        name: "Monkey",
        emoji: "🐒",
    },
    {
        key: "gorilla",
        name: "Gorilla",
        emoji: "🦍",
    },
    {
        key: "orangutan",
        name: "Orangutan",
        emoji: "🦧",
    },
    {
        key: "dog",
        name: "Dog Face",
        emoji: "🐶",
    },
    {
        key: "dog2",
        name: "Dog",
        emoji: "🐕",
    },
    {
        key: "guide_dog",
        name: "Guide Dog",
        emoji: "🦮",
    },
    {
        key: "service_dog",
        name: "Service Dog",
        emoji: "🐕‍🦺",
    },
    {
        key: "poodle",
        name: "Poodle",
        emoji: "🐩",
    },
    {
        key: "wolf",
        name: "Wolf",
        emoji: "🐺",
    },
    {
        key: "fox_face",
        name: "Fox",
        emoji: "🦊",
    },
    {
        key: "raccoon",
        name: "Raccoon",
        emoji: "🦝",
    },
    {
        key: "cat",
        name: "Cat Face",
        emoji: "🐱",
    },
    {
        key: "cat2",
        name: "Cat",
        emoji: "🐈",
    },
    {
        key: "black_cat",
        name: "Black Cat",
        emoji: "🐈‍⬛",
    },
    {
        key: "lion_face",
        name: "Lion",
        emoji: "🦁",
    },
    {
        key: "tiger",
        name: "Tiger Face",
        emoji: "🐯",
    },
    {
        key: "tiger2",
        name: "Tiger",
        emoji: "🐅",
    },
    {
        key: "leopard",
        name: "Leopard",
        emoji: "🐆",
    },
    {
        key: "horse",
        name: "Horse Face",
        emoji: "🐴",
    },
    {
        key: "racehorse",
        name: "Horse",
        emoji: "🐎",
    },
    {
        key: "unicorn_face",
        name: "Unicorn",
        emoji: "🦄",
    },
    {
        key: "zebra_face",
        name: "Zebra",
        emoji: "🦓",
    },
    {
        key: "deer",
        name: "Deer",
        emoji: "🦌",
    },
    {
        key: "bison",
        name: "Bison",
        emoji: "🦬",
    },
    {
        key: "cow",
        name: "Cow Face",
        emoji: "🐮",
    },
    {
        key: "ox",
        name: "Ox",
        emoji: "🐂",
    },
    {
        key: "water_buffalo",
        name: "Water Buffalo",
        emoji: "🐃",
    },
    {
        key: "cow2",
        name: "Cow",
        emoji: "🐄",
    },
    {
        key: "pig",
        name: "Pig Face",
        emoji: "🐷",
    },
    {
        key: "pig2",
        name: "Pig",
        emoji: "🐖",
    },
    {
        key: "boar",
        name: "Boar",
        emoji: "🐗",
    },
    {
        key: "pig_nose",
        name: "Pig Nose",
        emoji: "🐽",
    },
    {
        key: "ram",
        name: "Ram",
        emoji: "🐏",
    },
    {
        key: "sheep",
        name: "Ewe",
        emoji: "🐑",
    },
    {
        key: "goat",
        name: "Goat",
        emoji: "🐐",
    },
    {
        key: "dromedary_camel",
        name: "Camel",
        emoji: "🐪",
    },
    {
        key: "camel",
        name: "Bactrian Camel",
        emoji: "🐫",
    },
    {
        key: "llama",
        name: "Llama",
        emoji: "🦙",
    },
    {
        key: "giraffe_face",
        name: "Giraffe",
        emoji: "🦒",
    },
    {
        key: "elephant",
        name: "Elephant",
        emoji: "🐘",
    },
    {
        key: "mammoth",
        name: "Mammoth",
        emoji: "🦣",
    },
    {
        key: "rhinoceros",
        name: "Rhinoceros",
        emoji: "🦏",
    },
    {
        key: "hippopotamus",
        name: "Hippopotamus",
        emoji: "🦛",
    },
    {
        key: "mouse",
        name: "Mouse Face",
        emoji: "🐭",
    },
    {
        key: "mouse2",
        name: "Mouse",
        emoji: "🐁",
    },
    {
        key: "rat",
        name: "Rat",
        emoji: "🐀",
    },
    {
        key: "hamster",
        name: "Hamster",
        emoji: "🐹",
    },
    {
        key: "rabbit",
        name: "Rabbit Face",
        emoji: "🐰",
    },
    {
        key: "rabbit2",
        name: "Rabbit",
        emoji: "🐇",
    },
    {
        key: "chipmunk",
        name: "Chipmunk",
        emoji: "🐿️",
    },
    {
        key: "beaver",
        name: "Beaver",
        emoji: "🦫",
    },
    {
        key: "hedgehog",
        name: "Hedgehog",
        emoji: "🦔",
    },
    {
        key: "bat",
        name: "Bat",
        emoji: "🦇",
    },
    {
        key: "bear",
        name: "Bear",
        emoji: "🐻",
    },
    {
        key: "polar_bear",
        name: "Polar Bear",
        emoji: "🐻‍❄️",
    },
    {
        key: "koala",
        name: "Koala",
        emoji: "🐨",
    },
    {
        key: "panda_face",
        name: "Panda",
        emoji: "🐼",
    },
    {
        key: "sloth",
        name: "Sloth",
        emoji: "🦥",
    },
    {
        key: "otter",
        name: "Otter",
        emoji: "🦦",
    },
    {
        key: "skunk",
        name: "Skunk",
        emoji: "🦨",
    },
    {
        key: "kangaroo",
        name: "Kangaroo",
        emoji: "🦘",
    },
    {
        key: "badger",
        name: "Badger",
        emoji: "🦡",
    },
    {
        key: "feet",
        name: "Paw Prints",
        emoji: "🐾",
    },
    {
        key: "turkey",
        name: "Turkey",
        emoji: "🦃",
    },
    {
        key: "chicken",
        name: "Chicken",
        emoji: "🐔",
    },
    {
        key: "rooster",
        name: "Rooster",
        emoji: "🐓",
    },
    {
        key: "hatching_chick",
        name: "Hatching Chick",
        emoji: "🐣",
    },
    {
        key: "baby_chick",
        name: "Baby Chick",
        emoji: "🐤",
    },
    {
        key: "hatched_chick",
        name: "Front-Facing Baby Chick",
        emoji: "🐥",
    },
    {
        key: "bird",
        name: "Bird",
        emoji: "🐦",
    },
    {
        key: "penguin",
        name: "Penguin",
        emoji: "🐧",
    },
    {
        key: "dove_of_peace",
        name: "Dove",
        emoji: "🕊️",
    },
    {
        key: "eagle",
        name: "Eagle",
        emoji: "🦅",
    },
    {
        key: "duck",
        name: "Duck",
        emoji: "🦆",
    },
    {
        key: "swan",
        name: "Swan",
        emoji: "🦢",
    },
    {
        key: "owl",
        name: "Owl",
        emoji: "🦉",
    },
    {
        key: "dodo",
        name: "Dodo",
        emoji: "🦤",
    },
    {
        key: "feather",
        name: "Feather",
        emoji: "🪶",
    },
    {
        key: "flamingo",
        name: "Flamingo",
        emoji: "🦩",
    },
    {
        key: "peacock",
        name: "Peacock",
        emoji: "🦚",
    },
    {
        key: "parrot",
        name: "Parrot",
        emoji: "🦜",
    },
    {
        key: "frog",
        name: "Frog",
        emoji: "🐸",
    },
    {
        key: "crocodile",
        name: "Crocodile",
        emoji: "🐊",
    },
    {
        key: "turtle",
        name: "Turtle",
        emoji: "🐢",
    },
    {
        key: "lizard",
        name: "Lizard",
        emoji: "🦎",
    },
    {
        key: "snake",
        name: "Snake",
        emoji: "🐍",
    },
    {
        key: "dragon_face",
        name: "Dragon Face",
        emoji: "🐲",
    },
    {
        key: "dragon",
        name: "Dragon",
        emoji: "🐉",
    },
    {
        key: "sauropod",
        name: "Sauropod",
        emoji: "🦕",
    },
    {
        key: "t-rex",
        name: "T-Rex",
        emoji: "🦖",
    },
    {
        key: "whale",
        name: "Spouting Whale",
        emoji: "🐳",
    },
    {
        key: "whale2",
        name: "Whale",
        emoji: "🐋",
    },
    {
        key: "dolphin",
        name: "Dolphin",
        emoji: "🐬",
    },
    {
        key: "seal",
        name: "Seal",
        emoji: "🦭",
    },
    {
        key: "fish",
        name: "Fish",
        emoji: "🐟",
    },
    {
        key: "tropical_fish",
        name: "Tropical Fish",
        emoji: "🐠",
    },
    {
        key: "blowfish",
        name: "Blowfish",
        emoji: "🐡",
    },
    {
        key: "shark",
        name: "Shark",
        emoji: "🦈",
    },
    {
        key: "octopus",
        name: "Octopus",
        emoji: "🐙",
    },
    {
        key: "shell",
        name: "Spiral Shell",
        emoji: "🐚",
    },
    {
        key: "coral",
        name: "Coral",
        emoji: "🪸",
    },
    {
        key: "snail",
        name: "Snail",
        emoji: "🐌",
    },
    {
        key: "butterfly",
        name: "Butterfly",
        emoji: "🦋",
    },
    {
        key: "bug",
        name: "Bug",
        emoji: "🐛",
    },
    {
        key: "ant",
        name: "Ant",
        emoji: "🐜",
    },
    {
        key: "bee",
        name: "Honeybee",
        emoji: "🐝",
    },
    {
        key: "beetle",
        name: "Beetle",
        emoji: "🪲",
    },
    {
        key: "ladybug",
        name: "Lady Beetle",
        emoji: "🐞",
    },
    {
        key: "cricket",
        name: "Cricket",
        emoji: "🦗",
    },
    {
        key: "cockroach",
        name: "Cockroach",
        emoji: "🪳",
    },
    {
        key: "spider",
        name: "Spider",
        emoji: "🕷️",
    },
    {
        key: "spider_web",
        name: "Spider Web",
        emoji: "🕸️",
    },
    {
        key: "scorpion",
        name: "Scorpion",
        emoji: "🦂",
    },
    {
        key: "mosquito",
        name: "Mosquito",
        emoji: "🦟",
    },
    {
        key: "fly",
        name: "Fly",
        emoji: "🪰",
    },
    {
        key: "worm",
        name: "Worm",
        emoji: "🪱",
    },
    {
        key: "microbe",
        name: "Microbe",
        emoji: "🦠",
    },
    {
        key: "bouquet",
        name: "Bouquet",
        emoji: "💐",
    },
    {
        key: "cherry_blossom",
        name: "Cherry Blossom",
        emoji: "🌸",
    },
    {
        key: "white_flower",
        name: "White Flower",
        emoji: "💮",
    },
    {
        key: "lotus",
        name: "Lotus",
        emoji: "🪷",
    },
    {
        key: "rosette",
        name: "Rosette",
        emoji: "🏵️",
    },
    {
        key: "rose",
        name: "Rose",
        emoji: "🌹",
    },
    {
        key: "wilted_flower",
        name: "Wilted Flower",
        emoji: "🥀",
    },
    {
        key: "hibiscus",
        name: "Hibiscus",
        emoji: "🌺",
    },
    {
        key: "sunflower",
        name: "Sunflower",
        emoji: "🌻",
    },
    {
        key: "blossom",
        name: "Blossom",
        emoji: "🌼",
    },
    {
        key: "tulip",
        name: "Tulip",
        emoji: "🌷",
    },
    {
        key: "seedling",
        name: "Seedling",
        emoji: "🌱",
    },
    {
        key: "potted_plant",
        name: "Potted Plant",
        emoji: "🪴",
    },
    {
        key: "evergreen_tree",
        name: "Evergreen Tree",
        emoji: "🌲",
    },
    {
        key: "deciduous_tree",
        name: "Deciduous Tree",
        emoji: "🌳",
    },
    {
        key: "palm_tree",
        name: "Palm Tree",
        emoji: "🌴",
    },
    {
        key: "cactus",
        name: "Cactus",
        emoji: "🌵",
    },
    {
        key: "ear_of_rice",
        name: "Ear of Rice",
        emoji: "🌾",
    },
    {
        key: "herb",
        name: "Herb",
        emoji: "🌿",
    },
    {
        key: "shamrock",
        name: "Shamrock",
        emoji: "☘️",
    },
    {
        key: "four_leaf_clover",
        name: "Four Leaf Clover",
        emoji: "🍀",
    },
    {
        key: "maple_leaf",
        name: "Maple Leaf",
        emoji: "🍁",
    },
    {
        key: "fallen_leaf",
        name: "Fallen Leaf",
        emoji: "🍂",
    },
    {
        key: "leaves",
        name: "Leaf Fluttering in Wind",
        emoji: "🍃",
    },
    {
        key: "empty_nest",
        name: "Empty Nest",
        emoji: "🪹",
    },
    {
        key: "nest_with_eggs",
        name: "Nest with Eggs",
        emoji: "🪺",
    },
    {
        key: "mushroom",
        name: "Mushroom",
        emoji: "🍄",
    },
    {
        key: "grapes",
        name: "Grapes",
        emoji: "🍇",
    },
    {
        key: "melon",
        name: "Melon",
        emoji: "🍈",
    },
    {
        key: "watermelon",
        name: "Watermelon",
        emoji: "🍉",
    },
    {
        key: "tangerine",
        name: "Tangerine",
        emoji: "🍊",
    },
    {
        key: "lemon",
        name: "Lemon",
        emoji: "🍋",
    },
    {
        key: "banana",
        name: "Banana",
        emoji: "🍌",
    },
    {
        key: "pineapple",
        name: "Pineapple",
        emoji: "🍍",
    },
    {
        key: "mango",
        name: "Mango",
        emoji: "🥭",
    },
    {
        key: "apple",
        name: "Red Apple",
        emoji: "🍎",
    },
    {
        key: "green_apple",
        name: "Green Apple",
        emoji: "🍏",
    },
    {
        key: "pear",
        name: "Pear",
        emoji: "🍐",
    },
    {
        key: "peach",
        name: "Peach",
        emoji: "🍑",
    },
    {
        key: "cherries",
        name: "Cherries",
        emoji: "🍒",
    },
    {
        key: "strawberry",
        name: "Strawberry",
        emoji: "🍓",
    },
    {
        key: "blueberries",
        name: "Blueberries",
        emoji: "🫐",
    },
    {
        key: "kiwifruit",
        name: "Kiwifruit",
        emoji: "🥝",
    },
    {
        key: "tomato",
        name: "Tomato",
        emoji: "🍅",
    },
    {
        key: "olive",
        name: "Olive",
        emoji: "🫒",
    },
    {
        key: "coconut",
        name: "Coconut",
        emoji: "🥥",
    },
    {
        key: "avocado",
        name: "Avocado",
        emoji: "🥑",
    },
    {
        key: "eggplant",
        name: "Eggplant",
        emoji: "🍆",
    },
    {
        key: "potato",
        name: "Potato",
        emoji: "🥔",
    },
    {
        key: "carrot",
        name: "Carrot",
        emoji: "🥕",
    },
    {
        key: "corn",
        name: "Ear of Corn",
        emoji: "🌽",
    },
    {
        key: "hot_pepper",
        name: "Hot Pepper",
        emoji: "🌶️",
    },
    {
        key: "bell_pepper",
        name: "Bell Pepper",
        emoji: "🫑",
    },
    {
        key: "cucumber",
        name: "Cucumber",
        emoji: "🥒",
    },
    {
        key: "leafy_green",
        name: "Leafy Green",
        emoji: "🥬",
    },
    {
        key: "broccoli",
        name: "Broccoli",
        emoji: "🥦",
    },
    {
        key: "garlic",
        name: "Garlic",
        emoji: "🧄",
    },
    {
        key: "onion",
        name: "Onion",
        emoji: "🧅",
    },
    {
        key: "peanuts",
        name: "Peanuts",
        emoji: "🥜",
    },
    {
        key: "beans",
        name: "Beans",
        emoji: "🫘",
    },
    {
        key: "chestnut",
        name: "Chestnut",
        emoji: "🌰",
    },
    {
        key: "bread",
        name: "Bread",
        emoji: "🍞",
    },
    {
        key: "croissant",
        name: "Croissant",
        emoji: "🥐",
    },
    {
        key: "baguette_bread",
        name: "Baguette Bread",
        emoji: "🥖",
    },
    {
        key: "flatbread",
        name: "Flatbread",
        emoji: "🫓",
    },
    {
        key: "pretzel",
        name: "Pretzel",
        emoji: "🥨",
    },
    {
        key: "bagel",
        name: "Bagel",
        emoji: "🥯",
    },
    {
        key: "pancakes",
        name: "Pancakes",
        emoji: "🥞",
    },
    {
        key: "waffle",
        name: "Waffle",
        emoji: "🧇",
    },
    {
        key: "cheese_wedge",
        name: "Cheese Wedge",
        emoji: "🧀",
    },
    {
        key: "meat_on_bone",
        name: "Meat on Bone",
        emoji: "🍖",
    },
    {
        key: "poultry_leg",
        name: "Poultry Leg",
        emoji: "🍗",
    },
    {
        key: "cut_of_meat",
        name: "Cut of Meat",
        emoji: "🥩",
    },
    {
        key: "bacon",
        name: "Bacon",
        emoji: "🥓",
    },
    {
        key: "hamburger",
        name: "Hamburger",
        emoji: "🍔",
    },
    {
        key: "fries",
        name: "French Fries",
        emoji: "🍟",
    },
    {
        key: "pizza",
        name: "Pizza",
        emoji: "🍕",
    },
    {
        key: "hotdog",
        name: "Hot Dog",
        emoji: "🌭",
    },
    {
        key: "sandwich",
        name: "Sandwich",
        emoji: "🥪",
    },
    {
        key: "taco",
        name: "Taco",
        emoji: "🌮",
    },
    {
        key: "burrito",
        name: "Burrito",
        emoji: "🌯",
    },
    {
        key: "tamale",
        name: "Tamale",
        emoji: "🫔",
    },
    {
        key: "stuffed_flatbread",
        name: "Stuffed Flatbread",
        emoji: "🥙",
    },
    {
        key: "falafel",
        name: "Falafel",
        emoji: "🧆",
    },
    {
        key: "egg",
        name: "Egg",
        emoji: "🥚",
    },
    {
        key: "fried_egg",
        name: "Cooking",
        emoji: "🍳",
    },
    {
        key: "shallow_pan_of_food",
        name: "Shallow Pan of Food",
        emoji: "🥘",
    },
    {
        key: "stew",
        name: "Pot of Food",
        emoji: "🍲",
    },
    {
        key: "fondue",
        name: "Fondue",
        emoji: "🫕",
    },
    {
        key: "bowl_with_spoon",
        name: "Bowl with Spoon",
        emoji: "🥣",
    },
    {
        key: "green_salad",
        name: "Green Salad",
        emoji: "🥗",
    },
    {
        key: "popcorn",
        name: "Popcorn",
        emoji: "🍿",
    },
    {
        key: "butter",
        name: "Butter",
        emoji: "🧈",
    },
    {
        key: "salt",
        name: "Salt",
        emoji: "🧂",
    },
    {
        key: "canned_food",
        name: "Canned Food",
        emoji: "🥫",
    },
    {
        key: "bento",
        name: "Bento Box",
        emoji: "🍱",
    },
    {
        key: "rice_cracker",
        name: "Rice Cracker",
        emoji: "🍘",
    },
    {
        key: "rice_ball",
        name: "Rice Ball",
        emoji: "🍙",
    },
    {
        key: "rice",
        name: "Cooked Rice",
        emoji: "🍚",
    },
    {
        key: "curry",
        name: "Curry Rice",
        emoji: "🍛",
    },
    {
        key: "ramen",
        name: "Steaming Bowl",
        emoji: "🍜",
    },
    {
        key: "spaghetti",
        name: "Spaghetti",
        emoji: "🍝",
    },
    {
        key: "sweet_potato",
        name: "Roasted Sweet Potato",
        emoji: "🍠",
    },
    {
        key: "oden",
        name: "Oden",
        emoji: "🍢",
    },
    {
        key: "sushi",
        name: "Sushi",
        emoji: "🍣",
    },
    {
        key: "fried_shrimp",
        name: "Fried Shrimp",
        emoji: "🍤",
    },
    {
        key: "fish_cake",
        name: "Fish Cake with Swirl",
        emoji: "🍥",
    },
    {
        key: "moon_cake",
        name: "Moon Cake",
        emoji: "🥮",
    },
    {
        key: "dango",
        name: "Dango",
        emoji: "🍡",
    },
    {
        key: "dumpling",
        name: "Dumpling",
        emoji: "🥟",
    },
    {
        key: "fortune_cookie",
        name: "Fortune Cookie",
        emoji: "🥠",
    },
    {
        key: "takeout_box",
        name: "Takeout Box",
        emoji: "🥡",
    },
    {
        key: "crab",
        name: "Crab",
        emoji: "🦀",
    },
    {
        key: "lobster",
        name: "Lobster",
        emoji: "🦞",
    },
    {
        key: "shrimp",
        name: "Shrimp",
        emoji: "🦐",
    },
    {
        key: "squid",
        name: "Squid",
        emoji: "🦑",
    },
    {
        key: "oyster",
        name: "Oyster",
        emoji: "🦪",
    },
    {
        key: "icecream",
        name: "Soft Ice Cream",
        emoji: "🍦",
    },
    {
        key: "shaved_ice",
        name: "Shaved Ice",
        emoji: "🍧",
    },
    {
        key: "ice_cream",
        name: "Ice Cream",
        emoji: "🍨",
    },
    {
        key: "doughnut",
        name: "Doughnut",
        emoji: "🍩",
    },
    {
        key: "cookie",
        name: "Cookie",
        emoji: "🍪",
    },
    {
        key: "birthday",
        name: "Birthday Cake",
        emoji: "🎂",
    },
    {
        key: "cake",
        name: "Shortcake",
        emoji: "🍰",
    },
    {
        key: "cupcake",
        name: "Cupcake",
        emoji: "🧁",
    },
    {
        key: "pie",
        name: "Pie",
        emoji: "🥧",
    },
    {
        key: "chocolate_bar",
        name: "Chocolate Bar",
        emoji: "🍫",
    },
    {
        key: "candy",
        name: "Candy",
        emoji: "🍬",
    },
    {
        key: "lollipop",
        name: "Lollipop",
        emoji: "🍭",
    },
    {
        key: "custard",
        name: "Custard",
        emoji: "🍮",
    },
    {
        key: "honey_pot",
        name: "Honey Pot",
        emoji: "🍯",
    },
    {
        key: "baby_bottle",
        name: "Baby Bottle",
        emoji: "🍼",
    },
    {
        key: "glass_of_milk",
        name: "Glass of Milk",
        emoji: "🥛",
    },
    {
        key: "coffee",
        name: "Hot Beverage",
        emoji: "☕",
    },
    {
        key: "teapot",
        name: "Teapot",
        emoji: "🫖",
    },
    {
        key: "tea",
        name: "Teacup Without Handle",
        emoji: "🍵",
    },
    {
        key: "sake",
        name: "Sake",
        emoji: "🍶",
    },
    {
        key: "champagne",
        name: "Bottle with Popping Cork",
        emoji: "🍾",
    },
    {
        key: "wine_glass",
        name: "Wine Glass",
        emoji: "🍷",
    },
    {
        key: "cocktail",
        name: "Cocktail Glass",
        emoji: "🍸",
    },
    {
        key: "tropical_drink",
        name: "Tropical Drink",
        emoji: "🍹",
    },
    {
        key: "beer",
        name: "Beer Mug",
        emoji: "🍺",
    },
    {
        key: "beers",
        name: "Clinking Beer Mugs",
        emoji: "🍻",
    },
    {
        key: "clinking_glasses",
        name: "Clinking Glasses",
        emoji: "🥂",
    },
    {
        key: "tumbler_glass",
        name: "Tumbler Glass",
        emoji: "🥃",
    },
    {
        key: "pouring_liquid",
        name: "Pouring Liquid",
        emoji: "🫗",
    },
    {
        key: "cup_with_straw",
        name: "Cup with Straw",
        emoji: "🥤",
    },
    {
        key: "bubble_tea",
        name: "Bubble Tea",
        emoji: "🧋",
    },
    {
        key: "beverage_box",
        name: "Beverage Box",
        emoji: "🧃",
    },
    {
        key: "mate_drink",
        name: "Mate",
        emoji: "🧉",
    },
    {
        key: "ice_cube",
        name: "Ice",
        emoji: "🧊",
    },
    {
        key: "chopsticks",
        name: "Chopsticks",
        emoji: "🥢",
    },
    {
        key: "knife_fork_plate",
        name: "Fork and Knife with Plate",
        emoji: "🍽️",
    },
    {
        key: "fork_and_knife",
        name: "Fork and Knife",
        emoji: "🍴",
    },
    {
        key: "spoon",
        name: "Spoon",
        emoji: "🥄",
    },
    {
        key: "hocho",
        name: "Hocho",
        emoji: "🔪",
    },
    {
        key: "jar",
        name: "Jar",
        emoji: "🫙",
    },
    {
        key: "amphora",
        name: "Amphora",
        emoji: "🏺",
    },
    {
        key: "earth_africa",
        name: "Earth Globe Europe-Africa",
        emoji: "🌍",
    },
    {
        key: "earth_americas",
        name: "Earth Globe Americas",
        emoji: "🌎",
    },
    {
        key: "earth_asia",
        name: "Earth Globe Asia-Australia",
        emoji: "🌏",
    },
    {
        key: "globe_with_meridians",
        name: "Globe with Meridians",
        emoji: "🌐",
    },
    {
        key: "world_map",
        name: "World Map",
        emoji: "🗺️",
    },
    {
        key: "japan",
        name: "Map of Japan",
        emoji: "🗾",
    },
    {
        key: "compass",
        name: "Compass",
        emoji: "🧭",
    },
    {
        key: "snow_capped_mountain",
        name: "Snow-Capped Mountain",
        emoji: "🏔️",
    },
    {
        key: "mountain",
        name: "Mountain",
        emoji: "⛰️",
    },
    {
        key: "volcano",
        name: "Volcano",
        emoji: "🌋",
    },
    {
        key: "mount_fuji",
        name: "Mount Fuji",
        emoji: "🗻",
    },
    {
        key: "camping",
        name: "Camping",
        emoji: "🏕️",
    },
    {
        key: "beach_with_umbrella",
        name: "Beach with Umbrella",
        emoji: "🏖️",
    },
    {
        key: "desert",
        name: "Desert",
        emoji: "🏜️",
    },
    {
        key: "desert_island",
        name: "Desert Island",
        emoji: "🏝️",
    },
    {
        key: "national_park",
        name: "National Park",
        emoji: "🏞️",
    },
    {
        key: "stadium",
        name: "Stadium",
        emoji: "🏟️",
    },
    {
        key: "classical_building",
        name: "Classical Building",
        emoji: "🏛️",
    },
    {
        key: "building_construction",
        name: "Building Construction",
        emoji: "🏗️",
    },
    {
        key: "bricks",
        name: "Brick",
        emoji: "🧱",
    },
    {
        key: "rock",
        name: "Rock",
        emoji: "🪨",
    },
    {
        key: "wood",
        name: "Wood",
        emoji: "🪵",
    },
    {
        key: "hut",
        name: "Hut",
        emoji: "🛖",
    },
    {
        key: "house_buildings",
        name: "Houses",
        emoji: "🏘️",
    },
    {
        key: "derelict_house_building",
        name: "Derelict House",
        emoji: "🏚️",
    },
    {
        key: "house",
        name: "House",
        emoji: "🏠",
    },
    {
        key: "house_with_garden",
        name: "House with Garden",
        emoji: "🏡",
    },
    {
        key: "office",
        name: "Office Building",
        emoji: "🏢",
    },
    {
        key: "post_office",
        name: "Japanese Post Office",
        emoji: "🏣",
    },
    {
        key: "european_post_office",
        name: "Post Office",
        emoji: "🏤",
    },
    {
        key: "hospital",
        name: "Hospital",
        emoji: "🏥",
    },
    {
        key: "bank",
        name: "Bank",
        emoji: "🏦",
    },
    {
        key: "hotel",
        name: "Hotel",
        emoji: "🏨",
    },
    {
        key: "love_hotel",
        name: "Love Hotel",
        emoji: "🏩",
    },
    {
        key: "convenience_store",
        name: "Convenience Store",
        emoji: "🏪",
    },
    {
        key: "school",
        name: "School",
        emoji: "🏫",
    },
    {
        key: "department_store",
        name: "Department Store",
        emoji: "🏬",
    },
    {
        key: "factory",
        name: "Factory",
        emoji: "🏭",
    },
    {
        key: "japanese_castle",
        name: "Japanese Castle",
        emoji: "🏯",
    },
    {
        key: "european_castle",
        name: "Castle",
        emoji: "🏰",
    },
    {
        key: "wedding",
        name: "Wedding",
        emoji: "💒",
    },
    {
        key: "tokyo_tower",
        name: "Tokyo Tower",
        emoji: "🗼",
    },
    {
        key: "statue_of_liberty",
        name: "Statue of Liberty",
        emoji: "🗽",
    },
    {
        key: "church",
        name: "Church",
        emoji: "⛪",
    },
    {
        key: "mosque",
        name: "Mosque",
        emoji: "🕌",
    },
    {
        key: "hindu_temple",
        name: "Hindu Temple",
        emoji: "🛕",
    },
    {
        key: "synagogue",
        name: "Synagogue",
        emoji: "🕍",
    },
    {
        key: "shinto_shrine",
        name: "Shinto Shrine",
        emoji: "⛩️",
    },
    {
        key: "kaaba",
        name: "Kaaba",
        emoji: "🕋",
    },
    {
        key: "fountain",
        name: "Fountain",
        emoji: "⛲",
    },
    {
        key: "tent",
        name: "Tent",
        emoji: "⛺",
    },
    {
        key: "foggy",
        name: "Foggy",
        emoji: "🌁",
    },
    {
        key: "night_with_stars",
        name: "Night with Stars",
        emoji: "🌃",
    },
    {
        key: "cityscape",
        name: "Cityscape",
        emoji: "🏙️",
    },
    {
        key: "sunrise_over_mountains",
        name: "Sunrise over Mountains",
        emoji: "🌄",
    },
    {
        key: "sunrise",
        name: "Sunrise",
        emoji: "🌅",
    },
    {
        key: "city_sunset",
        name: "Cityscape at Dusk",
        emoji: "🌆",
    },
    {
        key: "city_sunrise",
        name: "Sunset",
        emoji: "🌇",
    },
    {
        key: "bridge_at_night",
        name: "Bridge at Night",
        emoji: "🌉",
    },
    {
        key: "hotsprings",
        name: "Hot Springs",
        emoji: "♨️",
    },
    {
        key: "carousel_horse",
        name: "Carousel Horse",
        emoji: "🎠",
    },
    {
        key: "playground_slide",
        name: "Playground Slide",
        emoji: "🛝",
    },
    {
        key: "ferris_wheel",
        name: "Ferris Wheel",
        emoji: "🎡",
    },
    {
        key: "roller_coaster",
        name: "Roller Coaster",
        emoji: "🎢",
    },
    {
        key: "barber",
        name: "Barber Pole",
        emoji: "💈",
    },
    {
        key: "circus_tent",
        name: "Circus Tent",
        emoji: "🎪",
    },
    {
        key: "steam_locomotive",
        name: "Locomotive",
        emoji: "🚂",
    },
    {
        key: "railway_car",
        name: "Railway Car",
        emoji: "🚃",
    },
    {
        key: "bullettrain_side",
        name: "High-Speed Train",
        emoji: "🚄",
    },
    {
        key: "bullettrain_front",
        name: "Bullet Train",
        emoji: "🚅",
    },
    {
        key: "train2",
        name: "Train",
        emoji: "🚆",
    },
    {
        key: "metro",
        name: "Metro",
        emoji: "🚇",
    },
    {
        key: "light_rail",
        name: "Light Rail",
        emoji: "🚈",
    },
    {
        key: "station",
        name: "Station",
        emoji: "🚉",
    },
    {
        key: "tram",
        name: "Tram",
        emoji: "🚊",
    },
    {
        key: "monorail",
        name: "Monorail",
        emoji: "🚝",
    },
    {
        key: "mountain_railway",
        name: "Mountain Railway",
        emoji: "🚞",
    },
    {
        key: "train",
        name: "Tram Car",
        emoji: "🚋",
    },
    {
        key: "bus",
        name: "Bus",
        emoji: "🚌",
    },
    {
        key: "oncoming_bus",
        name: "Oncoming Bus",
        emoji: "🚍",
    },
    {
        key: "trolleybus",
        name: "Trolleybus",
        emoji: "🚎",
    },
    {
        key: "minibus",
        name: "Minibus",
        emoji: "🚐",
    },
    {
        key: "ambulance",
        name: "Ambulance",
        emoji: "🚑",
    },
    {
        key: "fire_engine",
        name: "Fire Engine",
        emoji: "🚒",
    },
    {
        key: "police_car",
        name: "Police Car",
        emoji: "🚓",
    },
    {
        key: "oncoming_police_car",
        name: "Oncoming Police Car",
        emoji: "🚔",
    },
    {
        key: "taxi",
        name: "Taxi",
        emoji: "🚕",
    },
    {
        key: "oncoming_taxi",
        name: "Oncoming Taxi",
        emoji: "🚖",
    },
    {
        key: "car",
        name: "Automobile",
        emoji: "🚗",
    },
    {
        key: "oncoming_automobile",
        name: "Oncoming Automobile",
        emoji: "🚘",
    },
    {
        key: "blue_car",
        name: "Recreational Vehicle",
        emoji: "🚙",
    },
    {
        key: "pickup_truck",
        name: "Pickup Truck",
        emoji: "🛻",
    },
    {
        key: "truck",
        name: "Delivery Truck",
        emoji: "🚚",
    },
    {
        key: "articulated_lorry",
        name: "Articulated Lorry",
        emoji: "🚛",
    },
    {
        key: "tractor",
        name: "Tractor",
        emoji: "🚜",
    },
    {
        key: "racing_car",
        name: "Racing Car",
        emoji: "🏎️",
    },
    {
        key: "racing_motorcycle",
        name: "Motorcycle",
        emoji: "🏍️",
    },
    {
        key: "motor_scooter",
        name: "Motor Scooter",
        emoji: "🛵",
    },
    {
        key: "manual_wheelchair",
        name: "Manual Wheelchair",
        emoji: "🦽",
    },
    {
        key: "motorized_wheelchair",
        name: "Motorized Wheelchair",
        emoji: "🦼",
    },
    {
        key: "auto_rickshaw",
        name: "Auto Rickshaw",
        emoji: "🛺",
    },
    {
        key: "bike",
        name: "Bicycle",
        emoji: "🚲",
    },
    {
        key: "scooter",
        name: "Scooter",
        emoji: "🛴",
    },
    {
        key: "skateboard",
        name: "Skateboard",
        emoji: "🛹",
    },
    {
        key: "roller_skate",
        name: "Roller Skate",
        emoji: "🛼",
    },
    {
        key: "busstop",
        name: "Bus Stop",
        emoji: "🚏",
    },
    {
        key: "motorway",
        name: "Motorway",
        emoji: "🛣️",
    },
    {
        key: "railway_track",
        name: "Railway Track",
        emoji: "🛤️",
    },
    {
        key: "oil_drum",
        name: "Oil Drum",
        emoji: "🛢️",
    },
    {
        key: "fuelpump",
        name: "Fuel Pump",
        emoji: "⛽",
    },
    {
        key: "wheel",
        name: "Wheel",
        emoji: "🛞",
    },
    {
        key: "rotating_light",
        name: "Police Car Light",
        emoji: "🚨",
    },
    {
        key: "traffic_light",
        name: "Horizontal Traffic Light",
        emoji: "🚥",
    },
    {
        key: "vertical_traffic_light",
        name: "Vertical Traffic Light",
        emoji: "🚦",
    },
    {
        key: "octagonal_sign",
        name: "Stop Sign",
        emoji: "🛑",
    },
    {
        key: "construction",
        name: "Construction",
        emoji: "🚧",
    },
    {
        key: "anchor",
        name: "Anchor",
        emoji: "⚓",
    },
    {
        key: "ring_buoy",
        name: "Ring Buoy",
        emoji: "🛟",
    },
    {
        key: "boat",
        name: "Sailboat",
        emoji: "⛵",
    },
    {
        key: "canoe",
        name: "Canoe",
        emoji: "🛶",
    },
    {
        key: "speedboat",
        name: "Speedboat",
        emoji: "🚤",
    },
    {
        key: "passenger_ship",
        name: "Passenger Ship",
        emoji: "🛳️",
    },
    {
        key: "ferry",
        name: "Ferry",
        emoji: "⛴️",
    },
    {
        key: "motor_boat",
        name: "Motor Boat",
        emoji: "🛥️",
    },
    {
        key: "ship",
        name: "Ship",
        emoji: "🚢",
    },
    {
        key: "airplane",
        name: "Airplane",
        emoji: "✈️",
    },
    {
        key: "small_airplane",
        name: "Small Airplane",
        emoji: "🛩️",
    },
    {
        key: "airplane_departure",
        name: "Airplane Departure",
        emoji: "🛫",
    },
    {
        key: "airplane_arriving",
        name: "Airplane Arrival",
        emoji: "🛬",
    },
    {
        key: "parachute",
        name: "Parachute",
        emoji: "🪂",
    },
    {
        key: "seat",
        name: "Seat",
        emoji: "💺",
    },
    {
        key: "helicopter",
        name: "Helicopter",
        emoji: "🚁",
    },
    {
        key: "suspension_railway",
        name: "Suspension Railway",
        emoji: "🚟",
    },
    {
        key: "mountain_cableway",
        name: "Mountain Cableway",
        emoji: "🚠",
    },
    {
        key: "aerial_tramway",
        name: "Aerial Tramway",
        emoji: "🚡",
    },
    {
        key: "satellite",
        name: "Satellite",
        emoji: "🛰️",
    },
    {
        key: "rocket",
        name: "Rocket",
        emoji: "🚀",
    },
    {
        key: "flying_saucer",
        name: "Flying Saucer",
        emoji: "🛸",
    },
    {
        key: "bellhop_bell",
        name: "Bellhop Bell",
        emoji: "🛎️",
    },
    {
        key: "luggage",
        name: "Luggage",
        emoji: "🧳",
    },
    {
        key: "hourglass",
        name: "Hourglass",
        emoji: "⌛",
    },
    {
        key: "hourglass_flowing_sand",
        name: "Hourglass Not Done",
        emoji: "⏳",
    },
    {
        key: "watch",
        name: "Watch",
        emoji: "⌚",
    },
    {
        key: "alarm_clock",
        name: "Alarm Clock",
        emoji: "⏰",
    },
    {
        key: "stopwatch",
        name: "Stopwatch",
        emoji: "⏱️",
    },
    {
        key: "timer_clock",
        name: "Timer Clock",
        emoji: "⏲️",
    },
    {
        key: "mantelpiece_clock",
        name: "Mantelpiece Clock",
        emoji: "🕰️",
    },
    {
        key: "clock12",
        name: "Twelve O'clock",
        emoji: "🕛",
    },
    {
        key: "clock1230",
        name: "Twelve-Thirty",
        emoji: "🕧",
    },
    {
        key: "clock1",
        name: "One O'clock",
        emoji: "🕐",
    },
    {
        key: "clock130",
        name: "One-Thirty",
        emoji: "🕜",
    },
    {
        key: "clock2",
        name: "Two O'clock",
        emoji: "🕑",
    },
    {
        key: "clock230",
        name: "Two-Thirty",
        emoji: "🕝",
    },
    {
        key: "clock3",
        name: "Three O'clock",
        emoji: "🕒",
    },
    {
        key: "clock330",
        name: "Three-Thirty",
        emoji: "🕞",
    },
    {
        key: "clock4",
        name: "Four O'clock",
        emoji: "🕓",
    },
    {
        key: "clock430",
        name: "Four-Thirty",
        emoji: "🕟",
    },
    {
        key: "clock5",
        name: "Five O'clock",
        emoji: "🕔",
    },
    {
        key: "clock530",
        name: "Five-Thirty",
        emoji: "🕠",
    },
    {
        key: "clock6",
        name: "Six O'clock",
        emoji: "🕕",
    },
    {
        key: "clock630",
        name: "Six-Thirty",
        emoji: "🕡",
    },
    {
        key: "clock7",
        name: "Seven O'clock",
        emoji: "🕖",
    },
    {
        key: "clock730",
        name: "Seven-Thirty",
        emoji: "🕢",
    },
    {
        key: "clock8",
        name: "Eight O'clock",
        emoji: "🕗",
    },
    {
        key: "clock830",
        name: "Eight-Thirty",
        emoji: "🕣",
    },
    {
        key: "clock9",
        name: "Nine O'clock",
        emoji: "🕘",
    },
    {
        key: "clock930",
        name: "Nine-Thirty",
        emoji: "🕤",
    },
    {
        key: "clock10",
        name: "Ten O'clock",
        emoji: "🕙",
    },
    {
        key: "clock1030",
        name: "Ten-Thirty",
        emoji: "🕥",
    },
    {
        key: "clock11",
        name: "Eleven O'clock",
        emoji: "🕚",
    },
    {
        key: "clock1130",
        name: "Eleven-Thirty",
        emoji: "🕦",
    },
    {
        key: "new_moon",
        name: "New Moon",
        emoji: "🌑",
    },
    {
        key: "waxing_crescent_moon",
        name: "Waxing Crescent Moon",
        emoji: "🌒",
    },
    {
        key: "first_quarter_moon",
        name: "First Quarter Moon",
        emoji: "🌓",
    },
    {
        key: "moon",
        name: "Waxing Gibbous Moon",
        emoji: "🌔",
    },
    {
        key: "full_moon",
        name: "Full Moon",
        emoji: "🌕",
    },
    {
        key: "waning_gibbous_moon",
        name: "Waning Gibbous Moon",
        emoji: "🌖",
    },
    {
        key: "last_quarter_moon",
        name: "Last Quarter Moon",
        emoji: "🌗",
    },
    {
        key: "waning_crescent_moon",
        name: "Waning Crescent Moon",
        emoji: "🌘",
    },
    {
        key: "crescent_moon",
        name: "Crescent Moon",
        emoji: "🌙",
    },
    {
        key: "new_moon_with_face",
        name: "New Moon Face",
        emoji: "🌚",
    },
    {
        key: "first_quarter_moon_with_face",
        name: "First Quarter Moon Face",
        emoji: "🌛",
    },
    {
        key: "last_quarter_moon_with_face",
        name: "Last Quarter Moon Face",
        emoji: "🌜",
    },
    {
        key: "thermometer",
        name: "Thermometer",
        emoji: "🌡️",
    },
    {
        key: "sunny",
        name: "Sun",
        emoji: "☀️",
    },
    {
        key: "full_moon_with_face",
        name: "Full Moon Face",
        emoji: "🌝",
    },
    {
        key: "sun_with_face",
        name: "Sun with Face",
        emoji: "🌞",
    },
    {
        key: "ringed_planet",
        name: "Ringed Planet",
        emoji: "🪐",
    },
    {
        key: "star",
        name: "Star",
        emoji: "⭐",
    },
    {
        key: "star2",
        name: "Glowing Star",
        emoji: "🌟",
    },
    {
        key: "stars",
        name: "Shooting Star",
        emoji: "🌠",
    },
    {
        key: "milky_way",
        name: "Milky Way",
        emoji: "🌌",
    },
    {
        key: "cloud",
        name: "Cloud",
        emoji: "☁️",
    },
    {
        key: "partly_sunny",
        name: "Sun Behind Cloud",
        emoji: "⛅",
    },
    {
        key: "thunder_cloud_and_rain",
        name: "Cloud with Lightning and Rain",
        emoji: "⛈️",
    },
    {
        key: "mostly_sunny",
        name: "Sun Behind Small Cloud",
        emoji: "🌤️",
    },
    {
        key: "barely_sunny",
        name: "Sun Behind Large Cloud",
        emoji: "🌥️",
    },
    {
        key: "partly_sunny_rain",
        name: "Sun Behind Rain Cloud",
        emoji: "🌦️",
    },
    {
        key: "rain_cloud",
        name: "Cloud with Rain",
        emoji: "🌧️",
    },
    {
        key: "snow_cloud",
        name: "Cloud with Snow",
        emoji: "🌨️",
    },
    {
        key: "lightning",
        name: "Cloud with Lightning",
        emoji: "🌩️",
    },
    {
        key: "tornado",
        name: "Tornado",
        emoji: "🌪️",
    },
    {
        key: "fog",
        name: "Fog",
        emoji: "🌫️",
    },
    {
        key: "wind_blowing_face",
        name: "Wind Face",
        emoji: "🌬️",
    },
    {
        key: "cyclone",
        name: "Cyclone",
        emoji: "🌀",
    },
    {
        key: "rainbow",
        name: "Rainbow",
        emoji: "🌈",
    },
    {
        key: "closed_umbrella",
        name: "Closed Umbrella",
        emoji: "🌂",
    },
    {
        key: "umbrella",
        name: "Umbrella",
        emoji: "☂️",
    },
    {
        key: "umbrella_with_rain_drops",
        name: "Umbrella with Rain Drops",
        emoji: "☔",
    },
    {
        key: "umbrella_on_ground",
        name: "Umbrella on Ground",
        emoji: "⛱️",
    },
    {
        key: "zap",
        name: "High Voltage",
        emoji: "⚡",
    },
    {
        key: "snowflake",
        name: "Snowflake",
        emoji: "❄️",
    },
    {
        key: "snowman",
        name: "Snowman",
        emoji: "☃️",
    },
    {
        key: "snowman_without_snow",
        name: "Snowman Without Snow",
        emoji: "⛄",
    },
    {
        key: "comet",
        name: "Comet",
        emoji: "☄️",
    },
    {
        key: "fire",
        name: "Fire",
        emoji: "🔥",
    },
    {
        key: "droplet",
        name: "Droplet",
        emoji: "💧",
    },
    {
        key: "ocean",
        name: "Water Wave",
        emoji: "🌊",
    },
    {
        key: "jack_o_lantern",
        name: "Jack-O-Lantern",
        emoji: "🎃",
    },
    {
        key: "christmas_tree",
        name: "Christmas Tree",
        emoji: "🎄",
    },
    {
        key: "fireworks",
        name: "Fireworks",
        emoji: "🎆",
    },
    {
        key: "sparkler",
        name: "Sparkler",
        emoji: "🎇",
    },
    {
        key: "firecracker",
        name: "Firecracker",
        emoji: "🧨",
    },
    {
        key: "sparkles",
        name: "Sparkles",
        emoji: "✨",
    },
    {
        key: "balloon",
        name: "Balloon",
        emoji: "🎈",
    },
    {
        key: "tada",
        name: "Party Popper",
        emoji: "🎉",
    },
    {
        key: "confetti_ball",
        name: "Confetti Ball",
        emoji: "🎊",
    },
    {
        key: "tanabata_tree",
        name: "Tanabata Tree",
        emoji: "🎋",
    },
    {
        key: "bamboo",
        name: "Pine Decoration",
        emoji: "🎍",
    },
    {
        key: "dolls",
        name: "Japanese Dolls",
        emoji: "🎎",
    },
    {
        key: "flags",
        name: "Carp Streamer",
        emoji: "🎏",
    },
    {
        key: "wind_chime",
        name: "Wind Chime",
        emoji: "🎐",
    },
    {
        key: "rice_scene",
        name: "Moon Viewing Ceremony",
        emoji: "🎑",
    },
    {
        key: "red_envelope",
        name: "Red Envelope",
        emoji: "🧧",
    },
    {
        key: "ribbon",
        name: "Ribbon",
        emoji: "🎀",
    },
    {
        key: "gift",
        name: "Wrapped Gift",
        emoji: "🎁",
    },
    {
        key: "reminder_ribbon",
        name: "Reminder Ribbon",
        emoji: "🎗️",
    },
    {
        key: "admission_tickets",
        name: "Admission Tickets",
        emoji: "🎟️",
    },
    {
        key: "ticket",
        name: "Ticket",
        emoji: "🎫",
    },
    {
        key: "medal",
        name: "Military Medal",
        emoji: "🎖️",
    },
    {
        key: "trophy",
        name: "Trophy",
        emoji: "🏆",
    },
    {
        key: "sports_medal",
        name: "Sports Medal",
        emoji: "🏅",
    },
    {
        key: "first_place_medal",
        name: "1st Place Medal",
        emoji: "🥇",
    },
    {
        key: "second_place_medal",
        name: "2nd Place Medal",
        emoji: "🥈",
    },
    {
        key: "third_place_medal",
        name: "3rd Place Medal",
        emoji: "🥉",
    },
    {
        key: "soccer",
        name: "Soccer Ball",
        emoji: "⚽",
    },
    {
        key: "baseball",
        name: "Baseball",
        emoji: "⚾",
    },
    {
        key: "softball",
        name: "Softball",
        emoji: "🥎",
    },
    {
        key: "basketball",
        name: "Basketball",
        emoji: "🏀",
    },
    {
        key: "volleyball",
        name: "Volleyball",
        emoji: "🏐",
    },
    {
        key: "football",
        name: "American Football",
        emoji: "🏈",
    },
    {
        key: "rugby_football",
        name: "Rugby Football",
        emoji: "🏉",
    },
    {
        key: "tennis",
        name: "Tennis",
        emoji: "🎾",
    },
    {
        key: "flying_disc",
        name: "Flying Disc",
        emoji: "🥏",
    },
    {
        key: "bowling",
        name: "Bowling",
        emoji: "🎳",
    },
    {
        key: "cricket_bat_and_ball",
        name: "Cricket Game",
        emoji: "🏏",
    },
    {
        key: "field_hockey_stick_and_ball",
        name: "Field Hockey",
        emoji: "🏑",
    },
    {
        key: "ice_hockey_stick_and_puck",
        name: "Ice Hockey",
        emoji: "🏒",
    },
    {
        key: "lacrosse",
        name: "Lacrosse",
        emoji: "🥍",
    },
    {
        key: "table_tennis_paddle_and_ball",
        name: "Ping Pong",
        emoji: "🏓",
    },
    {
        key: "badminton_racquet_and_shuttlecock",
        name: "Badminton",
        emoji: "🏸",
    },
    {
        key: "boxing_glove",
        name: "Boxing Glove",
        emoji: "🥊",
    },
    {
        key: "martial_arts_uniform",
        name: "Martial Arts Uniform",
        emoji: "🥋",
    },
    {
        key: "goal_net",
        name: "Goal Net",
        emoji: "🥅",
    },
    {
        key: "golf",
        name: "Flag in Hole",
        emoji: "⛳",
    },
    {
        key: "ice_skate",
        name: "Ice Skate",
        emoji: "⛸️",
    },
    {
        key: "fishing_pole_and_fish",
        name: "Fishing Pole",
        emoji: "🎣",
    },
    {
        key: "diving_mask",
        name: "Diving Mask",
        emoji: "🤿",
    },
    {
        key: "running_shirt_with_sash",
        name: "Running Shirt",
        emoji: "🎽",
    },
    {
        key: "ski",
        name: "Skis",
        emoji: "🎿",
    },
    {
        key: "sled",
        name: "Sled",
        emoji: "🛷",
    },
    {
        key: "curling_stone",
        name: "Curling Stone",
        emoji: "🥌",
    },
    {
        key: "dart",
        name: "Bullseye",
        emoji: "🎯",
    },
    {
        key: "yo-yo",
        name: "Yo-Yo",
        emoji: "🪀",
    },
    {
        key: "kite",
        name: "Kite",
        emoji: "🪁",
    },
    {
        key: "gun",
        name: "Pistol",
        emoji: "🔫",
    },
    {
        key: "8ball",
        name: "Billiards",
        emoji: "🎱",
    },
    {
        key: "crystal_ball",
        name: "Crystal Ball",
        emoji: "🔮",
    },
    {
        key: "magic_wand",
        name: "Magic Wand",
        emoji: "🪄",
    },
    {
        key: "video_game",
        name: "Video Game",
        emoji: "🎮",
    },
    {
        key: "joystick",
        name: "Joystick",
        emoji: "🕹️",
    },
    {
        key: "slot_machine",
        name: "Slot Machine",
        emoji: "🎰",
    },
    {
        key: "game_die",
        name: "Game Die",
        emoji: "🎲",
    },
    {
        key: "jigsaw",
        name: "Puzzle Piece",
        emoji: "🧩",
    },
    {
        key: "teddy_bear",
        name: "Teddy Bear",
        emoji: "🧸",
    },
    {
        key: "pinata",
        name: "Pinata",
        emoji: "🪅",
    },
    {
        key: "mirror_ball",
        name: "Mirror Ball",
        emoji: "🪩",
    },
    {
        key: "nesting_dolls",
        name: "Nesting Dolls",
        emoji: "🪆",
    },
    {
        key: "spades",
        name: "Spade Suit",
        emoji: "♠️",
    },
    {
        key: "hearts",
        name: "Heart Suit",
        emoji: "♥️",
    },
    {
        key: "diamonds",
        name: "Diamond Suit",
        emoji: "♦️",
    },
    {
        key: "clubs",
        name: "Club Suit",
        emoji: "♣️",
    },
    {
        key: "chess_pawn",
        name: "Chess Pawn",
        emoji: "♟️",
    },
    {
        key: "black_joker",
        name: "Joker",
        emoji: "🃏",
    },
    {
        key: "mahjong",
        name: "Mahjong Red Dragon",
        emoji: "🀄",
    },
    {
        key: "flower_playing_cards",
        name: "Flower Playing Cards",
        emoji: "🎴",
    },
    {
        key: "performing_arts",
        name: "Performing Arts",
        emoji: "🎭",
    },
    {
        key: "frame_with_picture",
        name: "Framed Picture",
        emoji: "🖼️",
    },
    {
        key: "art",
        name: "Artist Palette",
        emoji: "🎨",
    },
    {
        key: "thread",
        name: "Thread",
        emoji: "🧵",
    },
    {
        key: "sewing_needle",
        name: "Sewing Needle",
        emoji: "🪡",
    },
    {
        key: "yarn",
        name: "Yarn",
        emoji: "🧶",
    },
    {
        key: "knot",
        name: "Knot",
        emoji: "🪢",
    },
    {
        key: "eyeglasses",
        name: "Glasses",
        emoji: "👓",
    },
    {
        key: "dark_sunglasses",
        name: "Sunglasses",
        emoji: "🕶️",
    },
    {
        key: "goggles",
        name: "Goggles",
        emoji: "🥽",
    },
    {
        key: "lab_coat",
        name: "Lab Coat",
        emoji: "🥼",
    },
    {
        key: "safety_vest",
        name: "Safety Vest",
        emoji: "🦺",
    },
    {
        key: "necktie",
        name: "Necktie",
        emoji: "👔",
    },
    {
        key: "shirt",
        name: "T-Shirt",
        emoji: "👕",
    },
    {
        key: "jeans",
        name: "Jeans",
        emoji: "👖",
    },
    {
        key: "scarf",
        name: "Scarf",
        emoji: "🧣",
    },
    {
        key: "gloves",
        name: "Gloves",
        emoji: "🧤",
    },
    {
        key: "coat",
        name: "Coat",
        emoji: "🧥",
    },
    {
        key: "socks",
        name: "Socks",
        emoji: "🧦",
    },
    {
        key: "dress",
        name: "Dress",
        emoji: "👗",
    },
    {
        key: "kimono",
        name: "Kimono",
        emoji: "👘",
    },
    {
        key: "sari",
        name: "Sari",
        emoji: "🥻",
    },
    {
        key: "one-piece_swimsuit",
        name: "One-Piece Swimsuit",
        emoji: "🩱",
    },
    {
        key: "briefs",
        name: "Briefs",
        emoji: "🩲",
    },
    {
        key: "shorts",
        name: "Shorts",
        emoji: "🩳",
    },
    {
        key: "bikini",
        name: "Bikini",
        emoji: "👙",
    },
    {
        key: "womans_clothes",
        name: "Womans Clothes",
        emoji: "👚",
    },
    {
        key: "purse",
        name: "Purse",
        emoji: "👛",
    },
    {
        key: "handbag",
        name: "Handbag",
        emoji: "👜",
    },
    {
        key: "pouch",
        name: "Pouch",
        emoji: "👝",
    },
    {
        key: "shopping_bags",
        name: "Shopping Bags",
        emoji: "🛍️",
    },
    {
        key: "school_satchel",
        name: "Backpack",
        emoji: "🎒",
    },
    {
        key: "thong_sandal",
        name: "Thong Sandal",
        emoji: "🩴",
    },
    {
        key: "mans_shoe",
        name: "Mans Shoe",
        emoji: "👞",
    },
    {
        key: "athletic_shoe",
        name: "Running Shoe",
        emoji: "👟",
    },
    {
        key: "hiking_boot",
        name: "Hiking Boot",
        emoji: "🥾",
    },
    {
        key: "womans_flat_shoe",
        name: "Flat Shoe",
        emoji: "🥿",
    },
    {
        key: "high_heel",
        name: "High-Heeled Shoe",
        emoji: "👠",
    },
    {
        key: "sandal",
        name: "Womans Sandal",
        emoji: "👡",
    },
    {
        key: "ballet_shoes",
        name: "Ballet Shoes",
        emoji: "🩰",
    },
    {
        key: "boot",
        name: "Womans Boots",
        emoji: "👢",
    },
    {
        key: "crown",
        name: "Crown",
        emoji: "👑",
    },
    {
        key: "womans_hat",
        name: "Womans Hat",
        emoji: "👒",
    },
    {
        key: "tophat",
        name: "Top Hat",
        emoji: "🎩",
    },
    {
        key: "mortar_board",
        name: "Graduation Cap",
        emoji: "🎓",
    },
    {
        key: "billed_cap",
        name: "Billed Cap",
        emoji: "🧢",
    },
    {
        key: "military_helmet",
        name: "Military Helmet",
        emoji: "🪖",
    },
    {
        key: "helmet_with_white_cross",
        name: "Rescue Worker's Helmet",
        emoji: "⛑️",
    },
    {
        key: "prayer_beads",
        name: "Prayer Beads",
        emoji: "📿",
    },
    {
        key: "lipstick",
        name: "Lipstick",
        emoji: "💄",
    },
    {
        key: "ring",
        name: "Ring",
        emoji: "💍",
    },
    {
        key: "gem",
        name: "Gem Stone",
        emoji: "💎",
    },
    {
        key: "mute",
        name: "Muted Speaker",
        emoji: "🔇",
    },
    {
        key: "speaker",
        name: "Speaker",
        emoji: "🔈",
    },
    {
        key: "sound",
        name: "Speaker Medium Volume",
        emoji: "🔉",
    },
    {
        key: "loud_sound",
        name: "Speaker High Volume",
        emoji: "🔊",
    },
    {
        key: "loudspeaker",
        name: "Loudspeaker",
        emoji: "📢",
    },
    {
        key: "mega",
        name: "Megaphone",
        emoji: "📣",
    },
    {
        key: "postal_horn",
        name: "Postal Horn",
        emoji: "📯",
    },
    {
        key: "bell",
        name: "Bell",
        emoji: "🔔",
    },
    {
        key: "no_bell",
        name: "Bell with Slash",
        emoji: "🔕",
    },
    {
        key: "musical_score",
        name: "Musical Score",
        emoji: "🎼",
    },
    {
        key: "musical_note",
        name: "Musical Note",
        emoji: "🎵",
    },
    {
        key: "notes",
        name: "Musical Notes",
        emoji: "🎶",
    },
    {
        key: "studio_microphone",
        name: "Studio Microphone",
        emoji: "🎙️",
    },
    {
        key: "level_slider",
        name: "Level Slider",
        emoji: "🎚️",
    },
    {
        key: "control_knobs",
        name: "Control Knobs",
        emoji: "🎛️",
    },
    {
        key: "microphone",
        name: "Microphone",
        emoji: "🎤",
    },
    {
        key: "headphones",
        name: "Headphone",
        emoji: "🎧",
    },
    {
        key: "radio",
        name: "Radio",
        emoji: "📻",
    },
    {
        key: "saxophone",
        name: "Saxophone",
        emoji: "🎷",
    },
    {
        key: "accordion",
        name: "Accordion",
        emoji: "🪗",
    },
    {
        key: "guitar",
        name: "Guitar",
        emoji: "🎸",
    },
    {
        key: "musical_keyboard",
        name: "Musical Keyboard",
        emoji: "🎹",
    },
    {
        key: "trumpet",
        name: "Trumpet",
        emoji: "🎺",
    },
    {
        key: "violin",
        name: "Violin",
        emoji: "🎻",
    },
    {
        key: "banjo",
        name: "Banjo",
        emoji: "🪕",
    },
    {
        key: "drum_with_drumsticks",
        name: "Drum",
        emoji: "🥁",
    },
    {
        key: "long_drum",
        name: "Long Drum",
        emoji: "🪘",
    },
    {
        key: "iphone",
        name: "Mobile Phone",
        emoji: "📱",
    },
    {
        key: "calling",
        name: "Mobile Phone with Arrow",
        emoji: "📲",
    },
    {
        key: "phone",
        name: "Telephone",
        emoji: "☎️",
    },
    {
        key: "telephone_receiver",
        name: "Telephone Receiver",
        emoji: "📞",
    },
    {
        key: "pager",
        name: "Pager",
        emoji: "📟",
    },
    {
        key: "fax",
        name: "Fax Machine",
        emoji: "📠",
    },
    {
        key: "battery",
        name: "Battery",
        emoji: "🔋",
    },
    {
        key: "low_battery",
        name: "Low Battery",
        emoji: "🪫",
    },
    {
        key: "electric_plug",
        name: "Electric Plug",
        emoji: "🔌",
    },
    {
        key: "computer",
        name: "Laptop",
        emoji: "💻",
    },
    {
        key: "desktop_computer",
        name: "Desktop Computer",
        emoji: "🖥️",
    },
    {
        key: "printer",
        name: "Printer",
        emoji: "🖨️",
    },
    {
        key: "keyboard",
        name: "Keyboard",
        emoji: "⌨️",
    },
    {
        key: "three_button_mouse",
        name: "Computer Mouse",
        emoji: "🖱️",
    },
    {
        key: "trackball",
        name: "Trackball",
        emoji: "🖲️",
    },
    {
        key: "minidisc",
        name: "Minidisc",
        emoji: "💽",
    },
    {
        key: "floppy_disk",
        name: "Floppy Disk",
        emoji: "💾",
    },
    {
        key: "cd",
        name: "Optical Disc",
        emoji: "💿",
    },
    {
        key: "dvd",
        name: "Dvd",
        emoji: "📀",
    },
    {
        key: "abacus",
        name: "Abacus",
        emoji: "🧮",
    },
    {
        key: "movie_camera",
        name: "Movie Camera",
        emoji: "🎥",
    },
    {
        key: "film_frames",
        name: "Film Frames",
        emoji: "🎞️",
    },
    {
        key: "film_projector",
        name: "Film Projector",
        emoji: "📽️",
    },
    {
        key: "clapper",
        name: "Clapper Board",
        emoji: "🎬",
    },
    {
        key: "tv",
        name: "Television",
        emoji: "📺",
    },
    {
        key: "camera",
        name: "Camera",
        emoji: "📷",
    },
    {
        key: "camera_with_flash",
        name: "Camera with Flash",
        emoji: "📸",
    },
    {
        key: "video_camera",
        name: "Video Camera",
        emoji: "📹",
    },
    {
        key: "vhs",
        name: "Videocassette",
        emoji: "📼",
    },
    {
        key: "mag",
        name: "Magnifying Glass Tilted Left",
        emoji: "🔍",
    },
    {
        key: "mag_right",
        name: "Magnifying Glass Tilted Right",
        emoji: "🔎",
    },
    {
        key: "candle",
        name: "Candle",
        emoji: "🕯️",
    },
    {
        key: "bulb",
        name: "Light Bulb",
        emoji: "💡",
    },
    {
        key: "flashlight",
        name: "Flashlight",
        emoji: "🔦",
    },
    {
        key: "izakaya_lantern",
        name: "Izakaya Lantern",
        emoji: "🏮",
    },
    {
        key: "diya_lamp",
        name: "Diya Lamp",
        emoji: "🪔",
    },
    {
        key: "notebook_with_decorative_cover",
        name: "Notebook with Decorative Cover",
        emoji: "📔",
    },
    {
        key: "closed_book",
        name: "Closed Book",
        emoji: "📕",
    },
    {
        key: "book",
        name: "Open Book",
        emoji: "📖",
    },
    {
        key: "green_book",
        name: "Green Book",
        emoji: "📗",
    },
    {
        key: "blue_book",
        name: "Blue Book",
        emoji: "📘",
    },
    {
        key: "orange_book",
        name: "Orange Book",
        emoji: "📙",
    },
    {
        key: "books",
        name: "Books",
        emoji: "📚",
    },
    {
        key: "notebook",
        name: "Notebook",
        emoji: "📓",
    },
    {
        key: "ledger",
        name: "Ledger",
        emoji: "📒",
    },
    {
        key: "page_with_curl",
        name: "Page with Curl",
        emoji: "📃",
    },
    {
        key: "scroll",
        name: "Scroll",
        emoji: "📜",
    },
    {
        key: "page_facing_up",
        name: "Page Facing Up",
        emoji: "📄",
    },
    {
        key: "newspaper",
        name: "Newspaper",
        emoji: "📰",
    },
    {
        key: "rolled_up_newspaper",
        name: "Rolled-Up Newspaper",
        emoji: "🗞️",
    },
    {
        key: "bookmark_tabs",
        name: "Bookmark Tabs",
        emoji: "📑",
    },
    {
        key: "bookmark",
        name: "Bookmark",
        emoji: "🔖",
    },
    {
        key: "label",
        name: "Label",
        emoji: "🏷️",
    },
    {
        key: "moneybag",
        name: "Money Bag",
        emoji: "💰",
    },
    {
        key: "coin",
        name: "Coin",
        emoji: "🪙",
    },
    {
        key: "yen",
        name: "Yen Banknote",
        emoji: "💴",
    },
    {
        key: "dollar",
        name: "Dollar Banknote",
        emoji: "💵",
    },
    {
        key: "euro",
        name: "Euro Banknote",
        emoji: "💶",
    },
    {
        key: "pound",
        name: "Pound Banknote",
        emoji: "💷",
    },
    {
        key: "money_with_wings",
        name: "Money with Wings",
        emoji: "💸",
    },
    {
        key: "credit_card",
        name: "Credit Card",
        emoji: "💳",
    },
    {
        key: "receipt",
        name: "Receipt",
        emoji: "🧾",
    },
    {
        key: "chart",
        name: "Chart Increasing with Yen",
        emoji: "💹",
    },
    {
        key: "email",
        name: "Envelope",
        emoji: "✉️",
    },
    {
        key: "e-mail",
        name: "E-Mail",
        emoji: "📧",
    },
    {
        key: "incoming_envelope",
        name: "Incoming Envelope",
        emoji: "📨",
    },
    {
        key: "envelope_with_arrow",
        name: "Envelope with Arrow",
        emoji: "📩",
    },
    {
        key: "outbox_tray",
        name: "Outbox Tray",
        emoji: "📤",
    },
    {
        key: "inbox_tray",
        name: "Inbox Tray",
        emoji: "📥",
    },
    {
        key: "package",
        name: "Package",
        emoji: "📦",
    },
    {
        key: "mailbox",
        name: "Closed Mailbox with Raised Flag",
        emoji: "📫",
    },
    {
        key: "mailbox_closed",
        name: "Closed Mailbox with Lowered Flag",
        emoji: "📪",
    },
    {
        key: "mailbox_with_mail",
        name: "Open Mailbox with Raised Flag",
        emoji: "📬",
    },
    {
        key: "mailbox_with_no_mail",
        name: "Open Mailbox with Lowered Flag",
        emoji: "📭",
    },
    {
        key: "postbox",
        name: "Postbox",
        emoji: "📮",
    },
    {
        key: "ballot_box_with_ballot",
        name: "Ballot Box with Ballot",
        emoji: "🗳️",
    },
    {
        key: "pencil2",
        name: "Pencil",
        emoji: "✏️",
    },
    {
        key: "black_nib",
        name: "Black Nib",
        emoji: "✒️",
    },
    {
        key: "lower_left_fountain_pen",
        name: "Fountain Pen",
        emoji: "🖋️",
    },
    {
        key: "lower_left_ballpoint_pen",
        name: "Pen",
        emoji: "🖊️",
    },
    {
        key: "lower_left_paintbrush",
        name: "Paintbrush",
        emoji: "🖌️",
    },
    {
        key: "lower_left_crayon",
        name: "Crayon",
        emoji: "🖍️",
    },
    {
        key: "memo",
        name: "Memo",
        emoji: "📝",
    },
    {
        key: "briefcase",
        name: "Briefcase",
        emoji: "💼",
    },
    {
        key: "file_folder",
        name: "File Folder",
        emoji: "📁",
    },
    {
        key: "open_file_folder",
        name: "Open File Folder",
        emoji: "📂",
    },
    {
        key: "card_index_dividers",
        name: "Card Index Dividers",
        emoji: "🗂️",
    },
    {
        key: "date",
        name: "Calendar",
        emoji: "📅",
    },
    {
        key: "calendar",
        name: "Tear-off Calendar",
        emoji: "📆",
    },
    {
        key: "spiral_note_pad",
        name: "Spiral Notepad",
        emoji: "🗒️",
    },
    {
        key: "spiral_calendar_pad",
        name: "Spiral Calendar",
        emoji: "🗓️",
    },
    {
        key: "card_index",
        name: "Card Index",
        emoji: "📇",
    },
    {
        key: "chart_with_upwards_trend",
        name: "Chart Increasing",
        emoji: "📈",
    },
    {
        key: "chart_with_downwards_trend",
        name: "Chart Decreasing",
        emoji: "📉",
    },
    {
        key: "bar_chart",
        name: "Bar Chart",
        emoji: "📊",
    },
    {
        key: "clipboard",
        name: "Clipboard",
        emoji: "📋",
    },
    {
        key: "pushpin",
        name: "Pushpin",
        emoji: "📌",
    },
    {
        key: "round_pushpin",
        name: "Round Pushpin",
        emoji: "📍",
    },
    {
        key: "paperclip",
        name: "Paperclip",
        emoji: "📎",
    },
    {
        key: "linked_paperclips",
        name: "Linked Paperclips",
        emoji: "🖇️",
    },
    {
        key: "straight_ruler",
        name: "Straight Ruler",
        emoji: "📏",
    },
    {
        key: "triangular_ruler",
        name: "Triangular Ruler",
        emoji: "📐",
    },
    {
        key: "scissors",
        name: "Scissors",
        emoji: "✂️",
    },
    {
        key: "card_file_box",
        name: "Card File Box",
        emoji: "🗃️",
    },
    {
        key: "file_cabinet",
        name: "File Cabinet",
        emoji: "🗄️",
    },
    {
        key: "wastebasket",
        name: "Wastebasket",
        emoji: "🗑️",
    },
    {
        key: "lock",
        name: "Lock",
        emoji: "🔒",
    },
    {
        key: "unlock",
        name: "Unlocked",
        emoji: "🔓",
    },
    {
        key: "lock_with_ink_pen",
        name: "Locked with Pen",
        emoji: "🔏",
    },
    {
        key: "closed_lock_with_key",
        name: "Locked with Key",
        emoji: "🔐",
    },
    {
        key: "key",
        name: "Key",
        emoji: "🔑",
    },
    {
        key: "old_key",
        name: "Old Key",
        emoji: "🗝️",
    },
    {
        key: "hammer",
        name: "Hammer",
        emoji: "🔨",
    },
    {
        key: "axe",
        name: "Axe",
        emoji: "🪓",
    },
    {
        key: "pick",
        name: "Pick",
        emoji: "⛏️",
    },
    {
        key: "hammer_and_pick",
        name: "Hammer and Pick",
        emoji: "⚒️",
    },
    {
        key: "hammer_and_wrench",
        name: "Hammer and Wrench",
        emoji: "🛠️",
    },
    {
        key: "dagger_knife",
        name: "Dagger",
        emoji: "🗡️",
    },
    {
        key: "crossed_swords",
        name: "Crossed Swords",
        emoji: "⚔️",
    },
    {
        key: "bomb",
        name: "Bomb",
        emoji: "💣",
    },
    {
        key: "boomerang",
        name: "Boomerang",
        emoji: "🪃",
    },
    {
        key: "bow_and_arrow",
        name: "Bow and Arrow",
        emoji: "🏹",
    },
    {
        key: "shield",
        name: "Shield",
        emoji: "🛡️",
    },
    {
        key: "carpentry_saw",
        name: "Carpentry Saw",
        emoji: "🪚",
    },
    {
        key: "wrench",
        name: "Wrench",
        emoji: "🔧",
    },
    {
        key: "screwdriver",
        name: "Screwdriver",
        emoji: "🪛",
    },
    {
        key: "nut_and_bolt",
        name: "Nut and Bolt",
        emoji: "🔩",
    },
    {
        key: "gear",
        name: "Gear",
        emoji: "⚙️",
    },
    {
        key: "compression",
        name: "Clamp",
        emoji: "🗜️",
    },
    {
        key: "scales",
        name: "Balance Scale",
        emoji: "⚖️",
    },
    {
        key: "probing_cane",
        name: "White Cane",
        emoji: "🦯",
    },
    {
        key: "link",
        name: "Link",
        emoji: "🔗",
    },
    {
        key: "chains",
        name: "Chains",
        emoji: "⛓️",
    },
    {
        key: "hook",
        name: "Hook",
        emoji: "🪝",
    },
    {
        key: "toolbox",
        name: "Toolbox",
        emoji: "🧰",
    },
    {
        key: "magnet",
        name: "Magnet",
        emoji: "🧲",
    },
    {
        key: "ladder",
        name: "Ladder",
        emoji: "🪜",
    },
    {
        key: "alembic",
        name: "Alembic",
        emoji: "⚗️",
    },
    {
        key: "test_tube",
        name: "Test Tube",
        emoji: "🧪",
    },
    {
        key: "petri_dish",
        name: "Petri Dish",
        emoji: "🧫",
    },
    {
        key: "dna",
        name: "Dna",
        emoji: "🧬",
    },
    {
        key: "microscope",
        name: "Microscope",
        emoji: "🔬",
    },
    {
        key: "telescope",
        name: "Telescope",
        emoji: "🔭",
    },
    {
        key: "satellite_antenna",
        name: "Satellite Antenna",
        emoji: "📡",
    },
    {
        key: "syringe",
        name: "Syringe",
        emoji: "💉",
    },
    {
        key: "drop_of_blood",
        name: "Drop of Blood",
        emoji: "🩸",
    },
    {
        key: "pill",
        name: "Pill",
        emoji: "💊",
    },
    {
        key: "adhesive_bandage",
        name: "Adhesive Bandage",
        emoji: "🩹",
    },
    {
        key: "crutch",
        name: "Crutch",
        emoji: "🩼",
    },
    {
        key: "stethoscope",
        name: "Stethoscope",
        emoji: "🩺",
    },
    {
        key: "x-ray",
        name: "X-Ray",
        emoji: "🩻",
    },
    {
        key: "door",
        name: "Door",
        emoji: "🚪",
    },
    {
        key: "elevator",
        name: "Elevator",
        emoji: "🛗",
    },
    {
        key: "mirror",
        name: "Mirror",
        emoji: "🪞",
    },
    {
        key: "window",
        name: "Window",
        emoji: "🪟",
    },
    {
        key: "bed",
        name: "Bed",
        emoji: "🛏️",
    },
    {
        key: "couch_and_lamp",
        name: "Couch and Lamp",
        emoji: "🛋️",
    },
    {
        key: "chair",
        name: "Chair",
        emoji: "🪑",
    },
    {
        key: "toilet",
        name: "Toilet",
        emoji: "🚽",
    },
    {
        key: "plunger",
        name: "Plunger",
        emoji: "🪠",
    },
    {
        key: "shower",
        name: "Shower",
        emoji: "🚿",
    },
    {
        key: "bathtub",
        name: "Bathtub",
        emoji: "🛁",
    },
    {
        key: "mouse_trap",
        name: "Mouse Trap",
        emoji: "🪤",
    },
    {
        key: "razor",
        name: "Razor",
        emoji: "🪒",
    },
    {
        key: "lotion_bottle",
        name: "Lotion Bottle",
        emoji: "🧴",
    },
    {
        key: "safety_pin",
        name: "Safety Pin",
        emoji: "🧷",
    },
    {
        key: "broom",
        name: "Broom",
        emoji: "🧹",
    },
    {
        key: "basket",
        name: "Basket",
        emoji: "🧺",
    },
    {
        key: "roll_of_paper",
        name: "Roll of Paper",
        emoji: "🧻",
    },
    {
        key: "bucket",
        name: "Bucket",
        emoji: "🪣",
    },
    {
        key: "soap",
        name: "Soap",
        emoji: "🧼",
    },
    {
        key: "bubbles",
        name: "Bubbles",
        emoji: "🫧",
    },
    {
        key: "toothbrush",
        name: "Toothbrush",
        emoji: "🪥",
    },
    {
        key: "sponge",
        name: "Sponge",
        emoji: "🧽",
    },
    {
        key: "fire_extinguisher",
        name: "Fire Extinguisher",
        emoji: "🧯",
    },
    {
        key: "shopping_trolley",
        name: "Shopping Cart",
        emoji: "🛒",
    },
    {
        key: "smoking",
        name: "Cigarette",
        emoji: "🚬",
    },
    {
        key: "coffin",
        name: "Coffin",
        emoji: "⚰️",
    },
    {
        key: "headstone",
        name: "Headstone",
        emoji: "🪦",
    },
    {
        key: "funeral_urn",
        name: "Funeral Urn",
        emoji: "⚱️",
    },
    {
        key: "nazar_amulet",
        name: "Nazar Amulet",
        emoji: "🧿",
    },
    {
        key: "hamsa",
        name: "Hamsa",
        emoji: "🪬",
    },
    {
        key: "moyai",
        name: "Moai",
        emoji: "🗿",
    },
    {
        key: "placard",
        name: "Placard",
        emoji: "🪧",
    },
    {
        key: "identification_card",
        name: "Identification Card",
        emoji: "🪪",
    },
    {
        key: "atm",
        name: "Atm Sign",
        emoji: "🏧",
    },
    {
        key: "put_litter_in_its_place",
        name: "Litter in Bin Sign",
        emoji: "🚮",
    },
    {
        key: "potable_water",
        name: "Potable Water",
        emoji: "🚰",
    },
    {
        key: "wheelchair",
        name: "Wheelchair Symbol",
        emoji: "♿",
    },
    {
        key: "mens",
        name: "Men's Room",
        emoji: "🚹",
    },
    {
        key: "womens",
        name: "Women's Room",
        emoji: "🚺",
    },
    {
        key: "restroom",
        name: "Restroom",
        emoji: "🚻",
    },
    {
        key: "baby_symbol",
        name: "Baby Symbol",
        emoji: "🚼",
    },
    {
        key: "wc",
        name: "Water Closet",
        emoji: "🚾",
    },
    {
        key: "passport_control",
        name: "Passport Control",
        emoji: "🛂",
    },
    {
        key: "customs",
        name: "Customs",
        emoji: "🛃",
    },
    {
        key: "baggage_claim",
        name: "Baggage Claim",
        emoji: "🛄",
    },
    {
        key: "left_luggage",
        name: "Left Luggage",
        emoji: "🛅",
    },
    {
        key: "warning",
        name: "Warning",
        emoji: "⚠️",
    },
    {
        key: "children_crossing",
        name: "Children Crossing",
        emoji: "🚸",
    },
    {
        key: "no_entry",
        name: "No Entry",
        emoji: "⛔",
    },
    {
        key: "no_entry_sign",
        name: "Prohibited",
        emoji: "🚫",
    },
    {
        key: "no_bicycles",
        name: "No Bicycles",
        emoji: "🚳",
    },
    {
        key: "no_smoking",
        name: "No Smoking",
        emoji: "🚭",
    },
    {
        key: "do_not_litter",
        name: "No Littering",
        emoji: "🚯",
    },
    {
        key: "non-potable_water",
        name: "Non-Potable Water",
        emoji: "🚱",
    },
    {
        key: "no_pedestrians",
        name: "No Pedestrians",
        emoji: "🚷",
    },
    {
        key: "no_mobile_phones",
        name: "No Mobile Phones",
        emoji: "📵",
    },
    {
        key: "underage",
        name: "No One Under Eighteen",
        emoji: "🔞",
    },
    {
        key: "radioactive_sign",
        name: "Radioactive",
        emoji: "☢️",
    },
    {
        key: "biohazard_sign",
        name: "Biohazard",
        emoji: "☣️",
    },
    {
        key: "arrow_up",
        name: "Up Arrow",
        emoji: "⬆️",
    },
    {
        key: "arrow_upper_right",
        name: "Up-Right Arrow",
        emoji: "↗️",
    },
    {
        key: "arrow_right",
        name: "Right Arrow",
        emoji: "➡️",
    },
    {
        key: "arrow_lower_right",
        name: "South East Arrow",
        emoji: "↘️",
    },
    {
        key: "arrow_down",
        name: "Down Arrow",
        emoji: "⬇️",
    },
    {
        key: "arrow_lower_left",
        name: "Down-Left Arrow",
        emoji: "↙️",
    },
    {
        key: "arrow_left",
        name: "Left Arrow",
        emoji: "⬅️",
    },
    {
        key: "arrow_upper_left",
        name: "Up-Left Arrow",
        emoji: "↖️",
    },
    {
        key: "arrow_up_down",
        name: "Up Down Arrow",
        emoji: "↕️",
    },
    {
        key: "left_right_arrow",
        name: "Left Right Arrow",
        emoji: "↔️",
    },
    {
        key: "leftwards_arrow_with_hook",
        name: "Right Arrow Curving Left",
        emoji: "↩️",
    },
    {
        key: "arrow_right_hook",
        name: "Left Arrow Curving Right",
        emoji: "↪️",
    },
    {
        key: "arrow_heading_up",
        name: "Right Arrow Curving Up",
        emoji: "⤴️",
    },
    {
        key: "arrow_heading_down",
        name: "Right Arrow Curving Down",
        emoji: "⤵️",
    },
    {
        key: "arrows_clockwise",
        name: "Clockwise Vertical Arrows",
        emoji: "🔃",
    },
    {
        key: "arrows_counterclockwise",
        name: "Counterclockwise Arrows Button",
        emoji: "🔄",
    },
    {
        key: "back",
        name: "Back Arrow",
        emoji: "🔙",
    },
    {
        key: "end",
        name: "End Arrow",
        emoji: "🔚",
    },
    {
        key: "on",
        name: "On! Arrow",
        emoji: "🔛",
    },
    {
        key: "soon",
        name: "Soon Arrow",
        emoji: "🔜",
    },
    {
        key: "top",
        name: "Top Arrow",
        emoji: "🔝",
    },
    {
        key: "place_of_worship",
        name: "Place of Worship",
        emoji: "🛐",
    },
    {
        key: "atom_symbol",
        name: "Atom Symbol",
        emoji: "⚛️",
    },
    {
        key: "om_symbol",
        name: "Om",
        emoji: "🕉️",
    },
    {
        key: "star_of_david",
        name: "Star of David",
        emoji: "✡️",
    },
    {
        key: "wheel_of_dharma",
        name: "Wheel of Dharma",
        emoji: "☸️",
    },
    {
        key: "yin_yang",
        name: "Yin Yang",
        emoji: "☯️",
    },
    {
        key: "latin_cross",
        name: "Latin Cross",
        emoji: "✝️",
    },
    {
        key: "orthodox_cross",
        name: "Orthodox Cross",
        emoji: "☦️",
    },
    {
        key: "star_and_crescent",
        name: "Star and Crescent",
        emoji: "☪️",
    },
    {
        key: "peace_symbol",
        name: "Peace Symbol",
        emoji: "☮️",
    },
    {
        key: "menorah_with_nine_branches",
        name: "Menorah",
        emoji: "🕎",
    },
    {
        key: "six_pointed_star",
        name: "Dotted Six-Pointed Star",
        emoji: "🔯",
    },
    {
        key: "aries",
        name: "Aries",
        emoji: "♈",
    },
    {
        key: "taurus",
        name: "Taurus",
        emoji: "♉",
    },
    {
        key: "gemini",
        name: "Gemini",
        emoji: "♊",
    },
    {
        key: "cancer",
        name: "Cancer",
        emoji: "♋",
    },
    {
        key: "leo",
        name: "Leo",
        emoji: "♌",
    },
    {
        key: "virgo",
        name: "Virgo",
        emoji: "♍",
    },
    {
        key: "libra",
        name: "Libra",
        emoji: "♎",
    },
    {
        key: "scorpius",
        name: "Scorpio",
        emoji: "♏",
    },
    {
        key: "sagittarius",
        name: "Sagittarius",
        emoji: "♐",
    },
    {
        key: "capricorn",
        name: "Capricorn",
        emoji: "♑",
    },
    {
        key: "aquarius",
        name: "Aquarius",
        emoji: "♒",
    },
    {
        key: "pisces",
        name: "Pisces",
        emoji: "♓",
    },
    {
        key: "ophiuchus",
        name: "Ophiuchus",
        emoji: "⛎",
    },
    {
        key: "twisted_rightwards_arrows",
        name: "Shuffle Tracks Button",
        emoji: "🔀",
    },
    {
        key: "repeat",
        name: "Repeat Button",
        emoji: "🔁",
    },
    {
        key: "repeat_one",
        name: "Repeat Single Button",
        emoji: "🔂",
    },
    {
        key: "arrow_forward",
        name: "Play Button",
        emoji: "▶️",
    },
    {
        key: "fast_forward",
        name: "Fast-Forward Button",
        emoji: "⏩",
    },
    {
        key: "black_right_pointing_double_triangle_with_vertical_bar",
        name: "Next Track Button",
        emoji: "⏭️",
    },
    {
        key: "black_right_pointing_triangle_with_double_vertical_bar",
        name: "Play or Pause Button",
        emoji: "⏯️",
    },
    {
        key: "arrow_backward",
        name: "Reverse Button",
        emoji: "◀️",
    },
    {
        key: "rewind",
        name: "Fast Reverse Button",
        emoji: "⏪",
    },
    {
        key: "black_left_pointing_double_triangle_with_vertical_bar",
        name: "Last Track Button",
        emoji: "⏮️",
    },
    {
        key: "arrow_up_small",
        name: "Upwards Button",
        emoji: "🔼",
    },
    {
        key: "arrow_double_up",
        name: "Fast Up Button",
        emoji: "⏫",
    },
    {
        key: "arrow_down_small",
        name: "Downwards Button",
        emoji: "🔽",
    },
    {
        key: "arrow_double_down",
        name: "Fast Down Button",
        emoji: "⏬",
    },
    {
        key: "double_vertical_bar",
        name: "Pause Button",
        emoji: "⏸️",
    },
    {
        key: "black_square_for_stop",
        name: "Stop Button",
        emoji: "⏹️",
    },
    {
        key: "black_circle_for_record",
        name: "Record Button",
        emoji: "⏺️",
    },
    {
        key: "eject",
        name: "Eject Button",
        emoji: "⏏️",
    },
    {
        key: "cinema",
        name: "Cinema",
        emoji: "🎦",
    },
    {
        key: "low_brightness",
        name: "Dim Button",
        emoji: "🔅",
    },
    {
        key: "high_brightness",
        name: "Bright Button",
        emoji: "🔆",
    },
    {
        key: "signal_strength",
        name: "Antenna Bars",
        emoji: "📶",
    },
    {
        key: "vibration_mode",
        name: "Vibration Mode",
        emoji: "📳",
    },
    {
        key: "mobile_phone_off",
        name: "Mobile Phone off",
        emoji: "📴",
    },
    {
        key: "female_sign",
        name: "Female Sign",
        emoji: "♀️",
    },
    {
        key: "male_sign",
        name: "Male Sign",
        emoji: "♂️",
    },
    {
        key: "transgender_symbol",
        name: "Transgender Symbol",
        emoji: "⚧️",
    },
    {
        key: "heavy_multiplication_x",
        name: "Multiply",
        emoji: "✖️",
    },
    {
        key: "heavy_plus_sign",
        name: "Plus",
        emoji: "➕",
    },
    {
        key: "heavy_minus_sign",
        name: "Minus",
        emoji: "➖",
    },
    {
        key: "heavy_division_sign",
        name: "Divide",
        emoji: "➗",
    },
    {
        key: "heavy_equals_sign",
        name: "Heavy Equals Sign",
        emoji: "🟰",
    },
    {
        key: "infinity",
        name: "Infinity",
        emoji: "♾️",
    },
    {
        key: "bangbang",
        name: "Double Exclamation Mark",
        emoji: "‼️",
    },
    {
        key: "interrobang",
        name: "Exclamation Question Mark",
        emoji: "⁉️",
    },
    {
        key: "question",
        name: "Red Question Mark",
        emoji: "❓",
    },
    {
        key: "grey_question",
        name: "White Question Mark",
        emoji: "❔",
    },
    {
        key: "grey_exclamation",
        name: "White Exclamation Mark",
        emoji: "❕",
    },
    {
        key: "exclamation",
        name: "Red Exclamation Mark",
        emoji: "❗",
    },
    {
        key: "wavy_dash",
        name: "Wavy Dash",
        emoji: "〰️",
    },
    {
        key: "currency_exchange",
        name: "Currency Exchange",
        emoji: "💱",
    },
    {
        key: "heavy_dollar_sign",
        name: "Heavy Dollar Sign",
        emoji: "💲",
    },
    {
        key: "medical_symbol",
        name: "Medical Symbol",
        emoji: "⚕️",
    },
    {
        key: "recycle",
        name: "Recycling Symbol",
        emoji: "♻️",
    },
    {
        key: "fleur_de_lis",
        name: "Fleur-De-Lis",
        emoji: "⚜️",
    },
    {
        key: "trident",
        name: "Trident Emblem",
        emoji: "🔱",
    },
    {
        key: "name_badge",
        name: "Name Badge",
        emoji: "📛",
    },
    {
        key: "beginner",
        name: "Japanese Symbol for Beginner",
        emoji: "🔰",
    },
    {
        key: "o",
        name: "Hollow Red Circle",
        emoji: "⭕",
    },
    {
        key: "white_check_mark",
        name: "Check Mark Button",
        emoji: "✅",
    },
    {
        key: "ballot_box_with_check",
        name: "Check Box with Check",
        emoji: "☑️",
    },
    {
        key: "heavy_check_mark",
        name: "Check Mark",
        emoji: "✔️",
    },
    {
        key: "x",
        name: "Cross Mark",
        emoji: "❌",
    },
    {
        key: "negative_squared_cross_mark",
        name: "Cross Mark Button",
        emoji: "❎",
    },
    {
        key: "curly_loop",
        name: "Curly Loop",
        emoji: "➰",
    },
    {
        key: "loop",
        name: "Double Curly Loop",
        emoji: "➿",
    },
    {
        key: "part_alternation_mark",
        name: "Part Alternation Mark",
        emoji: "〽️",
    },
    {
        key: "eight_spoked_asterisk",
        name: "Eight Spoked Asterisk",
        emoji: "✳️",
    },
    {
        key: "eight_pointed_black_star",
        name: "Eight-Pointed Star",
        emoji: "✴️",
    },
    {
        key: "sparkle",
        name: "Sparkle",
        emoji: "❇️",
    },
    {
        key: "copyright",
        name: "Copyright",
        emoji: "©️",
    },
    {
        key: "registered",
        name: "Registered",
        emoji: "®️",
    },
    {
        key: "tm",
        name: "Trade Mark",
        emoji: "™️",
    },
    {
        key: "hash",
        name: "Hash Key",
        emoji: "#️⃣",
    },
    {
        key: "keycap_star",
        name: "Keycap: *",
        emoji: "*️⃣",
    },
    {
        key: "zero",
        name: "Keycap 0",
        emoji: "0️⃣",
    },
    {
        key: "one",
        name: "Keycap 1",
        emoji: "1️⃣",
    },
    {
        key: "two",
        name: "Keycap 2",
        emoji: "2️⃣",
    },
    {
        key: "three",
        name: "Keycap 3",
        emoji: "3️⃣",
    },
    {
        key: "four",
        name: "Keycap 4",
        emoji: "4️⃣",
    },
    {
        key: "five",
        name: "Keycap 5",
        emoji: "5️⃣",
    },
    {
        key: "six",
        name: "Keycap 6",
        emoji: "6️⃣",
    },
    {
        key: "seven",
        name: "Keycap 7",
        emoji: "7️⃣",
    },
    {
        key: "eight",
        name: "Keycap 8",
        emoji: "8️⃣",
    },
    {
        key: "nine",
        name: "Keycap 9",
        emoji: "9️⃣",
    },
    {
        key: "keycap_ten",
        name: "Keycap 10",
        emoji: "🔟",
    },
    {
        key: "capital_abcd",
        name: "Input Latin Uppercase",
        emoji: "🔠",
    },
    {
        key: "abcd",
        name: "Input Latin Lowercase",
        emoji: "🔡",
    },
    {
        key: "symbols",
        name: "Input Symbols",
        emoji: "🔣",
    },
    {
        key: "abc",
        name: "Input Latin Letters",
        emoji: "🔤",
    },
    {
        key: "a",
        name: "A Button (blood Type)",
        emoji: "🅰️",
    },
    {
        key: "ab",
        name: "Negative Squared Ab",
        emoji: "🆎",
    },
    {
        key: "b",
        name: "B Button (blood Type)",
        emoji: "🅱️",
    },
    {
        key: "cl",
        name: "Cl Button",
        emoji: "🆑",
    },
    {
        key: "cool",
        name: "Cool Button",
        emoji: "🆒",
    },
    {
        key: "free",
        name: "Free Button",
        emoji: "🆓",
    },
    {
        key: "information_source",
        name: "Information",
        emoji: "ℹ️",
    },
    {
        key: "id",
        name: "Id Button",
        emoji: "🆔",
    },
    {
        key: "m",
        name: "Circled M",
        emoji: "Ⓜ️",
    },
    {
        key: "new",
        name: "New Button",
        emoji: "🆕",
    },
    {
        key: "ng",
        name: "Ng Button",
        emoji: "🆖",
    },
    {
        key: "o2",
        name: "O Button (blood Type)",
        emoji: "🅾️",
    },
    {
        key: "ok",
        name: "Ok Button",
        emoji: "🆗",
    },
    {
        key: "parking",
        name: "P Button",
        emoji: "🅿️",
    },
    {
        key: "sos",
        name: "Sos Button",
        emoji: "🆘",
    },
    {
        key: "up",
        name: "Up! Button",
        emoji: "🆙",
    },
    {
        key: "vs",
        name: "Vs Button",
        emoji: "🆚",
    },
    {
        key: "koko",
        name: "Squared Katakana Koko",
        emoji: "🈁",
    },
    {
        key: "sa",
        name: "Squared Katakana Sa",
        emoji: "🈂️",
    },
    {
        key: "u6708",
        name: "Japanese 'monthly Amount' Button",
        emoji: "🈷️",
    },
    {
        key: "u6709",
        name: "Squared Cjk Unified Ideograph-6709",
        emoji: "🈶",
    },
    {
        key: "u6307",
        name: "Japanese 'reserved' Button",
        emoji: "🈯",
    },
    {
        key: "ideograph_advantage",
        name: "Japanese 'bargain' Button",
        emoji: "🉐",
    },
    {
        key: "u5272",
        name: "Japanese 'discount' Button",
        emoji: "🈹",
    },
    {
        key: "u7121",
        name: "Japanese 'free of Charge' Button",
        emoji: "🈚",
    },
    {
        key: "u7981",
        name: "Japanese 'prohibited' Button",
        emoji: "🈲",
    },
    {
        key: "accept",
        name: "Circled Ideograph Accept",
        emoji: "🉑",
    },
    {
        key: "u7533",
        name: "Japanese 'application' Button",
        emoji: "🈸",
    },
    {
        key: "u5408",
        name: "Japanese 'passing Grade' Button",
        emoji: "🈴",
    },
    {
        key: "u7a7a",
        name: "Japanese 'vacancy' Button",
        emoji: "🈳",
    },
    {
        key: "congratulations",
        name: "Circled Ideograph Congratulation",
        emoji: "㊗️",
    },
    {
        key: "secret",
        name: "Circled Ideograph Secret",
        emoji: "㊙️",
    },
    {
        key: "u55b6",
        name: "Squared Cjk Unified Ideograph-55b6",
        emoji: "🈺",
    },
    {
        key: "u6e80",
        name: "Japanese 'no Vacancy' Button",
        emoji: "🈵",
    },
    {
        key: "red_circle",
        name: "Red Circle",
        emoji: "🔴",
    },
    {
        key: "large_orange_circle",
        name: "Orange Circle",
        emoji: "🟠",
    },
    {
        key: "large_yellow_circle",
        name: "Yellow Circle",
        emoji: "🟡",
    },
    {
        key: "large_green_circle",
        name: "Green Circle",
        emoji: "🟢",
    },
    {
        key: "large_blue_circle",
        name: "Blue Circle",
        emoji: "🔵",
    },
    {
        key: "large_purple_circle",
        name: "Purple Circle",
        emoji: "🟣",
    },
    {
        key: "large_brown_circle",
        name: "Brown Circle",
        emoji: "🟤",
    },
    {
        key: "black_circle",
        name: "Black Circle",
        emoji: "⚫",
    },
    {
        key: "white_circle",
        name: "White Circle",
        emoji: "⚪",
    },
    {
        key: "large_red_square",
        name: "Red Square",
        emoji: "🟥",
    },
    {
        key: "large_orange_square",
        name: "Orange Square",
        emoji: "🟧",
    },
    {
        key: "large_yellow_square",
        name: "Yellow Square",
        emoji: "🟨",
    },
    {
        key: "large_green_square",
        name: "Green Square",
        emoji: "🟩",
    },
    {
        key: "large_blue_square",
        name: "Blue Square",
        emoji: "🟦",
    },
    {
        key: "large_purple_square",
        name: "Purple Square",
        emoji: "🟪",
    },
    {
        key: "large_brown_square",
        name: "Brown Square",
        emoji: "🟫",
    },
    {
        key: "black_large_square",
        name: "Black Large Square",
        emoji: "⬛",
    },
    {
        key: "white_large_square",
        name: "White Large Square",
        emoji: "⬜",
    },
    {
        key: "black_medium_square",
        name: "Black Medium Square",
        emoji: "◼️",
    },
    {
        key: "white_medium_square",
        name: "White Medium Square",
        emoji: "◻️",
    },
    {
        key: "black_medium_small_square",
        name: "Black Medium Small Square",
        emoji: "◾",
    },
    {
        key: "white_medium_small_square",
        name: "White Medium Small Square",
        emoji: "◽",
    },
    {
        key: "black_small_square",
        name: "Black Small Square",
        emoji: "▪️",
    },
    {
        key: "white_small_square",
        name: "White Small Square",
        emoji: "▫️",
    },
    {
        key: "large_orange_diamond",
        name: "Large Orange Diamond",
        emoji: "🔶",
    },
    {
        key: "large_blue_diamond",
        name: "Large Blue Diamond",
        emoji: "🔷",
    },
    {
        key: "small_orange_diamond",
        name: "Small Orange Diamond",
        emoji: "🔸",
    },
    {
        key: "small_blue_diamond",
        name: "Small Blue Diamond",
        emoji: "🔹",
    },
    {
        key: "small_red_triangle",
        name: "Red Triangle Pointed Up",
        emoji: "🔺",
    },
    {
        key: "small_red_triangle_down",
        name: "Red Triangle Pointed Down",
        emoji: "🔻",
    },
    {
        key: "diamond_shape_with_a_dot_inside",
        name: "Diamond with a Dot",
        emoji: "💠",
    },
    {
        key: "radio_button",
        name: "Radio Button",
        emoji: "🔘",
    },
    {
        key: "white_square_button",
        name: "White Square Button",
        emoji: "🔳",
    },
    {
        key: "black_square_button",
        name: "Black Square Button",
        emoji: "🔲",
    },
    {
        key: "checkered_flag",
        name: "Chequered Flag",
        emoji: "🏁",
    },
    {
        key: "triangular_flag_on_post",
        name: "Triangular Flag",
        emoji: "🚩",
    },
    {
        key: "crossed_flags",
        name: "Crossed Flags",
        emoji: "🎌",
    },
    {
        key: "waving_black_flag",
        name: "Black Flag",
        emoji: "🏴",
    },
    {
        key: "waving_white_flag",
        name: "White Flag",
        emoji: "🏳️",
    },
    {
        key: "rainbow-flag",
        name: "Rainbow Flag",
        emoji: "🏳️‍🌈",
    },
    {
        key: "transgender_flag",
        name: "Transgender Flag",
        emoji: "🏳️‍⚧️",
    },
    {
        key: "pirate_flag",
        name: "Pirate Flag",
        emoji: "🏴‍☠️",
    },
    {
        key: "flag-ac",
        name: "Ascension Island Flag",
        emoji: "🇦🇨",
    },
    {
        key: "flag-ad",
        name: "Andorra Flag",
        emoji: "🇦🇩",
    },
    {
        key: "flag-ae",
        name: "United Arab Emirates Flag",
        emoji: "🇦🇪",
    },
    {
        key: "flag-af",
        name: "Afghanistan Flag",
        emoji: "🇦🇫",
    },
    {
        key: "flag-ag",
        name: "Antigua & Barbuda Flag",
        emoji: "🇦🇬",
    },
    {
        key: "flag-ai",
        name: "Anguilla Flag",
        emoji: "🇦🇮",
    },
    {
        key: "flag-al",
        name: "Albania Flag",
        emoji: "🇦🇱",
    },
    {
        key: "flag-am",
        name: "Armenia Flag",
        emoji: "🇦🇲",
    },
    {
        key: "flag-ao",
        name: "Angola Flag",
        emoji: "🇦🇴",
    },
    {
        key: "flag-aq",
        name: "Antarctica Flag",
        emoji: "🇦🇶",
    },
    {
        key: "flag-ar",
        name: "Argentina Flag",
        emoji: "🇦🇷",
    },
    {
        key: "flag-as",
        name: "American Samoa Flag",
        emoji: "🇦🇸",
    },
    {
        key: "flag-at",
        name: "Austria Flag",
        emoji: "🇦🇹",
    },
    {
        key: "flag-au",
        name: "Australia Flag",
        emoji: "🇦🇺",
    },
    {
        key: "flag-aw",
        name: "Aruba Flag",
        emoji: "🇦🇼",
    },
    {
        key: "flag-ax",
        name: "Åland Islands Flag",
        emoji: "🇦🇽",
    },
    {
        key: "flag-az",
        name: "Azerbaijan Flag",
        emoji: "🇦🇿",
    },
    {
        key: "flag-ba",
        name: "Bosnia & Herzegovina Flag",
        emoji: "🇧🇦",
    },
    {
        key: "flag-bb",
        name: "Barbados Flag",
        emoji: "🇧🇧",
    },
    {
        key: "flag-bd",
        name: "Bangladesh Flag",
        emoji: "🇧🇩",
    },
    {
        key: "flag-be",
        name: "Belgium Flag",
        emoji: "🇧🇪",
    },
    {
        key: "flag-bf",
        name: "Burkina Faso Flag",
        emoji: "🇧🇫",
    },
    {
        key: "flag-bg",
        name: "Bulgaria Flag",
        emoji: "🇧🇬",
    },
    {
        key: "flag-bh",
        name: "Bahrain Flag",
        emoji: "🇧🇭",
    },
    {
        key: "flag-bi",
        name: "Burundi Flag",
        emoji: "🇧🇮",
    },
    {
        key: "flag-bj",
        name: "Benin Flag",
        emoji: "🇧🇯",
    },
    {
        key: "flag-bl",
        name: "St. Barthélemy Flag",
        emoji: "🇧🇱",
    },
    {
        key: "flag-bm",
        name: "Bermuda Flag",
        emoji: "🇧🇲",
    },
    {
        key: "flag-bn",
        name: "Brunei Flag",
        emoji: "🇧🇳",
    },
    {
        key: "flag-bo",
        name: "Bolivia Flag",
        emoji: "🇧🇴",
    },
    {
        key: "flag-bq",
        name: "Caribbean Netherlands Flag",
        emoji: "🇧🇶",
    },
    {
        key: "flag-br",
        name: "Brazil Flag",
        emoji: "🇧🇷",
    },
    {
        key: "flag-bs",
        name: "Bahamas Flag",
        emoji: "🇧🇸",
    },
    {
        key: "flag-bt",
        name: "Bhutan Flag",
        emoji: "🇧🇹",
    },
    {
        key: "flag-bv",
        name: "Bouvet Island Flag",
        emoji: "🇧🇻",
    },
    {
        key: "flag-bw",
        name: "Botswana Flag",
        emoji: "🇧🇼",
    },
    {
        key: "flag-by",
        name: "Belarus Flag",
        emoji: "🇧🇾",
    },
    {
        key: "flag-bz",
        name: "Belize Flag",
        emoji: "🇧🇿",
    },
    {
        key: "flag-ca",
        name: "Canada Flag",
        emoji: "🇨🇦",
    },
    {
        key: "flag-cc",
        name: "Cocos (keeling) Islands Flag",
        emoji: "🇨🇨",
    },
    {
        key: "flag-cd",
        name: "Congo - Kinshasa Flag",
        emoji: "🇨🇩",
    },
    {
        key: "flag-cf",
        name: "Central African Republic Flag",
        emoji: "🇨🇫",
    },
    {
        key: "flag-cg",
        name: "Congo - Brazzaville Flag",
        emoji: "🇨🇬",
    },
    {
        key: "flag-ch",
        name: "Switzerland Flag",
        emoji: "🇨🇭",
    },
    {
        key: "flag-ci",
        name: "Côte D'ivoire Flag",
        emoji: "🇨🇮",
    },
    {
        key: "flag-ck",
        name: "Cook Islands Flag",
        emoji: "🇨🇰",
    },
    {
        key: "flag-cl",
        name: "Chile Flag",
        emoji: "🇨🇱",
    },
    {
        key: "flag-cm",
        name: "Cameroon Flag",
        emoji: "🇨🇲",
    },
    {
        key: "cn",
        name: "China Flag",
        emoji: "🇨🇳",
    },
    {
        key: "flag-co",
        name: "Colombia Flag",
        emoji: "🇨🇴",
    },
    {
        key: "flag-cp",
        name: "Clipperton Island Flag",
        emoji: "🇨🇵",
    },
    {
        key: "flag-cr",
        name: "Costa Rica Flag",
        emoji: "🇨🇷",
    },
    {
        key: "flag-cu",
        name: "Cuba Flag",
        emoji: "🇨🇺",
    },
    {
        key: "flag-cv",
        name: "Cape Verde Flag",
        emoji: "🇨🇻",
    },
    {
        key: "flag-cw",
        name: "Curaçao Flag",
        emoji: "🇨🇼",
    },
    {
        key: "flag-cx",
        name: "Christmas Island Flag",
        emoji: "🇨🇽",
    },
    {
        key: "flag-cy",
        name: "Cyprus Flag",
        emoji: "🇨🇾",
    },
    {
        key: "flag-cz",
        name: "Czechia Flag",
        emoji: "🇨🇿",
    },
    {
        key: "de",
        name: "Germany Flag",
        emoji: "🇩🇪",
    },
    {
        key: "flag-dg",
        name: "Diego Garcia Flag",
        emoji: "🇩🇬",
    },
    {
        key: "flag-dj",
        name: "Djibouti Flag",
        emoji: "🇩🇯",
    },
    {
        key: "flag-dk",
        name: "Denmark Flag",
        emoji: "🇩🇰",
    },
    {
        key: "flag-dm",
        name: "Dominica Flag",
        emoji: "🇩🇲",
    },
    {
        key: "flag-do",
        name: "Dominican Republic Flag",
        emoji: "🇩🇴",
    },
    {
        key: "flag-dz",
        name: "Algeria Flag",
        emoji: "🇩🇿",
    },
    {
        key: "flag-ea",
        name: "Ceuta & Melilla Flag",
        emoji: "🇪🇦",
    },
    {
        key: "flag-ec",
        name: "Ecuador Flag",
        emoji: "🇪🇨",
    },
    {
        key: "flag-ee",
        name: "Estonia Flag",
        emoji: "🇪🇪",
    },
    {
        key: "flag-eg",
        name: "Egypt Flag",
        emoji: "🇪🇬",
    },
    {
        key: "flag-eh",
        name: "Western Sahara Flag",
        emoji: "🇪🇭",
    },
    {
        key: "flag-er",
        name: "Eritrea Flag",
        emoji: "🇪🇷",
    },
    {
        key: "es",
        name: "Spain Flag",
        emoji: "🇪🇸",
    },
    {
        key: "flag-et",
        name: "Ethiopia Flag",
        emoji: "🇪🇹",
    },
    {
        key: "flag-eu",
        name: "European Union Flag",
        emoji: "🇪🇺",
    },
    {
        key: "flag-fi",
        name: "Finland Flag",
        emoji: "🇫🇮",
    },
    {
        key: "flag-fj",
        name: "Fiji Flag",
        emoji: "🇫🇯",
    },
    {
        key: "flag-fk",
        name: "Falkland Islands Flag",
        emoji: "🇫🇰",
    },
    {
        key: "flag-fm",
        name: "Micronesia Flag",
        emoji: "🇫🇲",
    },
    {
        key: "flag-fo",
        name: "Faroe Islands Flag",
        emoji: "🇫🇴",
    },
    {
        key: "fr",
        name: "France Flag",
        emoji: "🇫🇷",
    },
    {
        key: "flag-ga",
        name: "Gabon Flag",
        emoji: "🇬🇦",
    },
    {
        key: "gb",
        name: "United Kingdom Flag",
        emoji: "🇬🇧",
    },
    {
        key: "flag-gd",
        name: "Grenada Flag",
        emoji: "🇬🇩",
    },
    {
        key: "flag-ge",
        name: "Georgia Flag",
        emoji: "🇬🇪",
    },
    {
        key: "flag-gf",
        name: "French Guiana Flag",
        emoji: "🇬🇫",
    },
    {
        key: "flag-gg",
        name: "Guernsey Flag",
        emoji: "🇬🇬",
    },
    {
        key: "flag-gh",
        name: "Ghana Flag",
        emoji: "🇬🇭",
    },
    {
        key: "flag-gi",
        name: "Gibraltar Flag",
        emoji: "🇬🇮",
    },
    {
        key: "flag-gl",
        name: "Greenland Flag",
        emoji: "🇬🇱",
    },
    {
        key: "flag-gm",
        name: "Gambia Flag",
        emoji: "🇬🇲",
    },
    {
        key: "flag-gn",
        name: "Guinea Flag",
        emoji: "🇬🇳",
    },
    {
        key: "flag-gp",
        name: "Guadeloupe Flag",
        emoji: "🇬🇵",
    },
    {
        key: "flag-gq",
        name: "Equatorial Guinea Flag",
        emoji: "🇬🇶",
    },
    {
        key: "flag-gr",
        name: "Greece Flag",
        emoji: "🇬🇷",
    },
    {
        key: "flag-gs",
        name: "South Georgia & South Sandwich Islands Flag",
        emoji: "🇬🇸",
    },
    {
        key: "flag-gt",
        name: "Guatemala Flag",
        emoji: "🇬🇹",
    },
    {
        key: "flag-gu",
        name: "Guam Flag",
        emoji: "🇬🇺",
    },
    {
        key: "flag-gw",
        name: "Guinea-Bissau Flag",
        emoji: "🇬🇼",
    },
    {
        key: "flag-gy",
        name: "Guyana Flag",
        emoji: "🇬🇾",
    },
    {
        key: "flag-hk",
        name: "Hong Kong Sar China Flag",
        emoji: "🇭🇰",
    },
    {
        key: "flag-hm",
        name: "Heard & Mcdonald Islands Flag",
        emoji: "🇭🇲",
    },
    {
        key: "flag-hn",
        name: "Honduras Flag",
        emoji: "🇭🇳",
    },
    {
        key: "flag-hr",
        name: "Croatia Flag",
        emoji: "🇭🇷",
    },
    {
        key: "flag-ht",
        name: "Haiti Flag",
        emoji: "🇭🇹",
    },
    {
        key: "flag-hu",
        name: "Hungary Flag",
        emoji: "🇭🇺",
    },
    {
        key: "flag-ic",
        name: "Canary Islands Flag",
        emoji: "🇮🇨",
    },
    {
        key: "flag-id",
        name: "Indonesia Flag",
        emoji: "🇮🇩",
    },
    {
        key: "flag-ie",
        name: "Ireland Flag",
        emoji: "🇮🇪",
    },
    {
        key: "flag-il",
        name: "Israel Flag",
        emoji: "🇮🇱",
    },
    {
        key: "flag-im",
        name: "Isle of Man Flag",
        emoji: "🇮🇲",
    },
    {
        key: "flag-in",
        name: "India Flag",
        emoji: "🇮🇳",
    },
    {
        key: "flag-io",
        name: "British Indian Ocean Territory Flag",
        emoji: "🇮🇴",
    },
    {
        key: "flag-iq",
        name: "Iraq Flag",
        emoji: "🇮🇶",
    },
    {
        key: "flag-ir",
        name: "Iran Flag",
        emoji: "🇮🇷",
    },
    {
        key: "flag-is",
        name: "Iceland Flag",
        emoji: "🇮🇸",
    },
    {
        key: "it",
        name: "Italy Flag",
        emoji: "🇮🇹",
    },
    {
        key: "flag-je",
        name: "Jersey Flag",
        emoji: "🇯🇪",
    },
    {
        key: "flag-jm",
        name: "Jamaica Flag",
        emoji: "🇯🇲",
    },
    {
        key: "flag-jo",
        name: "Jordan Flag",
        emoji: "🇯🇴",
    },
    {
        key: "jp",
        name: "Japan Flag",
        emoji: "🇯🇵",
    },
    {
        key: "flag-ke",
        name: "Kenya Flag",
        emoji: "🇰🇪",
    },
    {
        key: "flag-kg",
        name: "Kyrgyzstan Flag",
        emoji: "🇰🇬",
    },
    {
        key: "flag-kh",
        name: "Cambodia Flag",
        emoji: "🇰🇭",
    },
    {
        key: "flag-ki",
        name: "Kiribati Flag",
        emoji: "🇰🇮",
    },
    {
        key: "flag-km",
        name: "Comoros Flag",
        emoji: "🇰🇲",
    },
    {
        key: "flag-kn",
        name: "St. Kitts & Nevis Flag",
        emoji: "🇰🇳",
    },
    {
        key: "flag-kp",
        name: "North Korea Flag",
        emoji: "🇰🇵",
    },
    {
        key: "kr",
        name: "South Korea Flag",
        emoji: "🇰🇷",
    },
    {
        key: "flag-kw",
        name: "Kuwait Flag",
        emoji: "🇰🇼",
    },
    {
        key: "flag-ky",
        name: "Cayman Islands Flag",
        emoji: "🇰🇾",
    },
    {
        key: "flag-kz",
        name: "Kazakhstan Flag",
        emoji: "🇰🇿",
    },
    {
        key: "flag-la",
        name: "Laos Flag",
        emoji: "🇱🇦",
    },
    {
        key: "flag-lb",
        name: "Lebanon Flag",
        emoji: "🇱🇧",
    },
    {
        key: "flag-lc",
        name: "St. Lucia Flag",
        emoji: "🇱🇨",
    },
    {
        key: "flag-li",
        name: "Liechtenstein Flag",
        emoji: "🇱🇮",
    },
    {
        key: "flag-lk",
        name: "Sri Lanka Flag",
        emoji: "🇱🇰",
    },
    {
        key: "flag-lr",
        name: "Liberia Flag",
        emoji: "🇱🇷",
    },
    {
        key: "flag-ls",
        name: "Lesotho Flag",
        emoji: "🇱🇸",
    },
    {
        key: "flag-lt",
        name: "Lithuania Flag",
        emoji: "🇱🇹",
    },
    {
        key: "flag-lu",
        name: "Luxembourg Flag",
        emoji: "🇱🇺",
    },
    {
        key: "flag-lv",
        name: "Latvia Flag",
        emoji: "🇱🇻",
    },
    {
        key: "flag-ly",
        name: "Libya Flag",
        emoji: "🇱🇾",
    },
    {
        key: "flag-ma",
        name: "Morocco Flag",
        emoji: "🇲🇦",
    },
    {
        key: "flag-mc",
        name: "Monaco Flag",
        emoji: "🇲🇨",
    },
    {
        key: "flag-md",
        name: "Moldova Flag",
        emoji: "🇲🇩",
    },
    {
        key: "flag-me",
        name: "Montenegro Flag",
        emoji: "🇲🇪",
    },
    {
        key: "flag-mf",
        name: "St. Martin Flag",
        emoji: "🇲🇫",
    },
    {
        key: "flag-mg",
        name: "Madagascar Flag",
        emoji: "🇲🇬",
    },
    {
        key: "flag-mh",
        name: "Marshall Islands Flag",
        emoji: "🇲🇭",
    },
    {
        key: "flag-mk",
        name: "North Macedonia Flag",
        emoji: "🇲🇰",
    },
    {
        key: "flag-ml",
        name: "Mali Flag",
        emoji: "🇲🇱",
    },
    {
        key: "flag-mm",
        name: "Myanmar (burma) Flag",
        emoji: "🇲🇲",
    },
    {
        key: "flag-mn",
        name: "Mongolia Flag",
        emoji: "🇲🇳",
    },
    {
        key: "flag-mo",
        name: "Macao Sar China Flag",
        emoji: "🇲🇴",
    },
    {
        key: "flag-mp",
        name: "Northern Mariana Islands Flag",
        emoji: "🇲🇵",
    },
    {
        key: "flag-mq",
        name: "Martinique Flag",
        emoji: "🇲🇶",
    },
    {
        key: "flag-mr",
        name: "Mauritania Flag",
        emoji: "🇲🇷",
    },
    {
        key: "flag-ms",
        name: "Montserrat Flag",
        emoji: "🇲🇸",
    },
    {
        key: "flag-mt",
        name: "Malta Flag",
        emoji: "🇲🇹",
    },
    {
        key: "flag-mu",
        name: "Mauritius Flag",
        emoji: "🇲🇺",
    },
    {
        key: "flag-mv",
        name: "Maldives Flag",
        emoji: "🇲🇻",
    },
    {
        key: "flag-mw",
        name: "Malawi Flag",
        emoji: "🇲🇼",
    },
    {
        key: "flag-mx",
        name: "Mexico Flag",
        emoji: "🇲🇽",
    },
    {
        key: "flag-my",
        name: "Malaysia Flag",
        emoji: "🇲🇾",
    },
    {
        key: "flag-mz",
        name: "Mozambique Flag",
        emoji: "🇲🇿",
    },
    {
        key: "flag-na",
        name: "Namibia Flag",
        emoji: "🇳🇦",
    },
    {
        key: "flag-nc",
        name: "New Caledonia Flag",
        emoji: "🇳🇨",
    },
    {
        key: "flag-ne",
        name: "Niger Flag",
        emoji: "🇳🇪",
    },
    {
        key: "flag-nf",
        name: "Norfolk Island Flag",
        emoji: "🇳🇫",
    },
    {
        key: "flag-ng",
        name: "Nigeria Flag",
        emoji: "🇳🇬",
    },
    {
        key: "flag-ni",
        name: "Nicaragua Flag",
        emoji: "🇳🇮",
    },
    {
        key: "flag-nl",
        name: "Netherlands Flag",
        emoji: "🇳🇱",
    },
    {
        key: "flag-no",
        name: "Norway Flag",
        emoji: "🇳🇴",
    },
    {
        key: "flag-np",
        name: "Nepal Flag",
        emoji: "🇳🇵",
    },
    {
        key: "flag-nr",
        name: "Nauru Flag",
        emoji: "🇳🇷",
    },
    {
        key: "flag-nu",
        name: "Niue Flag",
        emoji: "🇳🇺",
    },
    {
        key: "flag-nz",
        name: "New Zealand Flag",
        emoji: "🇳🇿",
    },
    {
        key: "flag-om",
        name: "Oman Flag",
        emoji: "🇴🇲",
    },
    {
        key: "flag-pa",
        name: "Panama Flag",
        emoji: "🇵🇦",
    },
    {
        key: "flag-pe",
        name: "Peru Flag",
        emoji: "🇵🇪",
    },
    {
        key: "flag-pf",
        name: "French Polynesia Flag",
        emoji: "🇵🇫",
    },
    {
        key: "flag-pg",
        name: "Papua New Guinea Flag",
        emoji: "🇵🇬",
    },
    {
        key: "flag-ph",
        name: "Philippines Flag",
        emoji: "🇵🇭",
    },
    {
        key: "flag-pk",
        name: "Pakistan Flag",
        emoji: "🇵🇰",
    },
    {
        key: "flag-pl",
        name: "Poland Flag",
        emoji: "🇵🇱",
    },
    {
        key: "flag-pm",
        name: "St. Pierre & Miquelon Flag",
        emoji: "🇵🇲",
    },
    {
        key: "flag-pn",
        name: "Pitcairn Islands Flag",
        emoji: "🇵🇳",
    },
    {
        key: "flag-pr",
        name: "Puerto Rico Flag",
        emoji: "🇵🇷",
    },
    {
        key: "flag-ps",
        name: "Palestinian Territories Flag",
        emoji: "🇵🇸",
    },
    {
        key: "flag-pt",
        name: "Portugal Flag",
        emoji: "🇵🇹",
    },
    {
        key: "flag-pw",
        name: "Palau Flag",
        emoji: "🇵🇼",
    },
    {
        key: "flag-py",
        name: "Paraguay Flag",
        emoji: "🇵🇾",
    },
    {
        key: "flag-qa",
        name: "Qatar Flag",
        emoji: "🇶🇦",
    },
    {
        key: "flag-re",
        name: "Réunion Flag",
        emoji: "🇷🇪",
    },
    {
        key: "flag-ro",
        name: "Romania Flag",
        emoji: "🇷🇴",
    },
    {
        key: "flag-rs",
        name: "Serbia Flag",
        emoji: "🇷🇸",
    },
    {
        key: "ru",
        name: "Russia Flag",
        emoji: "🇷🇺",
    },
    {
        key: "flag-rw",
        name: "Rwanda Flag",
        emoji: "🇷🇼",
    },
    {
        key: "flag-sa",
        name: "Saudi Arabia Flag",
        emoji: "🇸🇦",
    },
    {
        key: "flag-sb",
        name: "Solomon Islands Flag",
        emoji: "🇸🇧",
    },
    {
        key: "flag-sc",
        name: "Seychelles Flag",
        emoji: "🇸🇨",
    },
    {
        key: "flag-sd",
        name: "Sudan Flag",
        emoji: "🇸🇩",
    },
    {
        key: "flag-se",
        name: "Sweden Flag",
        emoji: "🇸🇪",
    },
    {
        key: "flag-sg",
        name: "Singapore Flag",
        emoji: "🇸🇬",
    },
    {
        key: "flag-sh",
        name: "St. Helena Flag",
        emoji: "🇸🇭",
    },
    {
        key: "flag-si",
        name: "Slovenia Flag",
        emoji: "🇸🇮",
    },
    {
        key: "flag-sj",
        name: "Svalbard & Jan Mayen Flag",
        emoji: "🇸🇯",
    },
    {
        key: "flag-sk",
        name: "Slovakia Flag",
        emoji: "🇸🇰",
    },
    {
        key: "flag-sl",
        name: "Sierra Leone Flag",
        emoji: "🇸🇱",
    },
    {
        key: "flag-sm",
        name: "San Marino Flag",
        emoji: "🇸🇲",
    },
    {
        key: "flag-sn",
        name: "Senegal Flag",
        emoji: "🇸🇳",
    },
    {
        key: "flag-so",
        name: "Somalia Flag",
        emoji: "🇸🇴",
    },
    {
        key: "flag-sr",
        name: "Suriname Flag",
        emoji: "🇸🇷",
    },
    {
        key: "flag-ss",
        name: "South Sudan Flag",
        emoji: "🇸🇸",
    },
    {
        key: "flag-st",
        name: "São Tomé & Príncipe Flag",
        emoji: "🇸🇹",
    },
    {
        key: "flag-sv",
        name: "El Salvador Flag",
        emoji: "🇸🇻",
    },
    {
        key: "flag-sx",
        name: "Sint Maarten Flag",
        emoji: "🇸🇽",
    },
    {
        key: "flag-sy",
        name: "Syria Flag",
        emoji: "🇸🇾",
    },
    {
        key: "flag-sz",
        name: "Eswatini Flag",
        emoji: "🇸🇿",
    },
    {
        key: "flag-ta",
        name: "Tristan Da Cunha Flag",
        emoji: "🇹🇦",
    },
    {
        key: "flag-tc",
        name: "Turks & Caicos Islands Flag",
        emoji: "🇹🇨",
    },
    {
        key: "flag-td",
        name: "Chad Flag",
        emoji: "🇹🇩",
    },
    {
        key: "flag-tf",
        name: "French Southern Territories Flag",
        emoji: "🇹🇫",
    },
    {
        key: "flag-tg",
        name: "Togo Flag",
        emoji: "🇹🇬",
    },
    {
        key: "flag-th",
        name: "Thailand Flag",
        emoji: "🇹🇭",
    },
    {
        key: "flag-tj",
        name: "Tajikistan Flag",
        emoji: "🇹🇯",
    },
    {
        key: "flag-tk",
        name: "Tokelau Flag",
        emoji: "🇹🇰",
    },
    {
        key: "flag-tl",
        name: "Timor-Leste Flag",
        emoji: "🇹🇱",
    },
    {
        key: "flag-tm",
        name: "Turkmenistan Flag",
        emoji: "🇹🇲",
    },
    {
        key: "flag-tn",
        name: "Tunisia Flag",
        emoji: "🇹🇳",
    },
    {
        key: "flag-to",
        name: "Tonga Flag",
        emoji: "🇹🇴",
    },
    {
        key: "flag-tr",
        name: "Turkey Flag",
        emoji: "🇹🇷",
    },
    {
        key: "flag-tt",
        name: "Trinidad & Tobago Flag",
        emoji: "🇹🇹",
    },
    {
        key: "flag-tv",
        name: "Tuvalu Flag",
        emoji: "🇹🇻",
    },
    {
        key: "flag-tw",
        name: "Taiwan Flag",
        emoji: "🇹🇼",
    },
    {
        key: "flag-tz",
        name: "Tanzania Flag",
        emoji: "🇹🇿",
    },
    {
        key: "flag-ua",
        name: "Ukraine Flag",
        emoji: "🇺🇦",
    },
    {
        key: "flag-ug",
        name: "Uganda Flag",
        emoji: "🇺🇬",
    },
    {
        key: "flag-um",
        name: "U.s. Outlying Islands Flag",
        emoji: "🇺🇲",
    },
    {
        key: "flag-un",
        name: "United Nations Flag",
        emoji: "🇺🇳",
    },
    {
        key: "us",
        name: "United States Flag",
        emoji: "🇺🇸",
    },
    {
        key: "flag-uy",
        name: "Uruguay Flag",
        emoji: "🇺🇾",
    },
    {
        key: "flag-uz",
        name: "Uzbekistan Flag",
        emoji: "🇺🇿",
    },
    {
        key: "flag-va",
        name: "Vatican City Flag",
        emoji: "🇻🇦",
    },
    {
        key: "flag-vc",
        name: "St. Vincent & Grenadines Flag",
        emoji: "🇻🇨",
    },
    {
        key: "flag-ve",
        name: "Venezuela Flag",
        emoji: "🇻🇪",
    },
    {
        key: "flag-vg",
        name: "British Virgin Islands Flag",
        emoji: "🇻🇬",
    },
    {
        key: "flag-vi",
        name: "U.s. Virgin Islands Flag",
        emoji: "🇻🇮",
    },
    {
        key: "flag-vn",
        name: "Vietnam Flag",
        emoji: "🇻🇳",
    },
    {
        key: "flag-vu",
        name: "Vanuatu Flag",
        emoji: "🇻🇺",
    },
    {
        key: "flag-wf",
        name: "Wallis & Futuna Flag",
        emoji: "🇼🇫",
    },
    {
        key: "flag-ws",
        name: "Samoa Flag",
        emoji: "🇼🇸",
    },
    {
        key: "flag-xk",
        name: "Kosovo Flag",
        emoji: "🇽🇰",
    },
    {
        key: "flag-ye",
        name: "Yemen Flag",
        emoji: "🇾🇪",
    },
    {
        key: "flag-yt",
        name: "Mayotte Flag",
        emoji: "🇾🇹",
    },
    {
        key: "flag-za",
        name: "South Africa Flag",
        emoji: "🇿🇦",
    },
    {
        key: "flag-zm",
        name: "Zambia Flag",
        emoji: "🇿🇲",
    },
    {
        key: "flag-zw",
        name: "Zimbabwe Flag",
        emoji: "🇿🇼",
    },
    {
        key: "flag-england",
        name: "England Flag",
        emoji: "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
    },
    {
        key: "flag-scotland",
        name: "Scotland Flag",
        emoji: "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
    },
    {
        key: "flag-wales",
        name: "Wales Flag",
        emoji: "🏴󠁧󠁢󠁷󠁬󠁳󠁿",
    },
];

export const EMOJIS_MAP: { [key: string]: string } = {
    "100": "💯",
    "1234": "🔢",
    grinning: "😀",
    smiley: "😃",
    smile: "😄",
    grin: "😁",
    laughing: "😆",
    sweat_smile: "😅",
    rolling_on_the_floor_laughing: "🤣",
    joy: "😂",
    slightly_smiling_face: "🙂",
    upside_down_face: "🙃",
    melting_face: "🫠",
    wink: "😉",
    blush: "😊",
    innocent: "😇",
    smiling_face_with_3_hearts: "🥰",
    heart_eyes: "😍",
    "star-struck": "🤩",
    kissing_heart: "😘",
    kissing: "😗",
    relaxed: "☺️",
    kissing_closed_eyes: "😚",
    kissing_smiling_eyes: "😙",
    smiling_face_with_tear: "🥲",
    yum: "😋",
    stuck_out_tongue: "😛",
    stuck_out_tongue_winking_eye: "😜",
    zany_face: "🤪",
    stuck_out_tongue_closed_eyes: "😝",
    money_mouth_face: "🤑",
    hugging_face: "🤗",
    face_with_hand_over_mouth: "🤭",
    face_with_open_eyes_and_hand_over_mouth: "🫢",
    face_with_peeking_eye: "🫣",
    shushing_face: "🤫",
    thinking_face: "🤔",
    saluting_face: "🫡",
    zipper_mouth_face: "🤐",
    face_with_raised_eyebrow: "🤨",
    neutral_face: "😐",
    expressionless: "😑",
    no_mouth: "😶",
    dotted_line_face: "🫥",
    face_in_clouds: "😶‍🌫️",
    smirk: "😏",
    unamused: "😒",
    face_with_rolling_eyes: "🙄",
    grimacing: "😬",
    face_exhaling: "😮‍💨",
    lying_face: "🤥",
    relieved: "😌",
    pensive: "😔",
    sleepy: "😪",
    drooling_face: "🤤",
    sleeping: "😴",
    mask: "😷",
    face_with_thermometer: "🤒",
    face_with_head_bandage: "🤕",
    nauseated_face: "🤢",
    face_vomiting: "🤮",
    sneezing_face: "🤧",
    hot_face: "🥵",
    cold_face: "🥶",
    woozy_face: "🥴",
    dizzy_face: "😵",
    face_with_spiral_eyes: "😵‍💫",
    exploding_head: "🤯",
    face_with_cowboy_hat: "🤠",
    partying_face: "🥳",
    disguised_face: "🥸",
    sunglasses: "😎",
    nerd_face: "🤓",
    face_with_monocle: "🧐",
    confused: "😕",
    face_with_diagonal_mouth: "🫤",
    worried: "😟",
    slightly_frowning_face: "🙁",
    white_frowning_face: "☹️",
    open_mouth: "😮",
    hushed: "😯",
    astonished: "😲",
    flushed: "😳",
    pleading_face: "🥺",
    face_holding_back_tears: "🥹",
    frowning: "😦",
    anguished: "😧",
    fearful: "😨",
    cold_sweat: "😰",
    disappointed_relieved: "😥",
    cry: "😢",
    sob: "😭",
    scream: "😱",
    confounded: "😖",
    persevere: "😣",
    disappointed: "😞",
    sweat: "😓",
    weary: "😩",
    tired_face: "😫",
    yawning_face: "🥱",
    triumph: "😤",
    rage: "😡",
    angry: "😠",
    face_with_symbols_on_mouth: "🤬",
    smiling_imp: "😈",
    imp: "👿",
    skull: "💀",
    skull_and_crossbones: "☠️",
    hankey: "💩",
    clown_face: "🤡",
    japanese_ogre: "👹",
    japanese_goblin: "👺",
    ghost: "👻",
    alien: "👽",
    space_invader: "👾",
    robot_face: "🤖",
    smiley_cat: "😺",
    smile_cat: "😸",
    joy_cat: "😹",
    heart_eyes_cat: "😻",
    smirk_cat: "😼",
    kissing_cat: "😽",
    scream_cat: "🙀",
    crying_cat_face: "😿",
    pouting_cat: "😾",
    see_no_evil: "🙈",
    hear_no_evil: "🙉",
    speak_no_evil: "🙊",
    love_letter: "💌",
    cupid: "💘",
    gift_heart: "💝",
    sparkling_heart: "💖",
    heartpulse: "💗",
    heartbeat: "💓",
    revolving_hearts: "💞",
    two_hearts: "💕",
    heart_decoration: "💟",
    heavy_heart_exclamation_mark_ornament: "❣️",
    broken_heart: "💔",
    heart_on_fire: "❤️‍🔥",
    mending_heart: "❤️‍🩹",
    heart: "❤️",
    orange_heart: "🧡",
    yellow_heart: "💛",
    green_heart: "💚",
    blue_heart: "💙",
    purple_heart: "💜",
    brown_heart: "🤎",
    black_heart: "🖤",
    white_heart: "🤍",
    kiss: "💋",
    anger: "💢",
    boom: "💥",
    dizzy: "💫",
    sweat_drops: "💦",
    dash: "💨",
    hole: "🕳️",
    speech_balloon: "💬",
    "eye-in-speech-bubble": "👁️‍🗨️",
    left_speech_bubble: "🗨️",
    right_anger_bubble: "🗯️",
    thought_balloon: "💭",
    zzz: "💤",
    wave: "👋",
    raised_back_of_hand: "🤚",
    raised_hand_with_fingers_splayed: "🖐️",
    hand: "✋",
    "spock-hand": "🖖",
    rightwards_hand: "🫱",
    leftwards_hand: "🫲",
    palm_down_hand: "🫳",
    palm_up_hand: "🫴",
    ok_hand: "👌",
    pinched_fingers: "🤌",
    pinching_hand: "🤏",
    v: "✌️",
    crossed_fingers: "🤞",
    hand_with_index_finger_and_thumb_crossed: "🫰",
    i_love_you_hand_sign: "🤟",
    the_horns: "🤘",
    call_me_hand: "🤙",
    point_left: "👈",
    point_right: "👉",
    point_up_2: "👆",
    middle_finger: "🖕",
    point_down: "👇",
    point_up: "☝️",
    index_pointing_at_the_viewer: "🫵",
    "+1": "👍",
    "-1": "👎",
    fist: "✊",
    facepunch: "👊",
    "left-facing_fist": "🤛",
    "right-facing_fist": "🤜",
    clap: "👏",
    raised_hands: "🙌",
    heart_hands: "🫶",
    open_hands: "👐",
    palms_up_together: "🤲",
    handshake: "🤝",
    pray: "🙏",
    writing_hand: "✍️",
    nail_care: "💅",
    selfie: "🤳",
    muscle: "💪",
    mechanical_arm: "🦾",
    mechanical_leg: "🦿",
    leg: "🦵",
    foot: "🦶",
    ear: "👂",
    ear_with_hearing_aid: "🦻",
    nose: "👃",
    brain: "🧠",
    anatomical_heart: "🫀",
    lungs: "🫁",
    tooth: "🦷",
    bone: "🦴",
    eyes: "👀",
    eye: "👁️",
    tongue: "👅",
    lips: "👄",
    biting_lip: "🫦",
    baby: "👶",
    child: "🧒",
    boy: "👦",
    girl: "👧",
    adult: "🧑",
    person_with_blond_hair: "👱",
    man: "👨",
    bearded_person: "🧔",
    man_with_beard: "🧔‍♂️",
    woman_with_beard: "🧔‍♀️",
    red_haired_man: "👨‍🦰",
    curly_haired_man: "👨‍🦱",
    white_haired_man: "👨‍🦳",
    bald_man: "👨‍🦲",
    woman: "👩",
    red_haired_woman: "👩‍🦰",
    red_haired_person: "🧑‍🦰",
    curly_haired_woman: "👩‍🦱",
    curly_haired_person: "🧑‍🦱",
    white_haired_woman: "👩‍🦳",
    white_haired_person: "🧑‍🦳",
    bald_woman: "👩‍🦲",
    bald_person: "🧑‍🦲",
    "blond-haired-woman": "👱‍♀️",
    "blond-haired-man": "👱‍♂️",
    older_adult: "🧓",
    older_man: "👴",
    older_woman: "👵",
    person_frowning: "🙍",
    "man-frowning": "🙍‍♂️",
    "woman-frowning": "🙍‍♀️",
    person_with_pouting_face: "🙎",
    "man-pouting": "🙎‍♂️",
    "woman-pouting": "🙎‍♀️",
    no_good: "🙅",
    "man-gesturing-no": "🙅‍♂️",
    "woman-gesturing-no": "🙅‍♀️",
    ok_woman: "🙆",
    "man-gesturing-ok": "🙆‍♂️",
    "woman-gesturing-ok": "🙆‍♀️",
    information_desk_person: "💁",
    "man-tipping-hand": "💁‍♂️",
    "woman-tipping-hand": "💁‍♀️",
    raising_hand: "🙋",
    "man-raising-hand": "🙋‍♂️",
    "woman-raising-hand": "🙋‍♀️",
    deaf_person: "🧏",
    deaf_man: "🧏‍♂️",
    deaf_woman: "🧏‍♀️",
    bow: "🙇",
    "man-bowing": "🙇‍♂️",
    "woman-bowing": "🙇‍♀️",
    face_palm: "🤦",
    "man-facepalming": "🤦‍♂️",
    "woman-facepalming": "🤦‍♀️",
    shrug: "🤷",
    "man-shrugging": "🤷‍♂️",
    "woman-shrugging": "🤷‍♀️",
    health_worker: "🧑‍⚕️",
    "male-doctor": "👨‍⚕️",
    "female-doctor": "👩‍⚕️",
    student: "🧑‍🎓",
    "male-student": "👨‍🎓",
    "female-student": "👩‍🎓",
    teacher: "🧑‍🏫",
    "male-teacher": "👨‍🏫",
    "female-teacher": "👩‍🏫",
    judge: "🧑‍⚖️",
    "male-judge": "👨‍⚖️",
    "female-judge": "👩‍⚖️",
    farmer: "🧑‍🌾",
    "male-farmer": "👨‍🌾",
    "female-farmer": "👩‍🌾",
    cook: "🧑‍🍳",
    "male-cook": "👨‍🍳",
    "female-cook": "👩‍🍳",
    mechanic: "🧑‍🔧",
    "male-mechanic": "👨‍🔧",
    "female-mechanic": "👩‍🔧",
    factory_worker: "🧑‍🏭",
    "male-factory-worker": "👨‍🏭",
    "female-factory-worker": "👩‍🏭",
    office_worker: "🧑‍💼",
    "male-office-worker": "👨‍💼",
    "female-office-worker": "👩‍💼",
    scientist: "🧑‍🔬",
    "male-scientist": "👨‍🔬",
    "female-scientist": "👩‍🔬",
    technologist: "🧑‍💻",
    "male-technologist": "👨‍💻",
    "female-technologist": "👩‍💻",
    singer: "🧑‍🎤",
    "male-singer": "👨‍🎤",
    "female-singer": "👩‍🎤",
    artist: "🧑‍🎨",
    "male-artist": "👨‍🎨",
    "female-artist": "👩‍🎨",
    pilot: "🧑‍✈️",
    "male-pilot": "👨‍✈️",
    "female-pilot": "👩‍✈️",
    astronaut: "🧑‍🚀",
    "male-astronaut": "👨‍🚀",
    "female-astronaut": "👩‍🚀",
    firefighter: "🧑‍🚒",
    "male-firefighter": "👨‍🚒",
    "female-firefighter": "👩‍🚒",
    cop: "👮",
    "male-police-officer": "👮‍♂️",
    "female-police-officer": "👮‍♀️",
    sleuth_or_spy: "🕵️",
    "male-detective": "🕵️‍♂️",
    "female-detective": "🕵️‍♀️",
    guardsman: "💂",
    "male-guard": "💂‍♂️",
    "female-guard": "💂‍♀️",
    ninja: "🥷",
    construction_worker: "👷",
    "male-construction-worker": "👷‍♂️",
    "female-construction-worker": "👷‍♀️",
    person_with_crown: "🫅",
    prince: "🤴",
    princess: "👸",
    man_with_turban: "👳",
    "man-wearing-turban": "👳‍♂️",
    "woman-wearing-turban": "👳‍♀️",
    man_with_gua_pi_mao: "👲",
    person_with_headscarf: "🧕",
    person_in_tuxedo: "🤵",
    man_in_tuxedo: "🤵‍♂️",
    woman_in_tuxedo: "🤵‍♀️",
    bride_with_veil: "👰",
    man_with_veil: "👰‍♂️",
    woman_with_veil: "👰‍♀️",
    pregnant_woman: "🤰",
    pregnant_man: "🫃",
    pregnant_person: "🫄",
    "breast-feeding": "🤱",
    woman_feeding_baby: "👩‍🍼",
    man_feeding_baby: "👨‍🍼",
    person_feeding_baby: "🧑‍🍼",
    angel: "👼",
    santa: "🎅",
    mrs_claus: "🤶",
    mx_claus: "🧑‍🎄",
    superhero: "🦸",
    male_superhero: "🦸‍♂️",
    female_superhero: "🦸‍♀️",
    supervillain: "🦹",
    male_supervillain: "🦹‍♂️",
    female_supervillain: "🦹‍♀️",
    mage: "🧙",
    male_mage: "🧙‍♂️",
    female_mage: "🧙‍♀️",
    fairy: "🧚",
    male_fairy: "🧚‍♂️",
    female_fairy: "🧚‍♀️",
    vampire: "🧛",
    male_vampire: "🧛‍♂️",
    female_vampire: "🧛‍♀️",
    merperson: "🧜",
    merman: "🧜‍♂️",
    mermaid: "🧜‍♀️",
    elf: "🧝",
    male_elf: "🧝‍♂️",
    female_elf: "🧝‍♀️",
    genie: "🧞",
    male_genie: "🧞‍♂️",
    female_genie: "🧞‍♀️",
    zombie: "🧟",
    male_zombie: "🧟‍♂️",
    female_zombie: "🧟‍♀️",
    troll: "🧌",
    massage: "💆",
    "man-getting-massage": "💆‍♂️",
    "woman-getting-massage": "💆‍♀️",
    haircut: "💇",
    "man-getting-haircut": "💇‍♂️",
    "woman-getting-haircut": "💇‍♀️",
    walking: "🚶",
    "man-walking": "🚶‍♂️",
    "woman-walking": "🚶‍♀️",
    standing_person: "🧍",
    man_standing: "🧍‍♂️",
    woman_standing: "🧍‍♀️",
    kneeling_person: "🧎",
    man_kneeling: "🧎‍♂️",
    woman_kneeling: "🧎‍♀️",
    person_with_probing_cane: "🧑‍🦯",
    man_with_probing_cane: "👨‍🦯",
    woman_with_probing_cane: "👩‍🦯",
    person_in_motorized_wheelchair: "🧑‍🦼",
    man_in_motorized_wheelchair: "👨‍🦼",
    woman_in_motorized_wheelchair: "👩‍🦼",
    person_in_manual_wheelchair: "🧑‍🦽",
    man_in_manual_wheelchair: "👨‍🦽",
    woman_in_manual_wheelchair: "👩‍🦽",
    runner: "🏃",
    "man-running": "🏃‍♂️",
    "woman-running": "🏃‍♀️",
    dancer: "💃",
    man_dancing: "🕺",
    man_in_business_suit_levitating: "🕴️",
    dancers: "👯",
    "men-with-bunny-ears-partying": "👯‍♂️",
    "women-with-bunny-ears-partying": "👯‍♀️",
    person_in_steamy_room: "🧖",
    man_in_steamy_room: "🧖‍♂️",
    woman_in_steamy_room: "🧖‍♀️",
    person_climbing: "🧗",
    man_climbing: "🧗‍♂️",
    woman_climbing: "🧗‍♀️",
    fencer: "🤺",
    horse_racing: "🏇",
    skier: "⛷️",
    snowboarder: "🏂",
    golfer: "🏌️",
    "man-golfing": "🏌️‍♂️",
    "woman-golfing": "🏌️‍♀️",
    surfer: "🏄",
    "man-surfing": "🏄‍♂️",
    "woman-surfing": "🏄‍♀️",
    rowboat: "🚣",
    "man-rowing-boat": "🚣‍♂️",
    "woman-rowing-boat": "🚣‍♀️",
    swimmer: "🏊",
    "man-swimming": "🏊‍♂️",
    "woman-swimming": "🏊‍♀️",
    person_with_ball: "⛹️",
    "man-bouncing-ball": "⛹️‍♂️",
    "woman-bouncing-ball": "⛹️‍♀️",
    weight_lifter: "🏋️",
    "man-lifting-weights": "🏋️‍♂️",
    "woman-lifting-weights": "🏋️‍♀️",
    bicyclist: "🚴",
    "man-biking": "🚴‍♂️",
    "woman-biking": "🚴‍♀️",
    mountain_bicyclist: "🚵",
    "man-mountain-biking": "🚵‍♂️",
    "woman-mountain-biking": "🚵‍♀️",
    person_doing_cartwheel: "🤸",
    "man-cartwheeling": "🤸‍♂️",
    "woman-cartwheeling": "🤸‍♀️",
    wrestlers: "🤼",
    "man-wrestling": "🤼‍♂️",
    "woman-wrestling": "🤼‍♀️",
    water_polo: "🤽",
    "man-playing-water-polo": "🤽‍♂️",
    "woman-playing-water-polo": "🤽‍♀️",
    handball: "🤾",
    "man-playing-handball": "🤾‍♂️",
    "woman-playing-handball": "🤾‍♀️",
    juggling: "🤹",
    "man-juggling": "🤹‍♂️",
    "woman-juggling": "🤹‍♀️",
    person_in_lotus_position: "🧘",
    man_in_lotus_position: "🧘‍♂️",
    woman_in_lotus_position: "🧘‍♀️",
    bath: "🛀",
    sleeping_accommodation: "🛌",
    people_holding_hands: "🧑‍🤝‍🧑",
    two_women_holding_hands: "👭",
    man_and_woman_holding_hands: "👫",
    two_men_holding_hands: "👬",
    couplekiss: "💏",
    "woman-kiss-man": "👩‍❤️‍💋‍👨",
    "man-kiss-man": "👨‍❤️‍💋‍👨",
    "woman-kiss-woman": "👩‍❤️‍💋‍👩",
    couple_with_heart: "💑",
    "woman-heart-man": "👩‍❤️‍👨",
    "man-heart-man": "👨‍❤️‍👨",
    "woman-heart-woman": "👩‍❤️‍👩",
    family: "👪",
    "man-woman-boy": "👨‍👩‍👦",
    "man-woman-girl": "👨‍👩‍👧",
    "man-woman-girl-boy": "👨‍👩‍👧‍👦",
    "man-woman-boy-boy": "👨‍👩‍👦‍👦",
    "man-woman-girl-girl": "👨‍👩‍👧‍👧",
    "man-man-boy": "👨‍👨‍👦",
    "man-man-girl": "👨‍👨‍👧",
    "man-man-girl-boy": "👨‍👨‍👧‍👦",
    "man-man-boy-boy": "👨‍👨‍👦‍👦",
    "man-man-girl-girl": "👨‍👨‍👧‍👧",
    "woman-woman-boy": "👩‍👩‍👦",
    "woman-woman-girl": "👩‍👩‍👧",
    "woman-woman-girl-boy": "👩‍👩‍👧‍👦",
    "woman-woman-boy-boy": "👩‍👩‍👦‍👦",
    "woman-woman-girl-girl": "👩‍👩‍👧‍👧",
    "man-boy": "👨‍👦",
    "man-boy-boy": "👨‍👦‍👦",
    "man-girl": "👨‍👧",
    "man-girl-boy": "👨‍👧‍👦",
    "man-girl-girl": "👨‍👧‍👧",
    "woman-boy": "👩‍👦",
    "woman-boy-boy": "👩‍👦‍👦",
    "woman-girl": "👩‍👧",
    "woman-girl-boy": "👩‍👧‍👦",
    "woman-girl-girl": "👩‍👧‍👧",
    speaking_head_in_silhouette: "🗣️",
    bust_in_silhouette: "👤",
    busts_in_silhouette: "👥",
    people_hugging: "🫂",
    footprints: "👣",
    monkey_face: "🐵",
    monkey: "🐒",
    gorilla: "🦍",
    orangutan: "🦧",
    dog: "🐶",
    dog2: "🐕",
    guide_dog: "🦮",
    service_dog: "🐕‍🦺",
    poodle: "🐩",
    wolf: "🐺",
    fox_face: "🦊",
    raccoon: "🦝",
    cat: "🐱",
    cat2: "🐈",
    black_cat: "🐈‍⬛",
    lion_face: "🦁",
    tiger: "🐯",
    tiger2: "🐅",
    leopard: "🐆",
    horse: "🐴",
    racehorse: "🐎",
    unicorn_face: "🦄",
    zebra_face: "🦓",
    deer: "🦌",
    bison: "🦬",
    cow: "🐮",
    ox: "🐂",
    water_buffalo: "🐃",
    cow2: "🐄",
    pig: "🐷",
    pig2: "🐖",
    boar: "🐗",
    pig_nose: "🐽",
    ram: "🐏",
    sheep: "🐑",
    goat: "🐐",
    dromedary_camel: "🐪",
    camel: "🐫",
    llama: "🦙",
    giraffe_face: "🦒",
    elephant: "🐘",
    mammoth: "🦣",
    rhinoceros: "🦏",
    hippopotamus: "🦛",
    mouse: "🐭",
    mouse2: "🐁",
    rat: "🐀",
    hamster: "🐹",
    rabbit: "🐰",
    rabbit2: "🐇",
    chipmunk: "🐿️",
    beaver: "🦫",
    hedgehog: "🦔",
    bat: "🦇",
    bear: "🐻",
    polar_bear: "🐻‍❄️",
    koala: "🐨",
    panda_face: "🐼",
    sloth: "🦥",
    otter: "🦦",
    skunk: "🦨",
    kangaroo: "🦘",
    badger: "🦡",
    feet: "🐾",
    turkey: "🦃",
    chicken: "🐔",
    rooster: "🐓",
    hatching_chick: "🐣",
    baby_chick: "🐤",
    hatched_chick: "🐥",
    bird: "🐦",
    penguin: "🐧",
    dove_of_peace: "🕊️",
    eagle: "🦅",
    duck: "🦆",
    swan: "🦢",
    owl: "🦉",
    dodo: "🦤",
    feather: "🪶",
    flamingo: "🦩",
    peacock: "🦚",
    parrot: "🦜",
    frog: "🐸",
    crocodile: "🐊",
    turtle: "🐢",
    lizard: "🦎",
    snake: "🐍",
    dragon_face: "🐲",
    dragon: "🐉",
    sauropod: "🦕",
    "t-rex": "🦖",
    whale: "🐳",
    whale2: "🐋",
    dolphin: "🐬",
    seal: "🦭",
    fish: "🐟",
    tropical_fish: "🐠",
    blowfish: "🐡",
    shark: "🦈",
    octopus: "🐙",
    shell: "🐚",
    coral: "🪸",
    snail: "🐌",
    butterfly: "🦋",
    bug: "🐛",
    ant: "🐜",
    bee: "🐝",
    beetle: "🪲",
    ladybug: "🐞",
    cricket: "🦗",
    cockroach: "🪳",
    spider: "🕷️",
    spider_web: "🕸️",
    scorpion: "🦂",
    mosquito: "🦟",
    fly: "🪰",
    worm: "🪱",
    microbe: "🦠",
    bouquet: "💐",
    cherry_blossom: "🌸",
    white_flower: "💮",
    lotus: "🪷",
    rosette: "🏵️",
    rose: "🌹",
    wilted_flower: "🥀",
    hibiscus: "🌺",
    sunflower: "🌻",
    blossom: "🌼",
    tulip: "🌷",
    seedling: "🌱",
    potted_plant: "🪴",
    evergreen_tree: "🌲",
    deciduous_tree: "🌳",
    palm_tree: "🌴",
    cactus: "🌵",
    ear_of_rice: "🌾",
    herb: "🌿",
    shamrock: "☘️",
    four_leaf_clover: "🍀",
    maple_leaf: "🍁",
    fallen_leaf: "🍂",
    leaves: "🍃",
    empty_nest: "🪹",
    nest_with_eggs: "🪺",
    mushroom: "🍄",
    grapes: "🍇",
    melon: "🍈",
    watermelon: "🍉",
    tangerine: "🍊",
    lemon: "🍋",
    banana: "🍌",
    pineapple: "🍍",
    mango: "🥭",
    apple: "🍎",
    green_apple: "🍏",
    pear: "🍐",
    peach: "🍑",
    cherries: "🍒",
    strawberry: "🍓",
    blueberries: "🫐",
    kiwifruit: "🥝",
    tomato: "🍅",
    olive: "🫒",
    coconut: "🥥",
    avocado: "🥑",
    eggplant: "🍆",
    potato: "🥔",
    carrot: "🥕",
    corn: "🌽",
    hot_pepper: "🌶️",
    bell_pepper: "🫑",
    cucumber: "🥒",
    leafy_green: "🥬",
    broccoli: "🥦",
    garlic: "🧄",
    onion: "🧅",
    peanuts: "🥜",
    beans: "🫘",
    chestnut: "🌰",
    bread: "🍞",
    croissant: "🥐",
    baguette_bread: "🥖",
    flatbread: "🫓",
    pretzel: "🥨",
    bagel: "🥯",
    pancakes: "🥞",
    waffle: "🧇",
    cheese_wedge: "🧀",
    meat_on_bone: "🍖",
    poultry_leg: "🍗",
    cut_of_meat: "🥩",
    bacon: "🥓",
    hamburger: "🍔",
    fries: "🍟",
    pizza: "🍕",
    hotdog: "🌭",
    sandwich: "🥪",
    taco: "🌮",
    burrito: "🌯",
    tamale: "🫔",
    stuffed_flatbread: "🥙",
    falafel: "🧆",
    egg: "🥚",
    fried_egg: "🍳",
    shallow_pan_of_food: "🥘",
    stew: "🍲",
    fondue: "🫕",
    bowl_with_spoon: "🥣",
    green_salad: "🥗",
    popcorn: "🍿",
    butter: "🧈",
    salt: "🧂",
    canned_food: "🥫",
    bento: "🍱",
    rice_cracker: "🍘",
    rice_ball: "🍙",
    rice: "🍚",
    curry: "🍛",
    ramen: "🍜",
    spaghetti: "🍝",
    sweet_potato: "🍠",
    oden: "🍢",
    sushi: "🍣",
    fried_shrimp: "🍤",
    fish_cake: "🍥",
    moon_cake: "🥮",
    dango: "🍡",
    dumpling: "🥟",
    fortune_cookie: "🥠",
    takeout_box: "🥡",
    crab: "🦀",
    lobster: "🦞",
    shrimp: "🦐",
    squid: "🦑",
    oyster: "🦪",
    icecream: "🍦",
    shaved_ice: "🍧",
    ice_cream: "🍨",
    doughnut: "🍩",
    cookie: "🍪",
    birthday: "🎂",
    cake: "🍰",
    cupcake: "🧁",
    pie: "🥧",
    chocolate_bar: "🍫",
    candy: "🍬",
    lollipop: "🍭",
    custard: "🍮",
    honey_pot: "🍯",
    baby_bottle: "🍼",
    glass_of_milk: "🥛",
    coffee: "☕",
    teapot: "🫖",
    tea: "🍵",
    sake: "🍶",
    champagne: "🍾",
    wine_glass: "🍷",
    cocktail: "🍸",
    tropical_drink: "🍹",
    beer: "🍺",
    beers: "🍻",
    clinking_glasses: "🥂",
    tumbler_glass: "🥃",
    pouring_liquid: "🫗",
    cup_with_straw: "🥤",
    bubble_tea: "🧋",
    beverage_box: "🧃",
    mate_drink: "🧉",
    ice_cube: "🧊",
    chopsticks: "🥢",
    knife_fork_plate: "🍽️",
    fork_and_knife: "🍴",
    spoon: "🥄",
    hocho: "🔪",
    jar: "🫙",
    amphora: "🏺",
    earth_africa: "🌍",
    earth_americas: "🌎",
    earth_asia: "🌏",
    globe_with_meridians: "🌐",
    world_map: "🗺️",
    japan: "🗾",
    compass: "🧭",
    snow_capped_mountain: "🏔️",
    mountain: "⛰️",
    volcano: "🌋",
    mount_fuji: "🗻",
    camping: "🏕️",
    beach_with_umbrella: "🏖️",
    desert: "🏜️",
    desert_island: "🏝️",
    national_park: "🏞️",
    stadium: "🏟️",
    classical_building: "🏛️",
    building_construction: "🏗️",
    bricks: "🧱",
    rock: "🪨",
    wood: "🪵",
    hut: "🛖",
    house_buildings: "🏘️",
    derelict_house_building: "🏚️",
    house: "🏠",
    house_with_garden: "🏡",
    office: "🏢",
    post_office: "🏣",
    european_post_office: "🏤",
    hospital: "🏥",
    bank: "🏦",
    hotel: "🏨",
    love_hotel: "🏩",
    convenience_store: "🏪",
    school: "🏫",
    department_store: "🏬",
    factory: "🏭",
    japanese_castle: "🏯",
    european_castle: "🏰",
    wedding: "💒",
    tokyo_tower: "🗼",
    statue_of_liberty: "🗽",
    church: "⛪",
    mosque: "🕌",
    hindu_temple: "🛕",
    synagogue: "🕍",
    shinto_shrine: "⛩️",
    kaaba: "🕋",
    fountain: "⛲",
    tent: "⛺",
    foggy: "🌁",
    night_with_stars: "🌃",
    cityscape: "🏙️",
    sunrise_over_mountains: "🌄",
    sunrise: "🌅",
    city_sunset: "🌆",
    city_sunrise: "🌇",
    bridge_at_night: "🌉",
    hotsprings: "♨️",
    carousel_horse: "🎠",
    playground_slide: "🛝",
    ferris_wheel: "🎡",
    roller_coaster: "🎢",
    barber: "💈",
    circus_tent: "🎪",
    steam_locomotive: "🚂",
    railway_car: "🚃",
    bullettrain_side: "🚄",
    bullettrain_front: "🚅",
    train2: "🚆",
    metro: "🚇",
    light_rail: "🚈",
    station: "🚉",
    tram: "🚊",
    monorail: "🚝",
    mountain_railway: "🚞",
    train: "🚋",
    bus: "🚌",
    oncoming_bus: "🚍",
    trolleybus: "🚎",
    minibus: "🚐",
    ambulance: "🚑",
    fire_engine: "🚒",
    police_car: "🚓",
    oncoming_police_car: "🚔",
    taxi: "🚕",
    oncoming_taxi: "🚖",
    car: "🚗",
    oncoming_automobile: "🚘",
    blue_car: "🚙",
    pickup_truck: "🛻",
    truck: "🚚",
    articulated_lorry: "🚛",
    tractor: "🚜",
    racing_car: "🏎️",
    racing_motorcycle: "🏍️",
    motor_scooter: "🛵",
    manual_wheelchair: "🦽",
    motorized_wheelchair: "🦼",
    auto_rickshaw: "🛺",
    bike: "🚲",
    scooter: "🛴",
    skateboard: "🛹",
    roller_skate: "🛼",
    busstop: "🚏",
    motorway: "🛣️",
    railway_track: "🛤️",
    oil_drum: "🛢️",
    fuelpump: "⛽",
    wheel: "🛞",
    rotating_light: "🚨",
    traffic_light: "🚥",
    vertical_traffic_light: "🚦",
    octagonal_sign: "🛑",
    construction: "🚧",
    anchor: "⚓",
    ring_buoy: "🛟",
    boat: "⛵",
    canoe: "🛶",
    speedboat: "🚤",
    passenger_ship: "🛳️",
    ferry: "⛴️",
    motor_boat: "🛥️",
    ship: "🚢",
    airplane: "✈️",
    small_airplane: "🛩️",
    airplane_departure: "🛫",
    airplane_arriving: "🛬",
    parachute: "🪂",
    seat: "💺",
    helicopter: "🚁",
    suspension_railway: "🚟",
    mountain_cableway: "🚠",
    aerial_tramway: "🚡",
    satellite: "🛰️",
    rocket: "🚀",
    flying_saucer: "🛸",
    bellhop_bell: "🛎️",
    luggage: "🧳",
    hourglass: "⌛",
    hourglass_flowing_sand: "⏳",
    watch: "⌚",
    alarm_clock: "⏰",
    stopwatch: "⏱️",
    timer_clock: "⏲️",
    mantelpiece_clock: "🕰️",
    clock12: "🕛",
    clock1230: "🕧",
    clock1: "🕐",
    clock130: "🕜",
    clock2: "🕑",
    clock230: "🕝",
    clock3: "🕒",
    clock330: "🕞",
    clock4: "🕓",
    clock430: "🕟",
    clock5: "🕔",
    clock530: "🕠",
    clock6: "🕕",
    clock630: "🕡",
    clock7: "🕖",
    clock730: "🕢",
    clock8: "🕗",
    clock830: "🕣",
    clock9: "🕘",
    clock930: "🕤",
    clock10: "🕙",
    clock1030: "🕥",
    clock11: "🕚",
    clock1130: "🕦",
    new_moon: "🌑",
    waxing_crescent_moon: "🌒",
    first_quarter_moon: "🌓",
    moon: "🌔",
    full_moon: "🌕",
    waning_gibbous_moon: "🌖",
    last_quarter_moon: "🌗",
    waning_crescent_moon: "🌘",
    crescent_moon: "🌙",
    new_moon_with_face: "🌚",
    first_quarter_moon_with_face: "🌛",
    last_quarter_moon_with_face: "🌜",
    thermometer: "🌡️",
    sunny: "☀️",
    full_moon_with_face: "🌝",
    sun_with_face: "🌞",
    ringed_planet: "🪐",
    star: "⭐",
    star2: "🌟",
    stars: "🌠",
    milky_way: "🌌",
    cloud: "☁️",
    partly_sunny: "⛅",
    thunder_cloud_and_rain: "⛈️",
    mostly_sunny: "🌤️",
    barely_sunny: "🌥️",
    partly_sunny_rain: "🌦️",
    rain_cloud: "🌧️",
    snow_cloud: "🌨️",
    lightning: "🌩️",
    tornado: "🌪️",
    fog: "🌫️",
    wind_blowing_face: "🌬️",
    cyclone: "🌀",
    rainbow: "🌈",
    closed_umbrella: "🌂",
    umbrella: "☂️",
    umbrella_with_rain_drops: "☔",
    umbrella_on_ground: "⛱️",
    zap: "⚡",
    snowflake: "❄️",
    snowman: "☃️",
    snowman_without_snow: "⛄",
    comet: "☄️",
    fire: "🔥",
    droplet: "💧",
    ocean: "🌊",
    jack_o_lantern: "🎃",
    christmas_tree: "🎄",
    fireworks: "🎆",
    sparkler: "🎇",
    firecracker: "🧨",
    sparkles: "✨",
    balloon: "🎈",
    tada: "🎉",
    confetti_ball: "🎊",
    tanabata_tree: "🎋",
    bamboo: "🎍",
    dolls: "🎎",
    flags: "🎏",
    wind_chime: "🎐",
    rice_scene: "🎑",
    red_envelope: "🧧",
    ribbon: "🎀",
    gift: "🎁",
    reminder_ribbon: "🎗️",
    admission_tickets: "🎟️",
    ticket: "🎫",
    medal: "🎖️",
    trophy: "🏆",
    sports_medal: "🏅",
    first_place_medal: "🥇",
    second_place_medal: "🥈",
    third_place_medal: "🥉",
    soccer: "⚽",
    baseball: "⚾",
    softball: "🥎",
    basketball: "🏀",
    volleyball: "🏐",
    football: "🏈",
    rugby_football: "🏉",
    tennis: "🎾",
    flying_disc: "🥏",
    bowling: "🎳",
    cricket_bat_and_ball: "🏏",
    field_hockey_stick_and_ball: "🏑",
    ice_hockey_stick_and_puck: "🏒",
    lacrosse: "🥍",
    table_tennis_paddle_and_ball: "🏓",
    badminton_racquet_and_shuttlecock: "🏸",
    boxing_glove: "🥊",
    martial_arts_uniform: "🥋",
    goal_net: "🥅",
    golf: "⛳",
    ice_skate: "⛸️",
    fishing_pole_and_fish: "🎣",
    diving_mask: "🤿",
    running_shirt_with_sash: "🎽",
    ski: "🎿",
    sled: "🛷",
    curling_stone: "🥌",
    dart: "🎯",
    "yo-yo": "🪀",
    kite: "🪁",
    gun: "🔫",
    "8ball": "🎱",
    crystal_ball: "🔮",
    magic_wand: "🪄",
    video_game: "🎮",
    joystick: "🕹️",
    slot_machine: "🎰",
    game_die: "🎲",
    jigsaw: "🧩",
    teddy_bear: "🧸",
    pinata: "🪅",
    mirror_ball: "🪩",
    nesting_dolls: "🪆",
    spades: "♠️",
    hearts: "♥️",
    diamonds: "♦️",
    clubs: "♣️",
    chess_pawn: "♟️",
    black_joker: "🃏",
    mahjong: "🀄",
    flower_playing_cards: "🎴",
    performing_arts: "🎭",
    frame_with_picture: "🖼️",
    art: "🎨",
    thread: "🧵",
    sewing_needle: "🪡",
    yarn: "🧶",
    knot: "🪢",
    eyeglasses: "👓",
    dark_sunglasses: "🕶️",
    goggles: "🥽",
    lab_coat: "🥼",
    safety_vest: "🦺",
    necktie: "👔",
    shirt: "👕",
    jeans: "👖",
    scarf: "🧣",
    gloves: "🧤",
    coat: "🧥",
    socks: "🧦",
    dress: "👗",
    kimono: "👘",
    sari: "🥻",
    "one-piece_swimsuit": "🩱",
    briefs: "🩲",
    shorts: "🩳",
    bikini: "👙",
    womans_clothes: "👚",
    purse: "👛",
    handbag: "👜",
    pouch: "👝",
    shopping_bags: "🛍️",
    school_satchel: "🎒",
    thong_sandal: "🩴",
    mans_shoe: "👞",
    athletic_shoe: "👟",
    hiking_boot: "🥾",
    womans_flat_shoe: "🥿",
    high_heel: "👠",
    sandal: "👡",
    ballet_shoes: "🩰",
    boot: "👢",
    crown: "👑",
    womans_hat: "👒",
    tophat: "🎩",
    mortar_board: "🎓",
    billed_cap: "🧢",
    military_helmet: "🪖",
    helmet_with_white_cross: "⛑️",
    prayer_beads: "📿",
    lipstick: "💄",
    ring: "💍",
    gem: "💎",
    mute: "🔇",
    speaker: "🔈",
    sound: "🔉",
    loud_sound: "🔊",
    loudspeaker: "📢",
    mega: "📣",
    postal_horn: "📯",
    bell: "🔔",
    no_bell: "🔕",
    musical_score: "🎼",
    musical_note: "🎵",
    notes: "🎶",
    studio_microphone: "🎙️",
    level_slider: "🎚️",
    control_knobs: "🎛️",
    microphone: "🎤",
    headphones: "🎧",
    radio: "📻",
    saxophone: "🎷",
    accordion: "🪗",
    guitar: "🎸",
    musical_keyboard: "🎹",
    trumpet: "🎺",
    violin: "🎻",
    banjo: "🪕",
    drum_with_drumsticks: "🥁",
    long_drum: "🪘",
    iphone: "📱",
    calling: "📲",
    phone: "☎️",
    telephone_receiver: "📞",
    pager: "📟",
    fax: "📠",
    battery: "🔋",
    low_battery: "🪫",
    electric_plug: "🔌",
    computer: "💻",
    desktop_computer: "🖥️",
    printer: "🖨️",
    keyboard: "⌨️",
    three_button_mouse: "🖱️",
    trackball: "🖲️",
    minidisc: "💽",
    floppy_disk: "💾",
    cd: "💿",
    dvd: "📀",
    abacus: "🧮",
    movie_camera: "🎥",
    film_frames: "🎞️",
    film_projector: "📽️",
    clapper: "🎬",
    tv: "📺",
    camera: "📷",
    camera_with_flash: "📸",
    video_camera: "📹",
    vhs: "📼",
    mag: "🔍",
    mag_right: "🔎",
    candle: "🕯️",
    bulb: "💡",
    flashlight: "🔦",
    izakaya_lantern: "🏮",
    diya_lamp: "🪔",
    notebook_with_decorative_cover: "📔",
    closed_book: "📕",
    book: "📖",
    green_book: "📗",
    blue_book: "📘",
    orange_book: "📙",
    books: "📚",
    notebook: "📓",
    ledger: "📒",
    page_with_curl: "📃",
    scroll: "📜",
    page_facing_up: "📄",
    newspaper: "📰",
    rolled_up_newspaper: "🗞️",
    bookmark_tabs: "📑",
    bookmark: "🔖",
    label: "🏷️",
    moneybag: "💰",
    coin: "🪙",
    yen: "💴",
    dollar: "💵",
    euro: "💶",
    pound: "💷",
    money_with_wings: "💸",
    credit_card: "💳",
    receipt: "🧾",
    chart: "💹",
    email: "✉️",
    "e-mail": "📧",
    incoming_envelope: "📨",
    envelope_with_arrow: "📩",
    outbox_tray: "📤",
    inbox_tray: "📥",
    package: "📦",
    mailbox: "📫",
    mailbox_closed: "📪",
    mailbox_with_mail: "📬",
    mailbox_with_no_mail: "📭",
    postbox: "📮",
    ballot_box_with_ballot: "🗳️",
    pencil2: "✏️",
    black_nib: "✒️",
    lower_left_fountain_pen: "🖋️",
    lower_left_ballpoint_pen: "🖊️",
    lower_left_paintbrush: "🖌️",
    lower_left_crayon: "🖍️",
    memo: "📝",
    briefcase: "💼",
    file_folder: "📁",
    open_file_folder: "📂",
    card_index_dividers: "🗂️",
    date: "📅",
    calendar: "📆",
    spiral_note_pad: "🗒️",
    spiral_calendar_pad: "🗓️",
    card_index: "📇",
    chart_with_upwards_trend: "📈",
    chart_with_downwards_trend: "📉",
    bar_chart: "📊",
    clipboard: "📋",
    pushpin: "📌",
    round_pushpin: "📍",
    paperclip: "📎",
    linked_paperclips: "🖇️",
    straight_ruler: "📏",
    triangular_ruler: "📐",
    scissors: "✂️",
    card_file_box: "🗃️",
    file_cabinet: "🗄️",
    wastebasket: "🗑️",
    lock: "🔒",
    unlock: "🔓",
    lock_with_ink_pen: "🔏",
    closed_lock_with_key: "🔐",
    key: "🔑",
    old_key: "🗝️",
    hammer: "🔨",
    axe: "🪓",
    pick: "⛏️",
    hammer_and_pick: "⚒️",
    hammer_and_wrench: "🛠️",
    dagger_knife: "🗡️",
    crossed_swords: "⚔️",
    bomb: "💣",
    boomerang: "🪃",
    bow_and_arrow: "🏹",
    shield: "🛡️",
    carpentry_saw: "🪚",
    wrench: "🔧",
    screwdriver: "🪛",
    nut_and_bolt: "🔩",
    gear: "⚙️",
    compression: "🗜️",
    scales: "⚖️",
    probing_cane: "🦯",
    link: "🔗",
    chains: "⛓️",
    hook: "🪝",
    toolbox: "🧰",
    magnet: "🧲",
    ladder: "🪜",
    alembic: "⚗️",
    test_tube: "🧪",
    petri_dish: "🧫",
    dna: "🧬",
    microscope: "🔬",
    telescope: "🔭",
    satellite_antenna: "📡",
    syringe: "💉",
    drop_of_blood: "🩸",
    pill: "💊",
    adhesive_bandage: "🩹",
    crutch: "🩼",
    stethoscope: "🩺",
    "x-ray": "🩻",
    door: "🚪",
    elevator: "🛗",
    mirror: "🪞",
    window: "🪟",
    bed: "🛏️",
    couch_and_lamp: "🛋️",
    chair: "🪑",
    toilet: "🚽",
    plunger: "🪠",
    shower: "🚿",
    bathtub: "🛁",
    mouse_trap: "🪤",
    razor: "🪒",
    lotion_bottle: "🧴",
    safety_pin: "🧷",
    broom: "🧹",
    basket: "🧺",
    roll_of_paper: "🧻",
    bucket: "🪣",
    soap: "🧼",
    bubbles: "🫧",
    toothbrush: "🪥",
    sponge: "🧽",
    fire_extinguisher: "🧯",
    shopping_trolley: "🛒",
    smoking: "🚬",
    coffin: "⚰️",
    headstone: "🪦",
    funeral_urn: "⚱️",
    nazar_amulet: "🧿",
    hamsa: "🪬",
    moyai: "🗿",
    placard: "🪧",
    identification_card: "🪪",
    atm: "🏧",
    put_litter_in_its_place: "🚮",
    potable_water: "🚰",
    wheelchair: "♿",
    mens: "🚹",
    womens: "🚺",
    restroom: "🚻",
    baby_symbol: "🚼",
    wc: "🚾",
    passport_control: "🛂",
    customs: "🛃",
    baggage_claim: "🛄",
    left_luggage: "🛅",
    warning: "⚠️",
    children_crossing: "🚸",
    no_entry: "⛔",
    no_entry_sign: "🚫",
    no_bicycles: "🚳",
    no_smoking: "🚭",
    do_not_litter: "🚯",
    "non-potable_water": "🚱",
    no_pedestrians: "🚷",
    no_mobile_phones: "📵",
    underage: "🔞",
    radioactive_sign: "☢️",
    biohazard_sign: "☣️",
    arrow_up: "⬆️",
    arrow_upper_right: "↗️",
    arrow_right: "➡️",
    arrow_lower_right: "↘️",
    arrow_down: "⬇️",
    arrow_lower_left: "↙️",
    arrow_left: "⬅️",
    arrow_upper_left: "↖️",
    arrow_up_down: "↕️",
    left_right_arrow: "↔️",
    leftwards_arrow_with_hook: "↩️",
    arrow_right_hook: "↪️",
    arrow_heading_up: "⤴️",
    arrow_heading_down: "⤵️",
    arrows_clockwise: "🔃",
    arrows_counterclockwise: "🔄",
    back: "🔙",
    end: "🔚",
    on: "🔛",
    soon: "🔜",
    top: "🔝",
    place_of_worship: "🛐",
    atom_symbol: "⚛️",
    om_symbol: "🕉️",
    star_of_david: "✡️",
    wheel_of_dharma: "☸️",
    yin_yang: "☯️",
    latin_cross: "✝️",
    orthodox_cross: "☦️",
    star_and_crescent: "☪️",
    peace_symbol: "☮️",
    menorah_with_nine_branches: "🕎",
    six_pointed_star: "🔯",
    aries: "♈",
    taurus: "♉",
    gemini: "♊",
    cancer: "♋",
    leo: "♌",
    virgo: "♍",
    libra: "♎",
    scorpius: "♏",
    sagittarius: "♐",
    capricorn: "♑",
    aquarius: "♒",
    pisces: "♓",
    ophiuchus: "⛎",
    twisted_rightwards_arrows: "🔀",
    repeat: "🔁",
    repeat_one: "🔂",
    arrow_forward: "▶️",
    fast_forward: "⏩",
    black_right_pointing_double_triangle_with_vertical_bar: "⏭️",
    black_right_pointing_triangle_with_double_vertical_bar: "⏯️",
    arrow_backward: "◀️",
    rewind: "⏪",
    black_left_pointing_double_triangle_with_vertical_bar: "⏮️",
    arrow_up_small: "🔼",
    arrow_double_up: "⏫",
    arrow_down_small: "🔽",
    arrow_double_down: "⏬",
    double_vertical_bar: "⏸️",
    black_square_for_stop: "⏹️",
    black_circle_for_record: "⏺️",
    eject: "⏏️",
    cinema: "🎦",
    low_brightness: "🔅",
    high_brightness: "🔆",
    signal_strength: "📶",
    vibration_mode: "📳",
    mobile_phone_off: "📴",
    female_sign: "♀️",
    male_sign: "♂️",
    transgender_symbol: "⚧️",
    heavy_multiplication_x: "✖️",
    heavy_plus_sign: "➕",
    heavy_minus_sign: "➖",
    heavy_division_sign: "➗",
    heavy_equals_sign: "🟰",
    infinity: "♾️",
    bangbang: "‼️",
    interrobang: "⁉️",
    question: "❓",
    grey_question: "❔",
    grey_exclamation: "❕",
    exclamation: "❗",
    wavy_dash: "〰️",
    currency_exchange: "💱",
    heavy_dollar_sign: "💲",
    medical_symbol: "⚕️",
    recycle: "♻️",
    fleur_de_lis: "⚜️",
    trident: "🔱",
    name_badge: "📛",
    beginner: "🔰",
    o: "⭕",
    white_check_mark: "✅",
    ballot_box_with_check: "☑️",
    heavy_check_mark: "✔️",
    x: "❌",
    negative_squared_cross_mark: "❎",
    curly_loop: "➰",
    loop: "➿",
    part_alternation_mark: "〽️",
    eight_spoked_asterisk: "✳️",
    eight_pointed_black_star: "✴️",
    sparkle: "❇️",
    copyright: "©️",
    registered: "®️",
    tm: "™️",
    hash: "#️⃣",
    keycap_star: "*️⃣",
    zero: "0️⃣",
    one: "1️⃣",
    two: "2️⃣",
    three: "3️⃣",
    four: "4️⃣",
    five: "5️⃣",
    six: "6️⃣",
    seven: "7️⃣",
    eight: "8️⃣",
    nine: "9️⃣",
    keycap_ten: "🔟",
    capital_abcd: "🔠",
    abcd: "🔡",
    symbols: "🔣",
    abc: "🔤",
    a: "🅰️",
    ab: "🆎",
    b: "🅱️",
    cl: "🆑",
    cool: "🆒",
    free: "🆓",
    information_source: "ℹ️",
    id: "🆔",
    m: "Ⓜ️",
    new: "🆕",
    ng: "🆖",
    o2: "🅾️",
    ok: "🆗",
    parking: "🅿️",
    sos: "🆘",
    up: "🆙",
    vs: "🆚",
    koko: "🈁",
    sa: "🈂️",
    u6708: "🈷️",
    u6709: "🈶",
    u6307: "🈯",
    ideograph_advantage: "🉐",
    u5272: "🈹",
    u7121: "🈚",
    u7981: "🈲",
    accept: "🉑",
    u7533: "🈸",
    u5408: "🈴",
    u7a7a: "🈳",
    congratulations: "㊗️",
    secret: "㊙️",
    u55b6: "🈺",
    u6e80: "🈵",
    red_circle: "🔴",
    large_orange_circle: "🟠",
    large_yellow_circle: "🟡",
    large_green_circle: "🟢",
    large_blue_circle: "🔵",
    large_purple_circle: "🟣",
    large_brown_circle: "🟤",
    black_circle: "⚫",
    white_circle: "⚪",
    large_red_square: "🟥",
    large_orange_square: "🟧",
    large_yellow_square: "🟨",
    large_green_square: "🟩",
    large_blue_square: "🟦",
    large_purple_square: "🟪",
    large_brown_square: "🟫",
    black_large_square: "⬛",
    white_large_square: "⬜",
    black_medium_square: "◼️",
    white_medium_square: "◻️",
    black_medium_small_square: "◾",
    white_medium_small_square: "◽",
    black_small_square: "▪️",
    white_small_square: "▫️",
    large_orange_diamond: "🔶",
    large_blue_diamond: "🔷",
    small_orange_diamond: "🔸",
    small_blue_diamond: "🔹",
    small_red_triangle: "🔺",
    small_red_triangle_down: "🔻",
    diamond_shape_with_a_dot_inside: "💠",
    radio_button: "🔘",
    white_square_button: "🔳",
    black_square_button: "🔲",
    checkered_flag: "🏁",
    triangular_flag_on_post: "🚩",
    crossed_flags: "🎌",
    waving_black_flag: "🏴",
    waving_white_flag: "🏳️",
    "rainbow-flag": "🏳️‍🌈",
    transgender_flag: "🏳️‍⚧️",
    pirate_flag: "🏴‍☠️",
    "flag-ac": "🇦🇨",
    "flag-ad": "🇦🇩",
    "flag-ae": "🇦🇪",
    "flag-af": "🇦🇫",
    "flag-ag": "🇦🇬",
    "flag-ai": "🇦🇮",
    "flag-al": "🇦🇱",
    "flag-am": "🇦🇲",
    "flag-ao": "🇦🇴",
    "flag-aq": "🇦🇶",
    "flag-ar": "🇦🇷",
    "flag-as": "🇦🇸",
    "flag-at": "🇦🇹",
    "flag-au": "🇦🇺",
    "flag-aw": "🇦🇼",
    "flag-ax": "🇦🇽",
    "flag-az": "🇦🇿",
    "flag-ba": "🇧🇦",
    "flag-bb": "🇧🇧",
    "flag-bd": "🇧🇩",
    "flag-be": "🇧🇪",
    "flag-bf": "🇧🇫",
    "flag-bg": "🇧🇬",
    "flag-bh": "🇧🇭",
    "flag-bi": "🇧🇮",
    "flag-bj": "🇧🇯",
    "flag-bl": "🇧🇱",
    "flag-bm": "🇧🇲",
    "flag-bn": "🇧🇳",
    "flag-bo": "🇧🇴",
    "flag-bq": "🇧🇶",
    "flag-br": "🇧🇷",
    "flag-bs": "🇧🇸",
    "flag-bt": "🇧🇹",
    "flag-bv": "🇧🇻",
    "flag-bw": "🇧🇼",
    "flag-by": "🇧🇾",
    "flag-bz": "🇧🇿",
    "flag-ca": "🇨🇦",
    "flag-cc": "🇨🇨",
    "flag-cd": "🇨🇩",
    "flag-cf": "🇨🇫",
    "flag-cg": "🇨🇬",
    "flag-ch": "🇨🇭",
    "flag-ci": "🇨🇮",
    "flag-ck": "🇨🇰",
    "flag-cl": "🇨🇱",
    "flag-cm": "🇨🇲",
    cn: "🇨🇳",
    "flag-co": "🇨🇴",
    "flag-cp": "🇨🇵",
    "flag-cr": "🇨🇷",
    "flag-cu": "🇨🇺",
    "flag-cv": "🇨🇻",
    "flag-cw": "🇨🇼",
    "flag-cx": "🇨🇽",
    "flag-cy": "🇨🇾",
    "flag-cz": "🇨🇿",
    de: "🇩🇪",
    "flag-dg": "🇩🇬",
    "flag-dj": "🇩🇯",
    "flag-dk": "🇩🇰",
    "flag-dm": "🇩🇲",
    "flag-do": "🇩🇴",
    "flag-dz": "🇩🇿",
    "flag-ea": "🇪🇦",
    "flag-ec": "🇪🇨",
    "flag-ee": "🇪🇪",
    "flag-eg": "🇪🇬",
    "flag-eh": "🇪🇭",
    "flag-er": "🇪🇷",
    es: "🇪🇸",
    "flag-et": "🇪🇹",
    "flag-eu": "🇪🇺",
    "flag-fi": "🇫🇮",
    "flag-fj": "🇫🇯",
    "flag-fk": "🇫🇰",
    "flag-fm": "🇫🇲",
    "flag-fo": "🇫🇴",
    fr: "🇫🇷",
    "flag-ga": "🇬🇦",
    gb: "🇬🇧",
    "flag-gd": "🇬🇩",
    "flag-ge": "🇬🇪",
    "flag-gf": "🇬🇫",
    "flag-gg": "🇬🇬",
    "flag-gh": "🇬🇭",
    "flag-gi": "🇬🇮",
    "flag-gl": "🇬🇱",
    "flag-gm": "🇬🇲",
    "flag-gn": "🇬🇳",
    "flag-gp": "🇬🇵",
    "flag-gq": "🇬🇶",
    "flag-gr": "🇬🇷",
    "flag-gs": "🇬🇸",
    "flag-gt": "🇬🇹",
    "flag-gu": "🇬🇺",
    "flag-gw": "🇬🇼",
    "flag-gy": "🇬🇾",
    "flag-hk": "🇭🇰",
    "flag-hm": "🇭🇲",
    "flag-hn": "🇭🇳",
    "flag-hr": "🇭🇷",
    "flag-ht": "🇭🇹",
    "flag-hu": "🇭🇺",
    "flag-ic": "🇮🇨",
    "flag-id": "🇮🇩",
    "flag-ie": "🇮🇪",
    "flag-il": "🇮🇱",
    "flag-im": "🇮🇲",
    "flag-in": "🇮🇳",
    "flag-io": "🇮🇴",
    "flag-iq": "🇮🇶",
    "flag-ir": "🇮🇷",
    "flag-is": "🇮🇸",
    it: "🇮🇹",
    "flag-je": "🇯🇪",
    "flag-jm": "🇯🇲",
    "flag-jo": "🇯🇴",
    jp: "🇯🇵",
    "flag-ke": "🇰🇪",
    "flag-kg": "🇰🇬",
    "flag-kh": "🇰🇭",
    "flag-ki": "🇰🇮",
    "flag-km": "🇰🇲",
    "flag-kn": "🇰🇳",
    "flag-kp": "🇰🇵",
    kr: "🇰🇷",
    "flag-kw": "🇰🇼",
    "flag-ky": "🇰🇾",
    "flag-kz": "🇰🇿",
    "flag-la": "🇱🇦",
    "flag-lb": "🇱🇧",
    "flag-lc": "🇱🇨",
    "flag-li": "🇱🇮",
    "flag-lk": "🇱🇰",
    "flag-lr": "🇱🇷",
    "flag-ls": "🇱🇸",
    "flag-lt": "🇱🇹",
    "flag-lu": "🇱🇺",
    "flag-lv": "🇱🇻",
    "flag-ly": "🇱🇾",
    "flag-ma": "🇲🇦",
    "flag-mc": "🇲🇨",
    "flag-md": "🇲🇩",
    "flag-me": "🇲🇪",
    "flag-mf": "🇲🇫",
    "flag-mg": "🇲🇬",
    "flag-mh": "🇲🇭",
    "flag-mk": "🇲🇰",
    "flag-ml": "🇲🇱",
    "flag-mm": "🇲🇲",
    "flag-mn": "🇲🇳",
    "flag-mo": "🇲🇴",
    "flag-mp": "🇲🇵",
    "flag-mq": "🇲🇶",
    "flag-mr": "🇲🇷",
    "flag-ms": "🇲🇸",
    "flag-mt": "🇲🇹",
    "flag-mu": "🇲🇺",
    "flag-mv": "🇲🇻",
    "flag-mw": "🇲🇼",
    "flag-mx": "🇲🇽",
    "flag-my": "🇲🇾",
    "flag-mz": "🇲🇿",
    "flag-na": "🇳🇦",
    "flag-nc": "🇳🇨",
    "flag-ne": "🇳🇪",
    "flag-nf": "🇳🇫",
    "flag-ng": "🇳🇬",
    "flag-ni": "🇳🇮",
    "flag-nl": "🇳🇱",
    "flag-no": "🇳🇴",
    "flag-np": "🇳🇵",
    "flag-nr": "🇳🇷",
    "flag-nu": "🇳🇺",
    "flag-nz": "🇳🇿",
    "flag-om": "🇴🇲",
    "flag-pa": "🇵🇦",
    "flag-pe": "🇵🇪",
    "flag-pf": "🇵🇫",
    "flag-pg": "🇵🇬",
    "flag-ph": "🇵🇭",
    "flag-pk": "🇵🇰",
    "flag-pl": "🇵🇱",
    "flag-pm": "🇵🇲",
    "flag-pn": "🇵🇳",
    "flag-pr": "🇵🇷",
    "flag-ps": "🇵🇸",
    "flag-pt": "🇵🇹",
    "flag-pw": "🇵🇼",
    "flag-py": "🇵🇾",
    "flag-qa": "🇶🇦",
    "flag-re": "🇷🇪",
    "flag-ro": "🇷🇴",
    "flag-rs": "🇷🇸",
    ru: "🇷🇺",
    "flag-rw": "🇷🇼",
    "flag-sa": "🇸🇦",
    "flag-sb": "🇸🇧",
    "flag-sc": "🇸🇨",
    "flag-sd": "🇸🇩",
    "flag-se": "🇸🇪",
    "flag-sg": "🇸🇬",
    "flag-sh": "🇸🇭",
    "flag-si": "🇸🇮",
    "flag-sj": "🇸🇯",
    "flag-sk": "🇸🇰",
    "flag-sl": "🇸🇱",
    "flag-sm": "🇸🇲",
    "flag-sn": "🇸🇳",
    "flag-so": "🇸🇴",
    "flag-sr": "🇸🇷",
    "flag-ss": "🇸🇸",
    "flag-st": "🇸🇹",
    "flag-sv": "🇸🇻",
    "flag-sx": "🇸🇽",
    "flag-sy": "🇸🇾",
    "flag-sz": "🇸🇿",
    "flag-ta": "🇹🇦",
    "flag-tc": "🇹🇨",
    "flag-td": "🇹🇩",
    "flag-tf": "🇹🇫",
    "flag-tg": "🇹🇬",
    "flag-th": "🇹🇭",
    "flag-tj": "🇹🇯",
    "flag-tk": "🇹🇰",
    "flag-tl": "🇹🇱",
    "flag-tm": "🇹🇲",
    "flag-tn": "🇹🇳",
    "flag-to": "🇹🇴",
    "flag-tr": "🇹🇷",
    "flag-tt": "🇹🇹",
    "flag-tv": "🇹🇻",
    "flag-tw": "🇹🇼",
    "flag-tz": "🇹🇿",
    "flag-ua": "🇺🇦",
    "flag-ug": "🇺🇬",
    "flag-um": "🇺🇲",
    "flag-un": "🇺🇳",
    us: "🇺🇸",
    "flag-uy": "🇺🇾",
    "flag-uz": "🇺🇿",
    "flag-va": "🇻🇦",
    "flag-vc": "🇻🇨",
    "flag-ve": "🇻🇪",
    "flag-vg": "🇻🇬",
    "flag-vi": "🇻🇮",
    "flag-vn": "🇻🇳",
    "flag-vu": "🇻🇺",
    "flag-wf": "🇼🇫",
    "flag-ws": "🇼🇸",
    "flag-xk": "🇽🇰",
    "flag-ye": "🇾🇪",
    "flag-yt": "🇾🇹",
    "flag-za": "🇿🇦",
    "flag-zm": "🇿🇲",
    "flag-zw": "🇿🇼",
    "flag-england": "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
    "flag-scotland": "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
    "flag-wales": "🏴󠁧󠁢󠁷󠁬󠁳󠁿",
};
