import { get } from "lodash";
import { useQuery } from "react-query";

import { APP_CONSTANT, TIME_ZONE } from "../constants/AppConstant";
import { UserContext } from "../contexts";
import { ProcessedPageDataType } from "../types";
import { api, messages } from "../utils";

const FIGMA_IMG = "figma-image";

const useFetchFigmaImage = (nodeIds: string, projectId: number) => {
    const { token } = UserContext();
    return useQuery(
        [FIGMA_IMG, nodeIds],
        async () => {
            const { data } = await api({
                version: "v2",
                url: `${APP_CONSTANT.webApi.fetchFigmaImage}`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Timezone: TIME_ZONE,
                },
                data: { nodeIds, projectId },
            });

            return get(data, "data", {}) as any;
        },
        {
            enabled: !!nodeIds,
            onSettled: (data, error) => {
                if (error) {
                    get(error, "response.data.message", messages.SOMETHING_WENT_WRONG);
                }
                return data;
            },
            retry: 3,
            retryDelay: 3,
            refetchOnMount: false,
            staleTime: Infinity,
        }
    );
};

const processfilteredPrototypes = (searchKey: string, data: ProcessedPageDataType[]) => {
    const lowerCaseSearchKey = searchKey.toLowerCase();

    const filteredData = data.filter((page) => {
        const matchesPageTitle = page.pageTitle.toLowerCase().includes(lowerCaseSearchKey);
        const matchesPrototypes = page.prototypes.some((prototype) =>
            prototype.prototypeTitle.toLowerCase().includes(lowerCaseSearchKey)
        );
        return matchesPageTitle || matchesPrototypes;
    });

    return filteredData;
};

export { processfilteredPrototypes, useFetchFigmaImage };
