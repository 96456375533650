import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

const MainContainer = styled.div`
    padding: 0px 0;
    display: block;
    margin: auto;
`;

export const ConsentPreview = () => (
    <MainContainer>
        <Box>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "1rem",
                    marginBottom: "15px",
                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                Introduction
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Export Comments and its team respects your privacy and is committed to protecting it
                through our compliance with this Privacy Policy. This means Export Comments does not
                transfer, distribute, disclose, rent or sell any of your personal information to
                third parties except as provided for in this Privacy Policy or as specifically
                consented by you.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                By accepting this Privacy Policy in registration for our Services and/or by visiting
                and using our Site, you expressly consent to our collection, use and disclosure of
                your Personal Information in accordance with this Privacy Policy.
            </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "1rem",
                    marginBottom: "15px",
                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                Scope
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                This Privacy Policy applies to all Personal Information collected, used or disclosed
                by Export Comments from end-users of Export Comments Services (“Users”), including
                you. “Personal Information” is any information in any form that identifies or can
                identify an individual or could be combined with other information to identify an
                individual and includes basic contact details like Name, Email ID, Profile Image,
                etc.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                This Privacy Policy also applies to information that is about you but individually
                does not identify you, as described below.
            </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "1rem",
                    marginBottom: "15px",

                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                Information Export Comments Collects
            </Typography>
            <Box sx={{ marginBottom: "20px" }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: "1rem",
                        marginBottom: "10px",

                        fontWeight: "500",
                        letterSpacing: "0.18px",
                    }}
                >
                    Personal Information
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    Personal Information that Export Comments collects includes, but is not limited
                    to:
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    <ul>
                        <li>
                            Registration information, such as name, e-mail address and a picture of
                            yourself;
                        </li>
                        <li>
                            Comments related information, including your comment id file id, and
                            account-related information (e.g. access token from Figma
                            Authentication)
                        </li>
                        <li>
                            Any other Personal Information that you may provide when you use the
                            Services, or when you contact Export Comments with questions and/or
                            inquiries;
                        </li>
                        <li>Technical support records;</li>
                        <li>Service requests and inquiries; and</li>
                        <li>
                            Any information that we collect from you that is intended to improve and
                            personalize our services.
                        </li>
                    </ul>
                </Typography>
            </Box>
            <Box sx={{ marginBottom: "20px" }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: "1rem",
                        marginBottom: "10px",

                        fontWeight: "500",
                        letterSpacing: "0.18px",
                    }}
                >
                    Non-Personal Information
                </Typography>
                <Typography variant="body1">
                    Non-Personal Information (“NPI”) means information that is aggregated,
                    anonymized or otherwise cannot be linked with any individual. Export Comments
                    collects NPI about Users when they interact with our Site. For instance, NPI may
                    include, but is not limited to, browser name, type of computer or device, IP
                    address, geo-location information and technical information about a User’s means
                    of connecting to the Site, such as operating system, Internet service provider
                    utilized, and mobile network information. See the section below entitled “How
                    does Export Comments collect, use and share NPI?” for more information.
                </Typography>
            </Box>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "1rem",
                    marginBottom: "15px",

                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                How Does Export Comments Use Personal Information?
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Export Comments collects Personal Information for the following purposes:
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                <ul>
                    <li>to establish and maintain responsible relations with you;</li>
                    <li>to communicate with you in order to provide our Services;</li>
                    <li>
                        to respond to any correspondence and support requests you may direct to
                        Export Comments;
                    </li>
                    <li>to understand our customers’ needs and preferences;</li>
                    <li>to provide you with service or administrative messages;</li>
                    <li>to meet legal and regulatory requirements;</li>
                    <li>
                        to prevent, detect and mitigate illegal, fraudulent, or counterfeit
                        activities;
                    </li>
                    <li>to improve our Site and Services; and</li>
                    <li>
                        for any other reasonable purposes for which you may have provided your
                        express consent or in which your consent can be reasonably implied.
                    </li>
                </ul>
            </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "1rem",
                    marginBottom: "15px",

                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                How Does Export Comments Collect, Use And Share NPI?
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Export Comments uses NPI in an ongoing effort to better understand and serve our
                customers and to improve the content and functionality of our Site.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                In addition, Export Comments may use Personal Information, on an aggregated
                de-identified basis, for research purposes to improve our Site or Services. This
                aggregated de-identified information does not identify any individual and therefore
                is
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                No NPI by itself, or together with aggregated Personal Information, can be tracked
                to a specific individual’s Personal Information.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Specific Uses of NPI by Export Comments:
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                <ul>
                    <li>
                        To conduct internal research on our customers to better understand and serve
                        them, including usage patterns, demographics, interests and behaviors.
                    </li>
                    <li>
                        We use anonymized and aggregated information about Users$apos: interaction
                        with our Site to test our systems, data analysis, developing new services
                        and improving and/or personalizing your experience on our Site.
                    </li>
                </ul>
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                The NPI collected by Export Comments will not be shared with third parties, except
                as expressly indicated in this Privacy Policy, or for other lawful purposes.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Technology Used to Collect NPI:
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                <ul>
                    <li>
                        Export Comments uses different types of technology to collect this
                        information, such as log files, embedded scripts, cookies, web beacons, and
                        pixel tags.
                    </li>
                </ul>
            </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "1rem",
                    marginBottom: "15px",

                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                Cookies & Opt-Out
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Export Comments uses cookies to enhance Users’ experiences. Users’ web browsers
                place cookies on their computers for record-keeping purposes and sometimes to track
                information about them. Cookies help Export Comments accurately understand how many
                consumers are visiting Export Comments’s Website, how often Users visit the Website,
                what content Users are most interested in, and how they prefer to interact with
                Content on the Website. For example, a cookie will allow Export Comments to remember
                certain things like your Internet Protocol (IP) address (IP addresses are strings of
                numbers that identify senders and receivers of information sent across the
                Internet), your login information, the time and duration of your visits, how you
                navigated through Export Comments’s content and where you exited the Website. To
                save you the time of re-entering your login information every time you come back to
                Export Comments’s website, Export Comments may link information contained in a
                cookie to your email address. Export Comments does not use cookies to retrieve
                information from your device that Export Comments did not originally send in a
                cookie.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Export Comments uses Google Analytics, which uses cookies to track information about
                traffic and visitors and sends such information (i) to Google servers in the United
                States and (ii) to the Export Comments. Google Analytics does not identify any
                individual users or associate your IP address with any other data held by Google.
                Export Comments uses such information in the aggregate to compile statistical
                reports about the activity on the Services as well as to benchmark the Services to
                other third parties who also use Google Analytics. This information is used by
                Export Comments to improve the Services and their functionality. Google’s privacy
                policy can be found here: policies.google.com/privacy
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                You may disable cookies using your browser’s settings. Please consult your browser’s
                help function for information on how to disable cookies.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                You may opt-out of Google Analytics by clicking here:
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                tools.google.com/dlpage/gaoptout?hl=en
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Note that if you disable cookies, certain features of Export Comments’s Services may
                not function to their fullest potential.
            </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "1rem",
                    marginBottom: "15px",

                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                Consent
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Export Comments respects your privacy and, unless otherwise required by law, Export
                Comments will not collect, use or disclose your Personal Information without your
                prior consent. Your consent may be expressed or implied. You may expressly give your
                consent in writing, verbally or through any electronic means. In certain
                circumstances, your consent may be implied by your actions.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Where appropriate, Export Comments will generally seek consent for the use or
                disclosure of the information at the time of collection. In certain circumstances,
                consent with respect to use or disclosure may be sought after the information has
                been collected but before use (for example, when Export Comments wants to use
                information for a purpose other than those identified above). In obtaining consent,
                Export Comments will use reasonable efforts to ensure that a User is advised of the
                identified purposes for which Personal Information collected will be used or
                disclosed.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                The form of consent sought by Export Comments may vary, depending upon the
                circumstances and type of information disclosed. In determining the appropriate form
                of consent, Export Comments shall take into account the sensitivity of the Personal
                Information and the reasonable expectations of Export Comments’s Users. Export
                Comments will seek express consent when the information is likely to be considered
                sensitive. Implied consent will generally be appropriate where the information is
                less sensitive.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                You may withdraw consent at any time, subject to legal or contractual restrictions
                and reasonable notice. In order to withdraw consent, you must provide notice to
                Export Comments in writing.
            </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "1rem",
                    marginBottom: "15px",

                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                Limiting Use, Disclosure And Retention
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                As noted above, Export Comments does not disclose your Personal Information to third
                parties, except as provided for in this Privacy Policy.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Export Comments may transfer your Personal Information to third party providers,
                contractors, including our integration parties for storing data (including your
                Personal Information), and providing you with the Services. Examples of our
                Affiliate include:
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                <ul>
                    <li>
                        Intelligaia- Inteligaia is the company taking care of end to end development
                        and maintenance of Export Comments and also owns the Export Comments
                        Product.
                    </li>
                </ul>
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Our Affiliates will only use your Personal Information for the purposes identified
                in this Privacy Policy.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                As explained above, Export Comments uses Google Analytics. Google’s privacy policy
                can be found here: policies.google.com/privacy
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                In the event your Personal Information is disclosed to a third party pursuant to a
                business transaction, Export Comments will ensure that it has entered into an
                agreement under which the collection, use and disclosure of the information is
                related to those purposes that relate to the transaction.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Subject to the foregoing, only Export Comments’s and Export Comments’s Affiliates’
                employees and contractors with a business need to know, or whose duties reasonably
                so require, are granted access to Personal Information about you. All such employees
                and contractors will be required as a condition of employment to contractually
                respect the confidentiality of Export Comments’s Users’ Personal Information.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Export Comments will retain Personal Information for only as long as required to
                fulfill the identified purposes or as required by law. Personal Information that is
                no longer required to fulfill the identified purposes will be destroyed, erased or
                made anonymous according to the guidelines and procedures established by Export
                Comments.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Export Comments will disclose Personal Information without your knowledge or consent
                if Export Comments receives an order, subpoena, warrant or other legal requirement
                issued by a court, tribunal, regulator or other person with jurisdiction to compel
                disclosure of your Personal Information. If Export Comments receives a written
                request from a police officer or other law enforcement agency with authority to
                request access to your Personal Information in the course of an actual or potential
                investigation into a breach of a law, Export Comments’s policy is to provide the
                requested information.
            </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "1rem",
                    marginBottom: "15px",

                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                Links To Other Services
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                You may be able to access third-party websites and services through links available
                on our Site. Any links are provided for your convenience. Export Comments does not
                have any control over those third-party websites or services and Export Comments
                does not provide any guarantee that the privacy practices of the hosts of those
                websites or services meet Export Comments’s standards. Your use of such third-party
                websites or services is at your own risk and will be governed by the privacy
                policies of those websites or services and not by this Privacy Policy. Do not
                transmit Personal Information using those websites or services without reading the
                privacy policies governing those websites and services.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                NOTE that you may choose to log-in to or register for Export Comments’s Services
                through social media or other online services. When you do so, Personal Information
                from your social media service account may be shared with Export Comments. The
                information Export Comments may receive varies by such services and is controlled by
                that service. By associating an account managed by a third party with your Export
                Comments account and authorizing Export Comments to have access to this information,
                and you agree that Export Comments may collect, store, and use this information in
                accordance with this Privacy Policy. Export Comments is not responsible for how
                those third parties use and share your Personal Information. Please refer to those
                third parties’ privacy policies to understand how they use and share your Personal
                Information.
            </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "1rem",
                    marginBottom: "15px",

                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                Email Communications
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                From time to time we may send you e-mail, text messages or other electronic
                communications with information and/or special offers that we believe would be of
                interest to you. If you do not wish to receive e-mail, text messages or other
                electronic communications you can notify Export Comments at any time of your
                preference by making the appropriate ‘opt-out’, ‘unsubscribe’ or ‘remove’ election
                contained in such communications.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Export Comments may use a reputable third party to deliver email, text messages or
                other electronic communications. If so, such a third party is under a contractual
                requirement to keep all personal information confidential and to only use any
                personal information for the sole purpose of sending you email, text messages or
                other electronic communications on behalf of Export Comments and to return any
                Personal Information once their services are completed.
            </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "1rem",
                    marginBottom: "15px",

                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                How Can I Access My Personal Information?
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Upon request, Export Comments will provide you information regarding the existence,
                use and disclosure of your Personal Information and you will be given access to that
                information. Export Comments will respond to an application for individual access to
                Personal Information within a reasonable time and at minimal or no cost to the
                individual. An individual may challenge the accuracy and completeness of the
                information and have it amended as appropriate.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                NOTE: In certain circumstances, Export Comments may not be able to provide access to
                all of your Personal Information that it holds. Exceptions may include information
                that is prohibitively costly to provide, information that contains references to
                other individuals, information that cannot be disclosed for legal, security or
                commercial proprietary reasons, or information that is subject to solicitor-client
                or litigation privilege. Export Comments will provide the reasons for denying access
                upon request.
            </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "1rem",
                    marginBottom: "15px",

                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                Safeguards
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Export Comments protects your Personal Information by security safeguards
                appropriate to the sensitivity of the information. Export Comments will protect
                Personal Information against loss or theft, as well as unauthorized access,
                disclosure, copying, use, or modification.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Export Comments’s methods of protection include:
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                <ul>
                    <li>
                        utilizing access controls and firewall controls for our computer servers;
                    </li>
                    <li>
                        limiting access of employees and contractors to, and the use of, Personal
                        Information through the use of passwords and graduated levels of clearance
                        and making available Personal Information only on a need-to-know basis;
                    </li>
                    <li>
                        the use of pseudonymization and encryption of Personal Information, where
                        appropriate;
                    </li>
                    <li>
                        measures to ensure the ongoing confidentiality, integrity, availability and
                        resilience of our systems and services; and
                    </li>
                    <li>
                        a process for regularly testing, assessing and evaluating the effectiveness
                        of technical and organizational measures for ensuring the security of the
                        processing of Personal Information.
                    </li>
                </ul>
            </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "1rem",
                    marginBottom: "15px",

                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                Inquiries; Challenging Compliance
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                An individual will be able to make inquiries and/or address a challenge concerning
                compliance with this Privacy Policy to Export Comments’s Privacy Officer.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Export Comments will maintain procedures for addressing and responding to all
                inquiries or complaints from Export Comments’s Users about Export Comments’s
                handling of Personal Information. Export Comments shall investigate all complaints.
                All inquiries or complaints involving Export Comments’s handling of Personal
                Information or compliance with this Privacy Policy.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                The Privacy Officer will respond to all such inquiries or complaints within 14
                business days of receipt. The Privacy Officer will make reasonable efforts to
                resolve all such complaints within 30 days of receipt of the initial complaint. If
                Export Comments finds a complaint to be justified, it will take appropriate
                measures, including, if necessary, amending its policies and procedures.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                For more information, please contact Export Comments’s Privacy Office as follows:
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                E-mail: uxdev@intelligaia.com , contact@intelligaia.com
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Full name of legal entity: Intelligaia
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Compliance hotline: +1 (252) 400-1521
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Postal address of data protection officer: RESEARCH TRIANGLE PARK, NC 800, Park
                Offices Drive, Suite 406, Research Triangle Park, NC 27709 US
            </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "1rem",
                    marginBottom: "15px",

                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                General
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "0px",
                }}
            >
                Export Comments and Intelligaia reserves the right to modify and amend this Privacy
                Policy from time to time in its sole discretion without prior notice. Any such
                amendment(s) will be posted on Export Comments’s website and will be effective as of
                the date of posting. In the case of Export Comments Members, we will send you notice
                of the amendment(s) by email.
            </Typography>
        </Box>
    </MainContainer>
);
